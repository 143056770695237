import styled, { css } from 'styled-components'

export const twoColumnsStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: 100%;

    ${({ theme }) => css`
      ${theme.breakpoints.media.small} {
        width: calc((100% - 2rem) / 2);
      }
    `}
  }
`

export const TwoColumns = styled.div`
  ${twoColumnsStyle}
`

export const TwoVerticalColumns = styled.div`
  width: 100%;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      columns: 2;
      column-gap: 2rem;
    }
  `}
`
