import 'proxy-polyfill'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { Config } from './types/config'
import { IntakeForm } from './IntakeForm'
import React from 'react'
import ReactDOM from 'react-dom'

class TabWidget {
  config: Config

  constructor(config: Config) {
    this.config = config
  }

  async render() {
    const { selector } = this.config
    const container = document.querySelector(selector ? selector : 'root')

    if (container) {
      ReactDOM.render(
        <React.StrictMode>
          <IntakeForm config={this.config} />
        </React.StrictMode>,
        container
      )
    }
  }

  static new(config: Config) {
    return new TabWidget(config)
  }
}

export { IntakeForm, TabWidget }
