import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button } from '../Button'
import { Modal } from '@components/Modal'
import { Typography } from '../Typography'
import { Icon } from '../Icon'

import ExclamationIcon from '@assets/icons/exclamation.svg'

const Divider = styled.div`
  width: 90%;
  border: solid 0.6px #bdbdbd;
  margin: auto;
  margin-top: 24px;
`
const StyledExclamationIcon = styled(Icon).attrs({
  src: ExclamationIcon,
  className: 'intake__icon--exclamation',
})`
  width: 45px;
  height: 45px;
  margin-bottom: 30px;
`
const StyledSecurityInfo = styled(Typography)`
  font-size: 12px;
  font-weight: 200;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #777777;
`
const StyledRetry = styled(Button)`
  margin: 20px 0;
`
const StyledNeedHelp = styled(Typography)`
  font-size: 14px;
  font-weight: 200;
`
const StyledModalTitle = styled(Modal.Title)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export interface ModalEligibilityNotFoundProps
  extends React.ComponentPropsWithoutRef<'div'> {
  onRetry?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const ModalEligibilityNotFound: React.FC<
  ModalEligibilityNotFoundProps
> = ({ onRetry, className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <StyledModalTitle>
        <StyledExclamationIcon />
        {t('eligibility_not_found_title')}
      </StyledModalTitle>

      <Modal.Content>
        <Typography>{t('eligibility_not_found_text')}</Typography>

        <StyledRetry onClick={onRetry}>
          {t('eligibility_not_found_button')}
        </StyledRetry>

        <StyledNeedHelp>
          {t('errorModal.helpText', {
            phoneNumber: '1-877-753-6727',
          })}
        </StyledNeedHelp>

        <Divider />

        <StyledSecurityInfo>{t('errorModal.securityInfo')}</StyledSecurityInfo>
      </Modal.Content>
    </div>
  )
}
