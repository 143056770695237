import { AnzForm } from '@api'
import { useConfig } from '../Context'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { Form } from '@api'
import { confirmation, continueLater } from '@utils/routes'
import { isCaOrUs } from '@root/utils/utils'

type FormCompleteProps = {
  data: Form | AnzForm | undefined
  nextStep: Form['step']
  run: (...args: any[]) => void
  isBack?: boolean
  redirectTo?: 'CONTINUE_LATER' | 'CONFIRMATION'
}

export const useFormComplete = ({
  data,
  nextStep,
  run,
  isBack = false,
  redirectTo,
}: FormCompleteProps) => {
  const history = useHistory()
  const { country } = useConfig()

  return useCallback(async () => {
    if (!data) throw new Error('Cannot Update Form Without Data')

    const shouldUpdate = isCaOrUs(country) && !isBack
    shouldUpdate &&
      run({
        ...data,
        step: nextStep,
      })

    const shouldRedirect = redirectTo && !isBack
    shouldRedirect &&
      history.push(
        {
          CONTINUE_LATER: continueLater(),
          CONFIRMATION: confirmation(),
        }[redirectTo!]
      )
  }, [data, nextStep, run, isBack, redirectTo, history, country])
}
