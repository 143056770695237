import { isJwtNotExpired, JWT } from '@root/api/core/request'
import { useConfig } from '@root/Context'
import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import useJwt from './useJwt'

const useIsJwtExpired = () => {
  const { appointmentId } = useConfig()
  const [isExpired, setIsExpired] = useState<boolean>(false)

  const { jwt } = useJwt()

  const resetExpired = () => setIsExpired(false)

  useEffect(() => {
    if (!jwt) {
      return
    }

    const checkJwt = () => {
      !isJwtNotExpired(jwt, appointmentId) && setIsExpired(true)
    }

    const decodedJwt = jwt_decode<JWT>(jwt)
    const remainingMsToExpireJwt = decodedJwt.exp * 1000 - new Date().getTime()

    const id =
      remainingMsToExpireJwt > 0
        ? setTimeout(checkJwt, remainingMsToExpireJwt)
        : undefined

    return () => id && clearTimeout(id)
  }, [jwt])

  return {
    isExpired,
    resetExpired,
  }
}

export default useIsJwtExpired
