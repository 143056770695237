import { useConfig } from '@root/Context'
import { CountryType } from '@root/types/config'

const useIsAnz = (countryParam?: CountryType): boolean => {
  const { country } = useConfig()
  const isAnz =
    (country || countryParam) === 'AU' || (country || countryParam) === 'NZ'

  return true
}

export default useIsAnz
