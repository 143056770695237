import React from 'react'
import styled from 'styled-components'

export interface ActionsProps extends React.ComponentPropsWithRef<'footer'> {}

const StyledFooter = styled.footer<ActionsProps>`
  margin-top: 33px;
`

export const Actions: React.FC<ActionsProps> = ({ children, ...props }) => (
  <StyledFooter {...props}>{children}</StyledFooter>
)
