import type { Appointment } from '@root/api'
import { request as __request } from '../core/request'

export class AppointmentService {
  /**
   * Get appointment information using own ID.
   * This method retrieve information about the appointment related to a form, with some data about the patient and the clinic.
   * @returns Appointment OK
   * @throws ApiError
   */
  public static async getAppointment({
    brand,
    appointmentId,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<Appointment> {
    const appId = appointmentId

    const result = await __request({
      method: 'GET',
      path: `/${brand}/appointment/${appId}/detail`,
      query: {},
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    if (!result.body)
      throw new Error(
        `No response received for GET /${brand}/appointment/${appId}/detail`
      )
    return result.body
  }
}
