import React from 'react'
import styled, { css } from 'styled-components'
import { twoColumnsStyle } from '../../Layout/TwoColumns'

export interface InputsProps extends React.ComponentPropsWithRef<'div'> {
  $twoColumns?: boolean
}

const StyledDiv = styled.div<InputsProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-bottom: 20px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.medium} {
      margin-left: 20px;
      margin-right: 20px;
    }
  `}

  margin-bottom: -20px;

  ${({ $twoColumns, theme }) =>
    $twoColumns
      ? twoColumnsStyle
      : css`
          & > * {
            margin-right: 20px;
            justify-content: space-between;
            ${theme.breakpoints.media.medium} {
              justify-content: flex-start;
            }
          }
        `}
`

export const Inputs: React.FC<InputsProps> = ({ children, ...props }) => {
  return <StyledDiv {...props}>{children}</StyledDiv>
}
