import { Icon } from '@components/Icon'
import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'
import styled, { css } from 'styled-components'

const LCSection = css`
  border-radius: 10px;
`

const OPSMSection = css`
  border: 1px solid ${({ theme }) => theme.colors.onSurface};
`

export const StyledSection = styled.section<StoreNameProps>`
  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSection,
        PV: LCSection,
        TO: LCSection,
        FE: LCSection,
        VT: LCSection,
        OPSM: OPSMSection,
      },
    })};

  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
  padding: 35px;
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & h1 {
    margin-top: 0;
  }

  &:not(:first-of-type) {
    margin-top: 55px;
  }
`

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TypographyWithIcon = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-left: 15px;
  }
`

export const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
`
