import React from 'react'
import { WidgetProps } from '@rjsf/core'
import { useTranslation } from 'react-i18next'

import { handleOnChange } from '../utils/handleOnChange'
import { TextArea } from '@components/TextArea'

const TextareaWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  schema,
  rawErrors,
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()

  return (
    <TextArea
      label={label}
      name={id}
      disabled={disabled}
      required={required}
      value={value}
      onChange={handleOnChange(onChange)}
      maxLength={schema.maxLength}
      resizable
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
      helperText={t('core.maxCharacters', {
        numberOfCharacter: schema.maxLength,
      })}
    />
  )
}

export default TextareaWidget
