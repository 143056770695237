/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnzForm, Form } from '../models/Form'
import type { FormId } from '../models/FormId'
import { request as __request } from '../core/request'
import { transformValueInArray } from '@components/RJSF/widgets/MultiSelectWidget'
import { OptIn } from '../models/OptIn'

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

export class FormService {
  /**
   * Get last form
   * This method get a form for an appointment already scheduled.
   * @returns FormId OK
   * @throws ApiError
   */
  public static async getFormByApptId({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<FormId> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/appointment/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get a specific form
   * This method get a form by ID
   * @returns Form OK
   * @throws ApiError
   */
  public static async getFormById({
    brand,
    id,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    id: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<Form> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/${id}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }

  /**
   * Get a specific form
   * This method get a form by ID
   * @returns Form OK
   * @throws ApiError
   */
  public static async getFormByAppointmentId({
    brand,
    appointmentId,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<AnzForm> {
    await delay(750)
    const result = await __request({
      method: 'GET',
      path: `/${brand}/form/retrieveform/${appointmentId}`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    // const cleanedData = FormService.removePrivacyPolicyFields(result.body)

    return FormService.formDataToState(result.body)
  }

  /**
   * Update form data and continue to next step
   * This method update a form for an appointment already scheduled. After the form is saved, user continues to complete other step of form. [Continue form buttom]
   * @returns Form Updated
   * @throws ApiError
   */
  public static async upsertFormByAppointmentId<T = Form | AnzForm>({
    brand,
    appointmentId,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    appointmentId: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: T
  }): Promise<T> {
    const result = await __request({
      method: 'PUT',
      path: `/${brand}/form/appointment/${appointmentId}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Update form data and continue to next step
   * This method update a form for an appointment already scheduled. After the form is saved, user continues to complete other step of form. [Continue form buttom]
   * @returns Form Updated
   * @throws ApiError
   */
  public static async upsertFormById({
    brand,
    id,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    id: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: Form
  }): Promise<Form> {
    const result = await __request({
      method: 'POST',
      path: `/${brand}/form/${id}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Update form data and send Email
   * This method update a form for an appointment already scheduled. After saved form, send an Email. [Complete form button or Save later buttom]
   * @returns Form Updated
   * @throws ApiError
   */
  public static async upsertFormByIdAndSendEmail<T = Form | AnzForm>({
    brand,
    id,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Form ID used to retrieve data. **/
    id: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: T
  }): Promise<T> {
    const result = await __request({
      method: 'POST',
      path: `/${brand}/form/${id}`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Update privacy policy fields
   * This method update privacy policy fields in a form for an appointment already scheduled. After the fields are saved, user grants access to the form.
   * @returns Privacy policy fields Updated
   * @throws ApiError
   */
  public static async upsertPrivacyPolicyByAppId({
    brand,
    appointmentId,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** Appointment ID used to retrieve data. **/
    appointmentId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: OptIn
  }): Promise<AnzForm> {
    const result = await __request({
      method: 'PUT',
      path: `/${brand}/form/upsertPrivacyPracticeByAppId/${appointmentId}`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return FormService.formDataToState(result.body)
  }

  private static formDataToState(formData: AnzForm): AnzForm {
    if ('reasonForVisit' in formData.questionAnswer) {
      formData.questionAnswer.reasonForVisit = transformValueInArray(
        formData.questionAnswer.reasonForVisit as string | string[] | undefined
      )
    }

    return formData
  }
}
