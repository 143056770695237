import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'

import useIsAnzAuthenticated from '@hooks/useIsAnzAuthenticated'
import { intro } from '@root/utils/routes'
import * as OPSM from './OPSM'
import useIsJwtExpired from '@hooks/useIsJwtExpired'
import { useConfig } from '@root/Context'
import { isCaOrUs } from '@root/utils/utils'

const ProtectedRouteIfAnz: React.FC<RouteProps> = (props) => {
  const { isAuthenticated } = useIsAnzAuthenticated()
  const { isExpired, resetExpired } = useIsJwtExpired()
  const { country } = useConfig()

  if (!isCaOrUs(country) && isExpired) {
    return <OPSM.SessionExpired onClose={resetExpired} />
  }

  if (isAuthenticated) {
    return <Route {...props} />
  }

  return <Redirect to={intro()} />
}

export default ProtectedRouteIfAnz
