import React from 'react'
import clsx from 'clsx'
import { Props as InputProps } from 'react-input-mask'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Typography } from '../Typography'
import { localeToDatePickerMask } from '../../utils/date'
import { StyledInput, StyledSpan, StyledWrapper } from './DatePicker.styling'
import { useConfig } from '@root/Context'
import useIsFieldReadonly from '@hooks/useIsFieldReadonly'

export interface StyledInputProps extends InputProps {
  $fullWidth?: boolean
  error?: boolean
}
export interface DatePickerProps extends Partial<StyledInputProps> {
  label?: string
  errorMessage?: string
  customMask?: string | (string | RegExp)[]
}

const StyledLabel = styled.label`
  position: relative;
`

const StyledError = styled(Typography)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  label,
  error,
  errorMessage,
  name,
  $fullWidth = false,
  required = false,
  customMask,
  readOnly,
  ...props
}) => {
  const { brand } = useConfig()
  const { i18n } = useTranslation()
  const isConfigReadonly = useIsFieldReadonly(name)

  const locale = i18n.language
  const dateMask = localeToDatePickerMask(locale)
  const isReadonly = !!readOnly || isConfigReadonly

  return (
    <StyledWrapper
      storeName={brand}
      className={clsx(
        'intake__textfield',
        {
          'intake__textfield--error': error,
        },
        className
      )}
    >
      {label && (
        <StyledLabel htmlFor={name}>
          <StyledSpan storeName={brand} $variant="span" required={required}>
            {label.trim()}
          </StyledSpan>
        </StyledLabel>
      )}
      <StyledInput
        storeName={brand}
        type="text"
        name={name}
        error={error}
        $fullWidth={$fullWidth}
        mask={customMask || dateMask}
        aria-invalid={error ? 'true' : 'false'}
        readOnly={isReadonly}
        {...props}
      />
      {error && errorMessage && (
        <StyledError $variant="span" $color="error" role="alert">
          {errorMessage}
        </StyledError>
      )}
    </StyledWrapper>
  )
}
