import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
import styled from 'styled-components'
import { Checkbox } from '@components/Checkbox'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react'
import { useInsuranceFieldVisible } from '@hooks/useInsuranceFieldVisible'
import { TypeAhead } from '@components/TypeAhead'
import { useCommonConfigs } from '@hooks/useCommonConfigs'
import { ThreeColumns } from '@components/Layout/ThreeColumns'
import { InsuranceTooltipHelper } from '@root/steps/Insurance/Insurance'
import { InputGroup } from '@components/InputGroup'
import { Radio } from '@components/Radio'
import InsuranceDataProduct from '@root/steps/Insurance/InsuranceDataProduct'
import { useEligibilityCheckEnabled } from '@hooks/useEligibilityCheckEnabled'
import { ModalEligibility } from '@components/ModalEligibility'
import { HasAdditionalErrorsContext } from '@root/anz/components/AnzForm/JSONSchemaForm'
import { useConfig } from '@root/Context'

interface ColumnProps extends React.ComponentPropsWithRef<'div'> {
  $fullWidth?: boolean
}

const StyledColumn = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '50%')};

  & > * {
    margin-bottom: 39px;
  }
`

const Column: React.FC<ColumnProps> = ({
  children,
  $fullWidth = false,
  ...props
}) => {
  return (
    <StyledColumn $fullWidth={$fullWidth} {...props}>
      {children}
    </StyledColumn>
  )
}

export type InsuranceFormData = {
  hasInsurancePlan: boolean
  insuranceProvider: string
  primaryMemberID: string
  primaryMemberSSN: string
  isPrimaryHolder: boolean
  relationshipWithHolder: string
  primaryHolderFirstName: string
  primaryHolderLastName: string
  primaryHolderGender: string
  primaryHolderDOB: string
  primaryHolderAddress: string
  primaryHolderCity: string
  primaryHolderCountry: string
  primaryHolderZipCode: string
  primaryHolderState: string
  primaryHolderSSN: string
}

const INITIAL_VALUES: InsuranceFormData = {
  hasInsurancePlan: false,
  insuranceProvider: '',
  primaryMemberID: '',
  primaryMemberSSN: '',
  isPrimaryHolder: true,
  relationshipWithHolder: '',
  primaryHolderFirstName: '',
  primaryHolderLastName: '',
  primaryHolderGender: '',
  primaryHolderDOB: '',
  primaryHolderAddress: '',
  primaryHolderCity: '',
  primaryHolderCountry: '',
  primaryHolderZipCode: '',
  primaryHolderState: '',
  primaryHolderSSN: '',
}

const InsuranceWidget = ({
  options,
  required,
  label,
  value: selectedValues,
  onChange,
  disabled,
  id,
  rawErrors,
  placeholder,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()
  const { setHasAdditionalErrors, isOnSubmit }: any = useContext(
    HasAdditionalErrorsContext
  )
  const initialValues = useMemo(
    () => (!!selectedValues ? JSON.parse(selectedValues) : {}),
    [selectedValues]
  )
  const [currentFormValues, setCurrentFormValues] = useState<InsuranceFormData>(
    !!selectedValues ? initialValues : INITIAL_VALUES
  )
  const [hasInsurancePlan, setHasInsurancePlan] = useState<boolean>(
    currentFormValues.hasInsurancePlan
  )
  const [insuranceProvider, setInsuranceProvider] = useState<string>(
    currentFormValues.insuranceProvider
  )
  const [isPrimaryHolder, setIsPrimaryHolder] = useState<boolean>(
    currentFormValues.isPrimaryHolder
  )
  const isFieldVisible = useInsuranceFieldVisible()
  const isEligibilityCheckEnabled = useEligibilityCheckEnabled()
  const commonConfigs = useCommonConfigs()

  const { country } = useConfig()

  useEffect(() => {
    onChange(JSON.stringify(currentFormValues))
  }, [onChange, currentFormValues])

  const onInsuranceStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked: hasInsurancePlan } = event.target
    setHasInsurancePlan(hasInsurancePlan)
    setInsuranceProvider((current) =>
      hasInsurancePlan ? current : INITIAL_VALUES.insuranceProvider
    )
    setCurrentFormValues((currentValues) => {
      const otherValues = hasInsurancePlan ? currentValues : INITIAL_VALUES
      return {
        ...otherValues,
        hasInsurancePlan,
      }
    })
  }

  const onHolderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as 'true' | 'false'
    const isPrimaryHolder = {
      true: true,
      false: false,
    }[value]

    const {
      hasInsurancePlan,
      insuranceProvider,
      primaryMemberID,
      primaryMemberSSN,
      ...resetValues
    } = INITIAL_VALUES

    setIsPrimaryHolder(isPrimaryHolder)
    setCurrentFormValues({
      ...currentFormValues,
      ...resetValues,
      isPrimaryHolder,
    })
  }

  const onInsuranceProviderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: insuranceProvider } = event.target
    setInsuranceProvider(insuranceProvider)
    setCurrentFormValues((currentValues) => {
      const isMemberIdVisible = isFieldVisible(
        'primaryMemberID',
        insuranceProvider
      )
      const isMemberSSNVisible = isFieldVisible(
        'primaryMemberSSN',
        insuranceProvider
      )
      return {
        ...currentValues,
        insuranceProvider,
        primaryMemberID: isMemberIdVisible
          ? INITIAL_VALUES.primaryMemberID
          : '-',
        primaryMemberSSN: isMemberSSNVisible
          ? INITIAL_VALUES.primaryMemberSSN
          : '-',
      }
    })
  }

  return (
    <div key={'insuranceWidget'}>
      <Column $fullWidth>
        <Checkbox
          name="hasInsurancePlan"
          label={props.schema.title}
          onChange={onInsuranceStatusChange}
          checked={hasInsurancePlan || currentFormValues.hasInsurancePlan}
          disabled={disabled}
        />
      </Column>

      {hasInsurancePlan && (
        <>
          <Column $fullWidth>
            {country === 'US' ? (
              <TypeAhead
                name="insuranceProvider"
                label={t('insurance.insuranceProvider')}
                onSelect={(e: any) => {
                  setInsuranceProvider(e.target.value)
                }}
                onChange={onInsuranceProviderChange}
                value={insuranceProvider || currentFormValues.insuranceProvider}
                required
                helperText={
                  <div>
                    {isPrimaryHolder &&
                      isEligibilityCheckEnabled(insuranceProvider) && (
                        <ModalEligibility
                          insuranceProvider={insuranceProvider}
                          primaryHolderDOB={currentFormValues.primaryHolderDOB}
                          primaryHolderFirstName={
                            currentFormValues.primaryHolderFirstName
                          }
                          primaryHolderLastName={
                            currentFormValues.primaryHolderLastName
                          }
                          primaryHolderSSN={currentFormValues.primaryHolderSSN}
                          primaryMemberID={currentFormValues.primaryMemberID}
                          primaryMemberSSN={currentFormValues.primaryMemberSSN}
                        />
                      )}
                  </div>
                }
              >
                <TypeAhead.Option value="" disabled>
                  --
                </TypeAhead.Option>
                {commonConfigs.insuranceProvider?.map(
                  ({ label, value }, index) => (
                    <TypeAhead.Option
                      value={value}
                      key={`provider-${label}-${index}`}
                    />
                  )
                )}
              </TypeAhead>
            ) : (
              <TextField
                name="insuranceProviderFreeText"
                label={t('insurance.insuranceProvider')}
                onChange={(e: any) => {
                  setInsuranceProvider(e.target.value)
                  setCurrentFormValues({
                    ...currentFormValues,
                    insuranceProvider: e.target.value,
                  })
                }}
                value={insuranceProvider || currentFormValues.insuranceProvider}
                required
                styleLabel={{ whiteSpace: 'nowrap' }}
              />
            )}
          </Column>
          <ThreeColumns>
            {(isFieldVisible('primaryMemberID', insuranceProvider) ||
              country === 'CAN') && (
              <TextField
                name="primaryMemberID"
                label={t('insurance.primaryMemberID')}
                onChange={(e) => {
                  const value = e.target.value
                  setCurrentFormValues({
                    ...currentFormValues,
                    primaryMemberID: e.target.value,
                  })
                  if (!!value) {
                    setHasAdditionalErrors(false)
                  } else {
                    setHasAdditionalErrors(true)
                  }
                }}
                value={currentFormValues.primaryMemberID}
                error={isOnSubmit && !currentFormValues.primaryMemberID}
                errorMessage={t('core.requiredMessage')}
                $fullWidth
                required
                helperText={
                  <InsuranceTooltipHelper
                    label={t('insurance.primaryMemberIDHelperText')}
                  >
                    {t('insurance.primaryMemberIDTooltip')}
                  </InsuranceTooltipHelper>
                }
              />
            )}

            {isFieldVisible('primaryMemberSSN', insuranceProvider) &&
            country === 'US' ? (
              <TextField
                name="primaryMemberSSN"
                label={t('insurance.primaryMemberSSN')}
                onChange={(e) => {
                  const value = e.target.value
                  setCurrentFormValues({
                    ...currentFormValues,
                    primaryMemberSSN: value,
                  })
                  if (!!value) {
                    setHasAdditionalErrors(false)
                  } else {
                    setHasAdditionalErrors(true)
                  }
                }}
                value={currentFormValues.primaryMemberSSN}
                error={isOnSubmit && !currentFormValues.primaryMemberSSN}
                errorMessage={t('core.requiredMessage')}
                required
                styleLabel={{ whiteSpace: 'nowrap' }}
              />
            ) : null}
          </ThreeColumns>

          {(isFieldVisible('isPrimaryHolder', insuranceProvider) ||
            country === 'CAN') && (
            <InputGroup>
              <InputGroup.Label required>
                {t('insurance.isPrimaryHolder')}
              </InputGroup.Label>

              <InputGroup.Inputs>
                <Radio
                  name="isPrimaryHolder"
                  label={t('core.yes')}
                  value="true"
                  onChange={onHolderChange}
                  checked={isPrimaryHolder}
                />

                <Radio
                  name="isPrimaryHolder"
                  label={t('core.no')}
                  value="false"
                  onChange={onHolderChange}
                  checked={!isPrimaryHolder}
                />
              </InputGroup.Inputs>
              <InputGroup.HelperText>
                <InsuranceTooltipHelper
                  label={t('insurance.isPrimaryHolderHelperText')}
                >
                  {t('insurance.isPrimaryHolderTooltip')}
                </InsuranceTooltipHelper>
              </InputGroup.HelperText>
              <InputGroup.Errors />
            </InputGroup>
          )}
          {!!insuranceProvider &&
            !isPrimaryHolder &&
            (isFieldVisible('isPrimaryHolder', insuranceProvider) ||
              country === 'CAN') && (
              <>
                <InsuranceDataProduct
                  namePrefix="vision"
                  currentFormValues={currentFormValues}
                  setCurrentFormValues={setCurrentFormValues}
                  setHasAdditionalErrors={setHasAdditionalErrors}
                  isOnSubmit={isOnSubmit}
                />
              </>
            )}
        </>
      )}
    </div>
  )
}

export default InsuranceWidget
