import React from 'react'
import clsx from 'clsx'

export interface OptGroupProps
  extends React.ComponentPropsWithRef<'optgroup'> {}

export const OptGroup: React.FC<OptGroupProps> = ({
  children,
  className,
  ...props
}) => (
  <optgroup className={clsx('intake__optgroup', className)} {...props}>
    {children}
  </optgroup>
)
