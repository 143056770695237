import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useFormStatus } from '@utils/routes'
import { Button } from '@components/Button'
import { Typography } from '@components/Typography'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'
import { Loader } from '@components/Loader'
import { AnzForm } from '@api'
import { useTabApiJsonSchema } from '@root/services/TabApiProvider/ProviderDynamicForm'
import { jsonSchemaCustom } from '@root/anz/types/anzTypes'
import type { JSONSchema7 } from 'json-schema'
import { Icon } from '@components/Icon'

import CheckIcon from '@assets/icons/check.svg'
import CrossIcon from '@assets/icons/cross.svg'
import clsx from 'clsx'
import { useDynamicAnzForm } from '@root/services/DynamicAnzFormProvider'
import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'
import { useConfig } from '@root/Context'

const LCSection = css`
  border-radius: 10px;
`

const OPSMSection = css`
  border: 1px solid ${({ theme }) => theme.colors.onSurface};
`

const StyledSection = styled.section<StoreNameProps>`
  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSection,
        PV: LCSection,
        TO: LCSection,
        FE: LCSection,
        VT: LCSection,
        OPSM: OPSMSection,
      },
    })};

  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
  padding: 35px;
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & h1 {
    margin-top: 0;
  }

  &:not(:first-of-type) {
    margin-top: 55px;
  }
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &:not(:first-of-type) {
    margin-top: 35px;
  }

  p {
    margin: 0;
  }

  span {
    font-size: 13px;
    letter-spacing: 0.41px;
  }

  button {
    margin-top: 15px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      flex-direction: row;
      align-items: center;

      button {
        margin-top: 0;
      }
    }
  `}
`

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
`

const TypographyWrapper = styled.div`
  align-items: center;

  & p {
    font-weight: bold;
    font-size: 14px;

    &:nth-of-type(2) {
      margin-left: 35px;
    }
  }
`

const TypographyWithIcon = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-left: 15px;
  }
`

const EditButton = (props: React.ComponentPropsWithRef<typeof Button>) => (
  <Button $outlined {...props} />
)

const StyledEditButton = styled(EditButton)`
  margin-top: 15px;
  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      margin-top: 0;
      position: absolute;
      top: 29px;
      right: 41px;
    }
  `}
`

const TitleWrapper = styled.div`
  width: 95%;

  padding-right: 0.75rem;

  @media screen and (min-width: 425px) {
    display: flex;
    justify-content: left;
    max-width: 70%;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 425px) {
    display: flex;
    justify-content: right;
    max-width: 30%;
  }
`

const isFieldTrue = (value: string | number | boolean | string[] | undefined) =>
  value &&
  ((typeof value === 'string' &&
    (value.toLocaleLowerCase() === 'yes' ||
      value.toLocaleLowerCase() === 'true' ||
      value === '1')) ||
    (typeof value === 'number' && value) ||
    (typeof value === 'boolean' && value) ||
    (typeof value === 'object' && value))

type IndexProps = {
  index: number
}

const GeneralReview: React.FC<Pick<jsonSchemaCustom, 'title'> & IndexProps> = ({
  title,
  index: step,
}) => {
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const { goToStep } = useDynamicAnzForm()

  const handleGoToStep = () => goToStep(step)

  return (
    <StyledDiv>
      <TitleWrapper>
        <TypographyWrapper>
          <Typography $variant="p" $align="left" $transform="uppercase">
            {title}
          </Typography>
          <Typography $variant="span" $align="left">
            {t(
              // isStepCompleted('5_PATIENT_BACKGROUND', step)
              'reviewSubmission.insuranceInfo.stepCompleted'
            )}
          </Typography>
        </TypographyWrapper>
      </TitleWrapper>

      <ButtonWrapper>
        <Button
          $outlined
          onClick={handleGoToStep}
          data-element-id="X_OEE_Review_View&Edit"
        >
          {t(isFormExpired ? 'core.view' : 'core.viewAndEdit')}
        </Button>
      </ButtonWrapper>
    </StyledDiv>
  )
}

const PropertyReview: React.FC<
  Partial<jsonSchemaCustom> & { formData: AnzForm }
> = ({ singlePropertiesToReview, properties, formData, title, page }) => {
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const { goToStep } = useDynamicAnzForm()

  const handleGoToStep = () => goToStep(Number(page))

  if (singlePropertiesToReview) {
    const propertiesKeys = Object.keys(singlePropertiesToReview)

    return (
      <React.Fragment>
        <Typography $variant="h1" $align="left">
          {title}
        </Typography>

        {propertiesKeys.map((key, index) => {
          const fieldName = (properties?.[key] as JSONSchema7)?.title
          const fieldValue = formData.questionAnswer[key]

          if (singlePropertiesToReview[key] === 'icon') {
            return (
              <TypographyWithIcon key={`${index}-${fieldName}`}>
                <StyledIcon
                  src={isFieldTrue(fieldValue) ? CheckIcon : CrossIcon}
                  className={clsx({
                    'intake__icon--check': fieldValue,
                    'intake__icon--cross': !fieldValue,
                  })}
                />

                <Typography $weight="bold">{fieldName}</Typography>
              </TypographyWithIcon>
            )
          }

          return (
            <TypographyWrapper key={`${index}-${fieldName}`}>
              <Typography $weight="bold">{fieldName}</Typography>

              <Typography $weight="bold" $transform="uppercase">
                {isFieldTrue(fieldValue) ? t('core.yes') : t('core.no')}
              </Typography>
            </TypographyWrapper>
          )
        })}

        <StyledEditButton
          $outlined
          onClick={handleGoToStep}
          data-element-id="X_OEE_Review_View&Edit"
        >
          {t(isFormExpired ? 'core.view' : 'core.edit')}
        </StyledEditButton>
      </React.Fragment>
    )
  }

  return null
}

export const OtherPagesRecap: React.FC = () => {
  const { brand } = useConfig()
  const { data, isLoading: isLoadingForm } = useTabApiForm<AnzForm>()
  const { data: jsonSchemaData, isLoading: isLoadingJsonSchema } =
    useTabApiJsonSchema()

  const step = data?.step

  const jsonSchemaGeneralReview =
    jsonSchemaData?.filter(
      ({ jsonSchema: { showGeneralReview } }) => showGeneralReview
    ) || []

  const jsonSchemaSinglePropertiesReview =
    jsonSchemaData?.filter(
      ({ jsonSchema: { singlePropertiesToReview } }) => singlePropertiesToReview
    ) || []

  if (isLoadingForm || isLoadingJsonSchema) return <Loader height={200} />

  return (
    <>
      {step
        ? jsonSchemaSinglePropertiesReview.map(({ jsonSchema }, index) => (
            <StyledSection
              key={`${brand}-${index}`}
              data-id={'pages-recap-container'}
              storeName={brand}
            >
              <PropertyReview {...jsonSchema} formData={data!} />
            </StyledSection>
          ))
        : null}
      {jsonSchemaGeneralReview && jsonSchemaGeneralReview.length ? (
        <StyledSection data-id={'pages-recap-container'} storeName={brand}>
          {jsonSchemaGeneralReview.map(
            ({ jsonSchema: { title, page } }, index) => (
              <GeneralReview
                key={`${brand}-${index}`}
                title={title}
                index={Number(page)}
              />
            )
          )}
        </StyledSection>
      ) : null}
    </>
  )
}
