import { MemoryRouter, Route, Switch } from 'react-router'
import React, {
  createContext,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import {
  anzForm,
  anzIntro,
  confirmation,
  continueLater,
  intro,
  legal,
} from '@utils/routes'

import { Config } from './types/config'
import { ConfigContext } from './Context'
import { ContinueLater } from './routes/ContinueLater'
import { ErrorBoundary } from '@components/ErrorBoundary'
import { GlobalErrorModal } from '@components/GlobalErrorModal'
import { Intro } from './routes/Intro/Intro'
import { LegalDocument } from './routes/LegalDocument'
import { Loader } from '@components/Loader'
import { OpenAPI } from '@api'
import { ScrollToTop } from './components/ScrollToTop'
import { AnzForm } from './anz'
import { TabApiProvider } from '@services/TabApiProvider'
import { difTheme, getFontsBasedOnStore } from './theme'
import { useI18nInstance } from './i18n'
import ProtectedRouteIfAnz from './routes/ProtectedRouteIfAnz'
import { isCaOrUs } from '@utils/utils'
import { Confirmation } from '@root/routes/Confirmation'
import OTPPage from '@root/routes/OTPPage'
import { getGlobalStyles } from '@root/style'

export const HasAlreadyRedirectedContext = createContext({
  hasAlreadyRedirected: false,
  setHasAlreadyRedirected: (value: boolean) => {},
})

export interface IntakeFormProps {
  config: Config
}

export const IntakeForm = ({ config }: IntakeFormProps) => {
  const i18nInstance = useI18nInstance(config.langUrl, config.lang)

  const [hasAlreadyRedirected, setHasAlreadyRedirected] = useState(false)
  const hasAlreadyRedirectedContextValue = useMemo(
    () => ({ hasAlreadyRedirected, setHasAlreadyRedirected }),
    [hasAlreadyRedirected]
  )

  OpenAPI.BASE =
    (!isCaOrUs(config.country)
      ? process.env.REACT_APP_TAB_API_URL_ANZ?.replace(/\/$/, '')
      : process.env.REACT_APP_TAB_API_URL?.replace(/\/$/, '')) || OpenAPI.BASE

  const GlobalStyle = getGlobalStyles(
    !config.isDoctor ? config.brand : 'LC',
    config.selector || ''
  )

  useEffect(() => {
    setHasAlreadyRedirected(false)
  }, [])

  return (
    <ThemeProvider theme={difTheme}>
      <ErrorBoundary>
        {getFontsBasedOnStore(config.brand)}
        <GlobalStyle />
        <Suspense fallback={<Loader height={200} />}>
          <I18nextProvider i18n={i18nInstance}>
            <ConfigContext.Provider value={config}>
              <HasAlreadyRedirectedContext.Provider
                value={hasAlreadyRedirectedContextValue}
              >
                <MemoryRouter>
                  <ScrollToTop />
                  <Switch>
                    <Route exact path={intro()} component={OTPPage} />
                    <TabApiProvider>
                      <GlobalErrorModal />
                      <ProtectedRouteIfAnz
                        exact
                        path={anzIntro()}
                        component={Intro}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={legal()}
                        component={LegalDocument}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={anzForm()}
                        component={AnzForm}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={continueLater()}
                        component={ContinueLater}
                      />
                      <ProtectedRouteIfAnz
                        exact
                        path={confirmation()}
                        component={Confirmation}
                      />
                    </TabApiProvider>
                  </Switch>
                </MemoryRouter>
              </HasAlreadyRedirectedContext.Provider>
            </ConfigContext.Provider>
          </I18nextProvider>
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  )
}
