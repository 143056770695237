import { useCallback } from 'react'
import { InsuranceConfig } from '../api'
import { useTabApiConfig } from '../services/TabApiProvider/ProviderConfigs'
import { getInsuranceGroupFromConfig } from '@hooks/useInsuranceFieldVisible'

export const useEligibilityCheckEnabled = () => {
  const { data: configs } = useTabApiConfig()

  const isEligibilityCheckEnabled = useCallback(
    (provider: string | undefined) => {
      if (!configs || !provider) return false
      const group = getInsuranceGroupFromConfig(configs, provider)
      if (!group) return false
      if (!configs.insuranceConfigs) return false

      const eligibilityProperty = configs.insuranceConfigs.find(
        (property: InsuranceConfig) => property.propertyCode === 'elegibility'
      )

      return eligibilityProperty?.isVisibleOnInsuranceProvider?.includes(group)
    },
    [configs]
  )

  return isEligibilityCheckEnabled
}
