import { BackendModule, ReadCallback, ResourceLanguage } from 'i18next'
import merge from 'lodash/merge'

interface BackendOptions {
  /**
   * array of existing i18next backends from https://www.i18next.com/plugins-and-utils.html#backends
   */
  backends?: BackendModule<unknown>[]
  /**
   * array of options in order of backends above
   */
  backendOptions?: unknown[]
}

export function createClassOnDemand(ClassOrObject: {} | (() => void)) {
  if (!ClassOrObject) return null
  if (typeof ClassOrObject === 'function') return new ClassOrObject()
  return ClassOrObject
}

export function isResourceLanguage(data: any): data is ResourceLanguage {
  return !!data && Object.keys(data).length > -1
}

class I18NextMergeBackend implements BackendModule<BackendOptions> {
  static type = 'backend'
  type: 'backend' = 'backend'
  services: any
  backends: BackendModule<unknown>[]
  options: BackendOptions

  constructor(services?: any, options: BackendOptions = {}) {
    this.backends = []
    this.options = merge({}, options)
    this.init(services, options)
  }

  init(services?: any, options: BackendOptions = {}, i18nextOptions?: any) {
    this.services = services
    this.options = merge(this.options || {}, options)

    this.options.backends &&
      this.options.backends.forEach((b, i) => {
        this.backends[i] = this.backends[i] || createClassOnDemand(b)
        const backendOption =
          this.options.backendOptions && this.options.backendOptions[i]
        this.backends[i].init(services, backendOption, i18nextOptions)
      })
  }

  async read(language: string, namespace: string, callback: ReadCallback) {
    const results = await Promise.all<ResourceLanguage | null>(
      this.backends.map(
        (backend) =>
          new Promise((resolve) => {
            backend.read(language, namespace, (err, data) => {
              if (err) {
                return resolve(null)
              }

              if (isResourceLanguage(data)) {
                if (backend.save) {
                  backend.save(language, namespace, data)
                }

                resolve(data)
              }

              resolve(null)
            })
          })
      )
    )

    const validResults = results.filter(isResourceLanguage)

    if (validResults.length > 0) {
      const mergedResult = merge({}, ...validResults)
      callback(null, mergedResult)
    } else {
      callback(new Error('none of the backend loaded data'), false)
    }
  }

  create(
    languages: string[],
    namespace: string,
    key: string,
    fallbackValue: string
  ) {
    this.backends.forEach((b) => {
      if (b.create) b.create(languages, namespace, key, fallbackValue)
    })
  }
}

export default I18NextMergeBackend
