import React from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { Typography } from '../../Typography'

export interface CompletionTrackerProps
  extends React.ComponentPropsWithRef<'div'> {
  value: string | number
}

const StyledProgressWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
`
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1022px;
`

const StyledProgress = styled.div`
  height: 5px;
  position: relative;
  background: ${({ theme: { colors } }) => colors.progressBg};
  border-radius: 25px;
  width: 100%;
`

const StyledSpan = styled.span<{ $value: string | number }>`
  display: block;
  height: 100%;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.primary};
  position: relative;
  overflow: hidden;
  width: ${({ $value }) => `${+$value * 10}%`};
`

const StyledTextualProgress = styled(Typography)`
  margin-left: 22.5px;
`

export const CompletionTracker: React.FC<CompletionTrackerProps> = ({
  className,
  value = 0,
  ...props
}) => {
  return (
    <StyledProgressWrapper
      className={clsx('intake__completion-tracker', className)}
      {...props}
    >
      <Wrapper>
        <StyledProgress>
          <StyledSpan $value={value} />
        </StyledProgress>

        <StyledTextualProgress $color="primary">
          {+value * 10}%
        </StyledTextualProgress>
      </Wrapper>
    </StyledProgressWrapper>
  )
}
