/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { getJwt, request as __request } from '../core/request'
import { InsuranceCard } from '../models/InsuranceCard'
import { OpenAPI } from '@api'

export class InsuranceCardService {
  /**
   * Get all insurance cards linked to form
   * @returns Document OK
   * @throws ApiError
   */
  public static async getInsuranceCardsList({
    formId,
    appointmentId,
    ocpApimSubscriptionKey,
    acceptedLanguage,
  }: {
    /** Form ID related to the document to retrieve. **/
    formId: string
    /** Appointment ID. **/
    appointmentId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage?: `${string}-${string}`
  }): Promise<InsuranceCard[]> {
    const result = await __request({
      method: 'GET',
      path: `/form/appointment/documentFileList`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      query: {
        appointmentId: appointmentId,
        formId: formId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  public static async getInsuranceCard({
    appointmentId,
    documentFileId,
    originalName,
    ocpApimSubscriptionKey,
  }: {
    /** Appointment ID. **/
    appointmentId: string
    /** document file ID. **/
    documentFileId: string
    /** original file name **/
    originalName: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage?: `${string}-${string}`
  }): Promise<InsuranceCard> {
    const result = await __request({
      method: 'GET',
      path: `/form/appointment/documentFile`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      query: {
        appointmentId: appointmentId,
        documentFileId: documentFileId,
        originalName: originalName,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  public static async getInsuranceCardBis({
    appointmentId,
    documentFileId,
    originalName,
    ocpApimSubscriptionKey,
  }: {
    appointmentId: string
    documentFileId: string
    originalName: string
    ocpApimSubscriptionKey: string
    acceptedLanguage?: `${string}-${string}`
  }): Promise<Response> {
    const path = `/form/appointment/documentFile`
    const queryString = new URLSearchParams({
      appointmentId,
      documentFileId,
      originalName,
    }).toString()
    const apiUrl = `${OpenAPI.BASE}${path}?${queryString}`
    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
          Authorization: `Bearer ${getJwt()}`,
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`)
      }

      return response
    } catch (error: any) {
      throw new Error(`Failed to fetch insurance card: ${error.message}`)
    }
  }

  /**
   * Get a document by clinic.
   * Get a document by clinicId.
   * @returns Document OK
   * @throws ApiError
   */
  public static async createInsuranceCards({
    appointmentId,
    formId,
    requestBody,
    ocpApimSubscriptionKey,
  }: {
    /** The appointment id **/
    appointmentId: string
    /** The form id **/
    formId: string
    /** The body of the POST request **/
    requestBody: any
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<InsuranceCard> {
    const result = await __request({
      method: 'POST',
      path: `/form/appointment/documentFileFE`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      query: {
        appointmentId: appointmentId,
        formId: formId,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body[0]
  }

  /**
   * Get insurance cards
   * @returns DocumentVisibilities OK
   * @throws ApiError
   */
  public static async deleteInsuranceCards({
    appointmentId,
    documentFileId,
    ocpApimSubscriptionKey,
  }: {
    /** The appointment id **/
    appointmentId: string
    /** The file id to delete **/
    documentFileId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<string> {
    const result = await __request({
      method: 'DELETE',
      path: `/form/appointment/documentFile`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      query: {
        appointmentId: appointmentId,
        documentFileId: documentFileId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }
}
