import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

// #region ::: PARTIALS
const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`

interface PropsWrapper {
  height: 'auto' | number
}
const DotWrapper = styled.div<PropsWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height !== 'auto' ? `${height}px` : height)};
`

interface PropsDot {
  delay: string
}

const Dot = styled.div<PropsDot>`
  background-color: ${({ theme: { colors } }) => colors.primary};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${({ delay }) => delay};
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
// #endregion

interface Props {
  height?: 'auto' | number
  fullHeight?: boolean
}
export const Loader: FC<Props> = ({
  height = 'auto',
  fullHeight = false,
  ...props
}): JSX.Element =>
  fullHeight ? (
    <Wrapper {...props}>
      <DotWrapper height={height}>
        <Dot delay="0s" />
        <Dot delay=".1s" />
        <Dot delay=".2s" />
      </DotWrapper>
    </Wrapper>
  ) : (
    <DotWrapper height={height} {...props}>
      <Dot delay="0s" />
      <Dot delay=".1s" />
      <Dot delay=".2s" />
    </DotWrapper>
  )
