import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AnzRelativesModal as RelativesModal } from '../AnzRelativesModal'
import { Checkbox } from '../../../components/Checkbox'
import { Typography } from '../../../components/Typography'

interface AnzHealthConditionProps {
  index: number
  name: string
  onChange?: (v: any) => void
  enumOptions: any[]
  selectedValues: any[]
  setCheckedValuesInWidget: (value: any) => void
  checkedValuesInWidget?: any
  relativesList?: string[]
}

type checkedRelativesType = {
  [key: string]: boolean
}

type healthState = {
  [key: string]: {
    self?: boolean
    relatives?: checkedRelativesType
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 675px) {
    width: calc((100% - 2rem) / 2);
  }
`

const StyledCell = styled.div`
  display: inline-flex;
  padding: 10px;
  text-align: left;
  align-items: center;
`

const ConditionWrapper = styled(StyledCell)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`

const CheckboxWrapper = styled(StyledCell)`
  width: 4em;
  flex-shrink: 0;

  label {
    margin: 0;
  }
`

export const AnzHealthCondition: React.FC<AnzHealthConditionProps> = ({
  index,
  name,
  setCheckedValuesInWidget,
  checkedValuesInWidget,
  relativesList,
}) => {
  const baseName = `healthConditions[${index}]`
  const [isRelativesModalVisible, setIsRelativesModalVisible] =
    useState<boolean>(false)

  useEffect(() => {
    document.body.classList.toggle(
      'intake__modal-body-open',
      isRelativesModalVisible
    )
  }, [isRelativesModalVisible])

  const handleAddSelf = (value: boolean) => {
    setCheckedValuesInWidget((oldState: healthState) => {
      if (!value) {
        const newState = { ...oldState }
        delete newState[`${name}`].self
        return newState
      }

      let updatedState: healthState = {
        [`${name}`]: {
          self: value,
        },
      }

      if (oldState[`${name}`] && oldState[`${name}`].relatives) {
        updatedState[`${name}`].relatives = oldState[`${name}`].relatives
      }

      return { ...oldState, ...updatedState }
    })
  }

  return (
    <StyledRow>
      <ConditionWrapper>
        <Wrapper>
          <Typography $noMargin $transform="uppercase" $align="left">
            {name}
            {/* {t(`healthConditions.${name}`)} */}
          </Typography>
          <RelativesModal
            isOpen={isRelativesModalVisible}
            name={name}
            baseName={baseName}
            onConfirm={() => setIsRelativesModalVisible(false)}
            onCancel={() => setIsRelativesModalVisible(false)}
            relativesList={relativesList}
            setCheckedValuesInWidget={setCheckedValuesInWidget}
            checkedValuesInModal={checkedValuesInWidget || {}}
          />
        </Wrapper>
      </ConditionWrapper>

      <CheckboxWrapper>
        <Checkbox
          name={`${baseName}.isSelf`}
          onChange={(e) => handleAddSelf(e.target.checked)}
        />
      </CheckboxWrapper>

      <CheckboxWrapper>
        <Checkbox
          name={`${baseName}.isRelatives`}
          onChange={(e) =>
            setIsRelativesModalVisible((isVisible) => !isVisible)
          }
          checked={Object.keys(checkedValuesInWidget || {}).length > 0}
        />
      </CheckboxWrapper>

      <input type="hidden" value={name} name={`${baseName}.conditionName`} />
    </StyledRow>
  )
}
