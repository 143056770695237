import { CountryType } from '@root/types/config'

const getHomePhoneRegexByCountry = (country: CountryType): RegExp => {
  switch (country) {
    case 'AU':
      return /(^$)|(^0[2,3,4,7,8]\d{8}$)|(^[2-9]\d{7}$)/
    case 'NZ':
      return /(^$)|(^02\d{7,9}$)|(^0[3,4,6,7,9]\d{7}$)|(^[2-9]\d{7}$)/
    default:
      return /^\(\d{3}\)\d{3}-\d{4}$/
  }
}

const getCellPhoneRegexByCountry = (country: CountryType): RegExp => {
  switch (country) {
    case 'AU':
      return /(^$)|(?:\+?61)?(?:\(0\)[23478]|\(?0?[23478]\)?)\d{8}/
    case 'NZ':
      return /(^$)|(^0[2,3,4,6,7,9][0-9]{7,9}$)|^[2-9][0-9]{7}$/
    default:
      return /^\(\d{3}\)\d{3}-\d{4}$/
  }
}

export const validateFormat = (country: CountryType) => {
  return {
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    cellPhone: getCellPhoneRegexByCountry(country),
    homePhone: getHomePhoneRegexByCountry(country),
    'dd/MM/yyyy':
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    'MM/yy': /(0[1-9]|1[0-2])\/[0-9]{2}$/,
    year: /^\d{4}$/,
    zipCode: /^\d{5}$/,
    zipCodeCAN: /^\w{6}$/,
    postalCode: /(^$)|^(0[289][0-9]{2})|([1-9][0-9]{3})$/,
    medicareDvaNumber:
      /(^[NVQWST][A-Za-z0-9]{6}[0-9]$|^[NVQWST][A-Za-z0-9]{7}[A-Za-z]$)/,
  }
}
