type InputType = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

const handleOnChange =
  <T extends InputType>(onChange: (value: any) => void) =>
  (e: React.ChangeEvent<T>) =>
    onChange(e.target.value || undefined)

const handleOnChangeCheckboxOrRadio =
  (onChange: (value: any) => void) =>
  (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.checked || undefined)

export { handleOnChange, handleOnChangeCheckboxOrRadio }
