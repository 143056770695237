import React, { useContext, useEffect } from 'react'
import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
import { handleOnChange } from '../utils/handleOnChange'
import { FormDataContext } from '@root/anz/components/AnzForm/JSONSchemaForm'

const ContactLensTypeWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const formData = useContext(FormDataContext)
  const eyeWearValue: string[] = (formData?.eyewear || []) as string[]

  const showField =
    eyeWearValue.includes('Contact Lenses') ||
    eyeWearValue.includes('Lentilles De Contact') ||
    eyeWearValue.includes('Lentes De Contacto')

  useEffect(() => {
    !showField && onChange('')
  }, [])

  return !!showField ? (
    <TextField
      name={id}
      value={value}
      label={label}
      onChange={handleOnChange(onChange)}
      disabled={disabled}
      required={required}
      $fullWidth
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
    />
  ) : null
}

export default ContactLensTypeWidget
