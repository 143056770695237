import React from 'react'
import { useHistory } from 'react-router-dom'
import { AppointmentRecap } from '@components/AppointmentRecap'
import { Button } from '@components/Button'
import { Layout } from '@components/Layout'
import { Typography } from '@components/Typography'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import styled from 'styled-components'

import DocumentSection from './DocumentSection'

import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import { useTabApiAppointment } from '@services/TabApiProvider/ProviderAppointment'
import { Loader } from '@components/Loader'
import usePageClassname from '@hooks/usePageClassname'
import { anzForm, useBypassIntro } from '@utils/routes'
import { DocumentType } from '@api'
import { useVisibleDocuments } from '@hooks/useVisibleDocuments'
import { useConfig } from '@root/Context'
import { ErrorModal } from '@components/ErrorModal'
import useStoreLink from '@hooks/useStoreLink'
import { getHideLogo } from '@root/utils/logo'
import { useDocumentRecap } from '@root/services/tabApi'
import { isCaOrUs } from '@root/utils/utils'

const CompleteExamSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 40px;
`

const TwoColumns = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden auto;

  & > * + * {
    margin-bottom: 40px;
  }
`

const IntroSection = styled.div`
  @media screen and (max-width: 425px) {
    padding: 0 1rem;
    margin-bottom: 3.5rem;
  }
`

export const Intro: React.FC = () => {
  const { brand, country } = useConfig()
  const { t } = useTranslation()
  const history = useHistory()
  const storeLink = useStoreLink()

  useBypassIntro()

  const {
    data,
    isLoading: isLoadingForm,
    error: errorTabForm,
  } = useTabApiForm()

  const {
    data: appointmentData,
    isLoading: isLoadingAppointment,
    error: errorTabAppointment,
  } = useTabApiAppointment()

  const { data: formDataHook } = useTabApiForm()
  const formData = flatFormObjectIfAnz(formDataHook)

  const [isLegalSignModalVisible, toggleLegalSignModalVisibility] =
    useToggle(false)
  const clinicId =
    appointmentData && appointmentData.clinic
      ? appointmentData.clinic.id || ''
      : ''
  const clinicType =
    appointmentData && appointmentData.clinic
      ? appointmentData.clinic.clinicType || ''
      : ''
  const clinicName =
    appointmentData && appointmentData.clinic
      ? appointmentData.clinic.name || ''
      : ''
  const visibleDocuments = useVisibleDocuments(clinicId, clinicType, clinicName)
  const recapDocuments = useDocumentRecap(formData?.id || '')
  const recapDocumentsSigned =
    recapDocuments &&
    recapDocuments.data &&
    recapDocuments.data.filter((doc) => doc.hasAccepted)

  const isDocumentVisible =
    visibleDocuments?.[DocumentType.DIF]?.length > 0 ||
    false ||
    visibleDocuments?.[DocumentType.PIRF]?.length > 0 ||
    false ||
    visibleDocuments?.[DocumentType.PRIVACY]?.length > 0 ||
    false

  const isDisabled =
    isLoadingForm ||
    !!errorTabForm ||
    isLoadingAppointment ||
    !!errorTabAppointment

  const isDocumentsSigned =
    isCaOrUs(country) &&
    recapDocumentsSigned &&
    recapDocumentsSigned.length ===
      visibleDocuments?.[DocumentType.DIF]?.length +
        visibleDocuments?.[DocumentType.PIRF]?.length

  const completeExamHandler = () => {
    if (isDocumentVisible && !isDocumentsSigned) {
      toggleLegalSignModalVisibility(true)
    } else {
      history.push(anzForm())
    }
  }

  usePageClassname('intro-page')

  return (
    <Layout>
      <Layout.Header hideLogo={getHideLogo(country)} />
      <Layout.Content>
        <TwoColumns>
          {!appointmentData && isLoadingAppointment && <Loader height={150} />}
          {appointmentData && (
            <IntroSection>
              <Typography $variant="h1" data-element-id="intro-title">
                {t('intro.title', { name: appointmentData.patient.firstName })}
              </Typography>

              <Typography>{t(`intro.description.${brand}`)}</Typography>
            </IntroSection>
          )}

          <DocumentSection
            isLegalSignModalVisible={isLegalSignModalVisible}
            toggleLegalSignModalVisibility={toggleLegalSignModalVisibility}
            isDocumentVisible={isDocumentVisible}
            isDocumentsSigned={Boolean(isDocumentsSigned)}
            recapDocumentsSigned={recapDocumentsSigned || []}
          />

          <CompleteExamSection>
            {isCaOrUs(country) && (
              <Button
                onClick={completeExamHandler}
                data-element-id="X_OEE_Intro_Legal-ConfirmMyForm"
                disabled={isDisabled}
              >
                {t('intro.completeExamForms')}
              </Button>
            )}
            <Typography>{t('intro.comeBackLaterToFinish')}</Typography>
          </CompleteExamSection>
        </TwoColumns>

        <AppointmentRecap />
      </Layout.Content>

      {data &&
        'statusAppointment' in data &&
        data.statusAppointment === 'DELETED' && (
          <ErrorModal
            isOpen
            errorMessage={t('core.appointmentNoLongerAvailable')}
            showBackToTheFormBtn={false}
            onRequestClose={() => window.location.assign(storeLink)}
          />
        )}
    </Layout>
  )
}
