import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import clsx from 'clsx'
import { useToggle } from 'react-use'
import { DocumentModal } from './DocumentModal'
import { LegalSignModal } from './LegalSignModal'

import { Icon } from '@components/Icon'
import { Typography } from '@components/Typography'
import { Loader } from '@components/Loader'
import { Button } from '@components/Button'

import CheckIcon from '@assets/icons/check-black.svg'
import ExclamationIcon from '@assets/icons/exclamation-black.svg'

import { DocumentType } from '@api'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'

import { isCaOrUs } from '@root/utils/utils'
import { useConfig } from '@root/Context'
import { DocumentRecap } from '@root/api/models/DocumentRecap'

interface DocumentSectionProps {
  isLegalSignModalVisible: boolean
  toggleLegalSignModalVisibility: () => void
  isDocumentVisible: boolean
  isDocumentsSigned: boolean
  recapDocumentsSigned: DocumentRecap[]
}

const DocumentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  padding: 12px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;

  ${({ theme }) => theme.breakpoints.media.medium} {
    flex-direction: row;
    gap: 10px;
    > button {
      margin-inline-start: auto;
    }
  }
`

const StyledIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
`

const TypographyWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  ${({ theme }) => theme.breakpoints.media.medium} {
    flex-direction: row;
  }
`

const Wrapper = styled.section`
  margin-bottom: 40px;

  & p {
    margin-left: 5px;
  }
`

const DocumentSection: React.FC<DocumentSectionProps> = ({
  isLegalSignModalVisible,
  toggleLegalSignModalVisibility,
  isDocumentVisible,
  isDocumentsSigned,
  recapDocumentsSigned,
}) => {
  const { country } = useConfig()
  const [documentModalType, setDocumentModalType] = useState<DocumentType>(
    isCaOrUs(country) ? DocumentType.DIF : DocumentType.PRIVACY
  )
  const { t } = useTranslation()
  const { data, isLoading: isLoadingForm } = useTabApiForm()

  const [isDocumentModalVisible, toggleDocumentModalVisibility] =
    useToggle(false)

  const documentPageHandler = (docType: DocumentType) => {
    toggleDocumentModalVisibility(true)
    setDocumentModalType(docType)
  }

  if (isLoadingForm) {
    return <Loader height={150} />
  }

  if (data) {
    return (
      <>
        <Wrapper id="document-section">
          <Typography $variant="h2">
            {t(
              isDocumentsSigned
                ? 'intro.legalTitleDocumentSigned'
                : !isCaOrUs(country)
                ? 'intro.legalTitleDocumentSignToProcede'
                : 'intro.legalTitleDocumentNotSigned'
            )}
          </Typography>

          {isDocumentVisible && (
            <DocumentWrapper id="privacy-document">
              <StyledIcon
                className={clsx({
                  'intake__icon-check': isDocumentsSigned,
                  'intake__icon-exclamation': !isDocumentsSigned,
                })}
                src={isDocumentsSigned ? CheckIcon : ExclamationIcon}
              />
              <Typography $weight="normal">
                {t('intro.privacyDescription')}
              </Typography>

              <Button
                $outlined
                onClick={() =>
                  documentPageHandler(
                    isCaOrUs(country) ? DocumentType.DIF : DocumentType.PRIVACY
                  )
                }
                data-element-id={
                  isDocumentsSigned
                    ? 'X_OEE_Intro_Legal-Read'
                    : 'X_OEE_Intro_Legal-ReadandSign'
                }
              >
                {t(isDocumentsSigned ? 'core.read' : 'core.readAndSign')}
              </Button>
            </DocumentWrapper>
          )}
        </Wrapper>

        <DocumentModal
          isOpen={isDocumentModalVisible}
          onRequestClose={toggleDocumentModalVisibility}
          docType={documentModalType}
          isDocumentsSigned={isDocumentsSigned}
          recapDocumentsSigned={recapDocumentsSigned}
        />

        <LegalSignModal
          isOpen={isLegalSignModalVisible}
          onRequestClose={toggleLegalSignModalVisibility}
          documentPageHandler={documentPageHandler}
          availableDocuments={{
            [DocumentType.DIF]: isDocumentVisible || false,
            [DocumentType.PIRF]: isDocumentVisible || false,
            [DocumentType.PRIVACY]: isDocumentVisible || false,
          }}
        />
      </>
    )
  }

  return null
}

export default DocumentSection
