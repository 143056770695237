import { useMemo } from 'react'
import { useTabApiConfig } from '../services/TabApiProvider/ProviderConfigs'

type CommonConfigs = Record<
  string,
  { label: string; value: string }[] | undefined
>

const proxyHandler: ProxyHandler<CommonConfigs> = {
  get: (target, name: string) => target[name] || [],
}

export const useCommonConfigs = (): CommonConfigs => {
  const { data: configs } = useTabApiConfig()

  return useMemo(() => {
    if (configs) {
      const { commonConfigs } = configs

      const configObj =
        commonConfigs &&
        commonConfigs.reduce((prev, cur) => {
          prev[cur.propertyCode] = cur.listingCodes?.map((el) => ({
            label: el.toLowerCase(),
            value: el,
          }))

          return prev
        }, {} as CommonConfigs)

      if (configObj) return new Proxy<CommonConfigs>(configObj, proxyHandler)
    }

    return new Proxy<CommonConfigs>({}, proxyHandler)
  }, [configs])
}
