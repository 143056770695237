import { debounce } from 'lodash'
import { useEffect, useState } from 'react'

const useWindowSizeDebounced = () => {
  const getSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    var handleResizeDebounced = debounce(() => {
      setWindowSize(getSize())
    }, 250)

    window.addEventListener('resize', handleResizeDebounced)
    return () => window.removeEventListener('resize', handleResizeDebounced)
  }, [])

  return windowSize
}

export default useWindowSizeDebounced
