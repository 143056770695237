/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request'
import { formConstructor } from '@root/anz/types/anzTypes'

export class DynamicFormService {
  /**
   * Get the JSON Schema
   * @returns JSONSchema OK
   * @throws ApiError
   */
  public static async getJSONSchema({
    acceptedLanguage,
    ocpApimSubscriptionKey,
    schemaVersion,
    organizationId,
  }: {
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage?: `${string}-${string}`
    /** Schema version */
    schemaVersion: number
    /** Schema version */
    organizationId: string
  }): Promise<formConstructor[]> {
    const result = await __request({
      method: 'GET',
      path: `/schema/${schemaVersion}`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      query: organizationId
        ? {
            organization: organizationId,
          }
        : {},
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })

    return result.body
  }
}
