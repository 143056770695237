import styled, { css } from 'styled-components'

import { Typography } from '@components/Typography'
import { stylesBasedOnThemes, StoreNameProps } from '../../theme'

import { TextFieldProps } from './TextField'

const LCWrapper = css`
  margin-bottom: 30px;
`

const OPSMWrapper = css``

const StyledWrapper = styled.div<StoreNameProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCWrapper,
        PV: LCWrapper,
        TO: LCWrapper,
        FE: LCWrapper,
        VT: LCWrapper,
        OPSM: OPSMWrapper,
      },
    })};
`

const LCInput = css<TextFieldProps>`
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ error, theme: { colors } }) =>
    error ? colors.error : colors.onSurface};
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.onSurface};
  height: 32px;
  margin-top: 4px;
  vertical-align: top;
  width: 100%;
  max-width: ${({ $fullWidth }) => ($fullWidth ? '250px' : '150px')};
`

const OPSMInput = css`
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 8px;
  max-width: 320px;
  height: 48px;

  padding: 12px 16px;
`

const StyledInput = styled.input<StoreNameProps & TextFieldProps>`
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  color: ${({ theme: { colors } }) => colors.onSurface};

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCInput,
        PV: LCInput,
        TO: LCInput,
        FE: LCInput,
        VT: LCInput,
        OPSM: OPSMInput,
      },
    })};
`

const LCSpan = css`
  color: ${({ theme: { colors } }) => colors.onSurface};
`

const OPSMSpan = css`
  position: absolute;
  top: -10px;
  left: 8px;
  background: ${({ theme: { colors } }) => colors.label};
  padding: 0 4px;

  &::after {
    color: ${({ theme: { colors } }) => colors.offer};
  }
`

type StyledSpanProps = Pick<TextFieldProps, 'required'> & StoreNameProps
const StyledSpan = styled(Typography)<StyledSpanProps & { isBold?: boolean }>`
  & {
    color: ${({ theme: { colors } }) => colors.onSurface};
  }

  &::first-letter {
    text-transform: capitalize;
  }

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSpan,
        PV: LCSpan,
        TO: LCSpan,
        FE: LCSpan,
        VT: LCSpan,
        OPSM: OPSMSpan,
      },
    })};

  ${(props) =>
    !!props.isBold
      ? `
      font-weight: bold;
    `
      : ''}
  &::after {
    content: ${({ required }) => (required ? "' *'" : '')};
    color: ${({ theme: { colors } }) => colors.info};
  }
`

export { StyledWrapper, StyledInput, StyledSpan }
