import { useConfig } from '@root/Context'
import React from 'react'

import { Legend } from './Label.styling'

export interface LabelProps extends React.ComponentPropsWithRef<'legend'> {
  required?: boolean
}

export const Label: React.FC<LabelProps> = ({
  children,
  required = false,
  ...props
}) => {
  const { brand } = useConfig()

  return (
    <Legend storeName={brand} required={required} {...props}>
      {children}
    </Legend>
  )
}
