import React from 'react'
import clsx from 'clsx'

import { StyledError } from './Errors.styling'
import { useConfig } from '@root/Context'

export interface ErrorsProps {
  error?: boolean
  errorMessage?: string
  className?: string
}

export const Errors: React.VFC<ErrorsProps> = ({
  className,
  error,
  errorMessage,
}) => {
  const { brand } = useConfig()

  return error && errorMessage ? (
    <StyledError
      storeName={brand}
      className={clsx(
        {
          'intake__inputgroup--error': error,
        },
        className
      )}
      $variant="span"
      $color="error"
      role="alert"
    >
      {errorMessage}
    </StyledError>
  ) : null
}
