import useLogoProps from '@hooks/useLogoProps'
import React from 'react'
import styled, { css } from 'styled-components'
import { Layout } from '../Layout'
import { ContinueLaterButton } from './ContinueLaterButton'

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  background-color: ${({ theme }) => theme.colors.backgroundTertiary};

  ${({ theme }) => theme.breakpoints.media.large} {
    position: sticky;
  }
`

const Wrapper = styled.div`
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1022px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${({
    theme: {
      heights: { headerHeight },
    },
  }) => headerHeight};

  > button {
    margin-inline-start: auto;
  }
`

const BorderBottomWrapper = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.onSurface};
`

const LogoImg = styled.img`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-width: 120px;
  height: 15px;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      min-width: 180px;
      height: 22px;
    }
  `}
`

export interface HeaderProps extends React.ComponentPropsWithRef<'header'> {
  hideLogo?: boolean
  showContinueLaterButton?: boolean
  continueLaterDataElementId?: string
  showCompletionTracker?: boolean
  completionTrackerValue?: number
}

export const Header = ({
  children,
  showContinueLaterButton = false,
  continueLaterDataElementId,
  showCompletionTracker = false,
  completionTrackerValue = 0,
  hideLogo = false,
  ...props
}: HeaderProps) => {
  const logoProps = useLogoProps()

  const LogoComponent = LogoImg

  return (
    <StyledHeader {...props}>
      {!hideLogo ||
        (showContinueLaterButton && (
          <BorderBottomWrapper>
            <Wrapper>
              {!hideLogo && (
                <div>
                  <LogoComponent className="intake__logo" {...logoProps} />
                </div>
              )}
              {showContinueLaterButton && (
                <ContinueLaterButton
                  data-element-id={continueLaterDataElementId}
                />
              )}
            </Wrapper>
          </BorderBottomWrapper>
        ))}

      {showCompletionTracker && (
        <Layout.CompletionTracker
          data-id={'completion-tracker-container'}
          value={completionTrackerValue}
        />
      )}
    </StyledHeader>
  )
}

Header.ContinueLaterButton = ContinueLaterButton
