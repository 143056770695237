import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { intro, useFormStatus } from '../../../utils/routes'

import { useConfig } from '@root/Context'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import React from 'react'
import { Button } from '../../../components/Button'
import { Loader } from '../../../components/Loader'
import { Typography } from '../../../components/Typography'
import * as Styles from './DocumentsRecap.styles'
import useIsDoctor from '@hooks/useIsDoctor'
import CheckIcon from '@assets/icons/check.svg'
import CrossIcon from '@assets/icons/cross.svg'
import clsx from 'clsx'
import { useDocumentRecap } from '@root/services/tabApi'
import { useHistory } from 'react-router'
import { isCaOrUs } from '@root/utils/utils'

export interface PersonalInfoRecapProps {}

const EditButton = (props: React.ComponentPropsWithRef<typeof Button>) => (
  <Button $outlined {...props} />
)

const StyledEditButton = styled(EditButton)`
  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      margin-top: 0;
      position: absolute;
      top: 29px;
      right: 41px;
    }
  `}
`

export const DocumentsRecap: React.FC<PersonalInfoRecapProps> = () => {
  const isDoctor = useIsDoctor()
  const { brand, country } = useConfig()
  const { t } = useTranslation()
  const { isFormExpired } = useFormStatus()
  const { data: formDataHook } = useTabApiForm()
  const formData = flatFormObjectIfAnz(formDataHook)
  const history = useHistory()

  const { data: documentData, isLoading: isLoadingDocument } = useDocumentRecap(
    formData?.id
  )

  if (isLoadingDocument) return <Loader height={100} />

  return (isCaOrUs(country) ? isDoctor : true) ? (
    <Styles.StyledSection storeName={brand} id="documents-recap">
      <Styles.SectionHeader>
        <Typography $variant="h1" $align="left">
          {t('reviewSubmission.documentsInfo.title')}
        </Typography>

        <StyledEditButton
          onClick={() => {
            history.push(intro())
          }}
          data-element-id="X_OEE_Basics_Edit"
        >
          {t(isFormExpired ? 'core.view' : 'core.edit')}
        </StyledEditButton>
      </Styles.SectionHeader>

      {documentData &&
        documentData.length > 0 &&
        documentData.map((document) => {
          const isSigned = document.hasAccepted

          return (
            <Styles.TypographyWithIcon
              key={`document-recap-${document.documentId}`}
            >
              <Styles.StyledIcon
                src={isSigned ? CheckIcon : CrossIcon}
                className={clsx({
                  'intake__icon--check': isSigned,
                  'intake__icon--cross': isSigned,
                })}
              />

              <Typography $weight="bold">{document.title}</Typography>
            </Styles.TypographyWithIcon>
          )
        })}
    </Styles.StyledSection>
  ) : null
}
