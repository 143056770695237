import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Benefits } from '@api'

import { Modal } from '@components/Modal'
import { Typography } from '@components/Typography'

import CheckIcon from '@assets/icons/check-green.svg'
import CrossIcon from '@assets/icons/letter-x-grey.svg'
import ContactLens from '@assets/icons/contact-lens.svg'
import EyeExam from '@assets/icons/eye-exam.svg'
import Pilot from '@assets/icons/pilot.svg'
import VisionCareAssistance from '@assets/icons/vision-care-eyewear-assistance.svg'

import { Icon } from '../Icon'

const benefitIcons = {
  EXAMS: EyeExam,
  LENS: VisionCareAssistance,
  FRAMES: Pilot,
  CONTACTS: ContactLens,
  FOLLOW_UP_CONTECTS: ContactLens,
}

const Divider = styled.div`
  width: 90%;
  border: solid 0.6px #bdbdbd;
  margin: auto;
  margin-top: 24px;
`
const StyledBenefitIconContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledBenefitIcon = styled.img`
  margin-right: 12px;
  max-width: 28px;
`

const StyledBenefitIconUnavailable = styled(StyledBenefitIcon)`
  opacity: 0.5;
`

const StyledSecurityInfo = styled(Typography)`
  font-size: 12px;
  font-weight: 200;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #777777;
`

const StyledBenefitList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 40px auto;
  max-width: 80%;
`
const StyledBenefitLi = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`

const StyledModalTitle = styled(Modal.Title)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledCheckIcon = styled(Icon).attrs({
  src: CheckIcon,
  className: 'intake__icon-check',
})`
  width: 12px;
  height: 12px;
  margin-bottom: 0;
  background-size: auto;
  padding: 0;
  margin-right: 5px;
`

const StyledCrossIcon = styled(Icon).attrs({
  className: 'intake__icon-cross',
})`
  width: 12px;
  height: 12px;
  margin-bottom: 0;
  background-size: auto;
  padding: 0;
  margin-right: 5px;
  margin-top: 2px;
`

const StyledStatusContainer = styled.div`
  display: flex;
  align-items: center;
  width: 155px;
`

const StyledAvailableText = styled(Typography)`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`
const StyledAvailableGreenText = styled(StyledAvailableText)`
  color: #2f8656;
`

const StyledUnavailableText = styled(StyledAvailableText)`
  color: #797979;
`

export interface ModalEligibilityFoundProps
  extends React.ComponentPropsWithoutRef<'div'> {
  benefits: Benefits
}

export const ModalEligibilityFound: React.FC<ModalEligibilityFoundProps> = ({
  benefits,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <StyledModalTitle>{t('eligibility_found_title')}</StyledModalTitle>

      <Modal.Content>
        <Typography>{t('eligibility_found_text')}</Typography>

        <StyledBenefitList>
          {benefits.map((benefit) => (
            <StyledBenefitLi key={benefit.benefitCode}>
              {benefit.isAvailable ? (
                <>
                  <StyledBenefitIconContainer>
                    <StyledBenefitIcon
                      src={benefitIcons[benefit.benefitCode]}
                    />
                    <StyledAvailableText $variant="span">
                      {t(benefit.benefitCode)}
                    </StyledAvailableText>
                  </StyledBenefitIconContainer>
                  <StyledStatusContainer>
                    <StyledCheckIcon />
                    <StyledAvailableGreenText $variant="span">
                      {t('eligibility_benefit_available')}
                    </StyledAvailableGreenText>
                  </StyledStatusContainer>
                </>
              ) : (
                <>
                  <StyledBenefitIconContainer>
                    <StyledBenefitIconUnavailable
                      src={benefitIcons[benefit.benefitCode]}
                    />
                    <StyledUnavailableText $variant="span">
                      {t(benefit.benefitCode)}
                    </StyledUnavailableText>
                  </StyledBenefitIconContainer>
                  <StyledStatusContainer>
                    <StyledCrossIcon src={CrossIcon} />
                    <StyledUnavailableText $variant="span">
                      {t('eligibility_benefit_unavailable')}
                    </StyledUnavailableText>
                  </StyledStatusContainer>
                </>
              )}
            </StyledBenefitLi>
          ))}
        </StyledBenefitList>

        <Divider />

        <StyledSecurityInfo>{t('errorModal.securityInfo')}</StyledSecurityInfo>
      </Modal.Content>
    </div>
  )
}
