import React from 'react'
import styled from 'styled-components'
import { Typography, TypographyProps } from '../../Typography'

export interface DescriptionProps extends TypographyProps {}

const StyledDescription = styled(Typography)<DescriptionProps>`
  font: ${({ theme: { fonts } }) => fonts.paragraph};
  letter-spacing: 0.5px;
  line-height: 1.44;
  font-size: 16px;
  margin-bottom: 40px;
`

export const Description: React.FC<DescriptionProps> = ({
  children,
  ...props
}) => (
  <StyledDescription $variant="h2" $align="left" {...props}>
    {children}
  </StyledDescription>
)
