import { createContext, FC, useCallback, useContext } from 'react'
import { useAsync } from 'react-async'
import { Appointment, AppointmentService } from '../../api'
import { useConfig } from '../../Context'

// #region ::: PARTIALS
// HOOK
export const useTabApiAppointment = () => useContext(TabApiAppointmentContext)

// CONTEXT
export const TabApiAppointmentContext = createContext(
  {} as ContextAppointmentValueType
)

// TYPES
const useAsyncWithAppointment = () => useAsync<Appointment>({})
type ContextAppointmentValueType = ReturnType<typeof useAsyncWithAppointment>
// #endregion

export const ProviderAppointment: FC = ({ children }) => {
  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
  } = useConfig()

  const getAppointment = useCallback(() => {
    return AppointmentService.getAppointment({
      appointmentId,
      brand,
      ocpApimSubscriptionKey,
    })
  }, [appointmentId, brand, ocpApimSubscriptionKey])

  const tabApiAppointmentContextValue = useAsync<Appointment>({
    promiseFn: getAppointment,
  })

  return (
    <TabApiAppointmentContext.Provider value={tabApiAppointmentContextValue}>
      {children}
    </TabApiAppointmentContext.Provider>
  )
}
