import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { CensoredContacts, OtpError, OtpWithMode } from '@root/api/models/Otp'
import { OtpService } from '@root/api/services/OtpService'
import { useConfig } from '@root/Context'

import ModalStyle from './OTPModalStyling'
import { Typography } from '@components/Typography'
import { Loader } from '@components/Loader'

import mailIcon from '../../assets/icons/email.svg'
import arrowIcon from '../../assets/icons/arrow.svg'
import smsIcon from '../../assets/icons/sms.svg'
import React, { useEffect, useState } from 'react'
import { useGetCurrentLanguage } from '@hooks/useLanguage'
import { useAsync } from 'react-async'
import { ResendCountdown } from '@components/OTPModal/ResendCountdown'
import { getOtpResendCountdown } from '@utils/date'

const StyledTargetsWrapper = styled.div`
  display: grid;
  gap: 15px;
`

const StyledTargetButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  margin: 0;
  border: none;
  padding: 1rem;
  background-color: inherit;

  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (prefers-color-scheme: dark) {
    img {
      filter: invert(100%);
    }
  }
`

const StyledArrowIcon = styled.img.attrs({
  src: arrowIcon,
  alt: 'Arrow icon',
})`
  transform: rotate(90deg);
  margin-inline-start: auto;
`

const CONTACT_TYPE_SYSTEM_TYPE_MAP: Record<string, string> = {
  phone: 'sms',
  email: 'mail',
}

const SYSTEM_TYPE_ICON_MAP: Record<string, string> = {
  sms: smsIcon,
  mail: mailIcon,
}

type StepOneProps = {
  navigateToStepTwo: (otpResponse: OtpWithMode) => void
  contacts: CensoredContacts | undefined
  isLoading: boolean
  otpInvalidated?: boolean
}

const StepOne = ({
  navigateToStepTwo,
  isLoading,
  contacts,
  otpInvalidated,
}: StepOneProps) => {
  const { t } = useTranslation()

  const [hasError, setHasError] = useState(false)
  const [otpRequestEnabled, setOtpRequestEnabled] = useState(true)

  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()

  const acceptedLanguage = useGetCurrentLanguage()

  const {
    data: otpResponse,
    isPending,
    error: apiError,
    run: sendOtpRequest,
  } = useAsync({
    deferFn: (args) => {
      const [system] = args
      return OtpService.generateOtp({
        acceptedLanguage,
        appointmentId,
        brand,
        caller,
        ocpApimSubscriptionKey,
        system,
      })
    },
  })

  const showLoader = isLoading || isPending
  const showError =
    (!showLoader && (Boolean(apiError) || hasError))

  useEffect(() => {
    if (!otpResponse) {
      return
    }
    const otpResponseError =
      !otpResponse.otpSent || Boolean(otpResponse?.errorCode)
    if (otpResponseError) {
      setHasError(true)
      setOtpRequestEnabled(false)
      return
    }
    navigateToStepTwo(otpResponse)
  }, [otpResponse, navigateToStepTwo])

  useEffect(() => {
    setOtpRequestEnabled(!otpInvalidated)
  }, [otpInvalidated])

  const onTargetClick = (system: string) => {
    if (!otpRequestEnabled) {
      return
    }
    sendOtpRequest(system)
  }

  return (
    <ModalStyle.StyledContent>
      <ModalStyle.StyledTypographyWithMarginBottom
        $variant="h1"
        $weight={700}
        $align="left"
      >
        {t('anz.otp.stepOne.heading')}
      </ModalStyle.StyledTypographyWithMarginBottom>
      <ModalStyle.StyledTypographyWithMarginBottom $align="left">
        {t('anz.otp.stepOne.description')}
      </ModalStyle.StyledTypographyWithMarginBottom>

      {showLoader && <Loader height={200} />}

      {!showLoader && (
        <StyledTargetsWrapper>
          {Object.entries(contacts || {}).flatMap(
            ([contactType, contactValue]) => {
              if (!contactValue) {
                return []
              }
              const system = CONTACT_TYPE_SYSTEM_TYPE_MAP[contactType]
              const systemIcon = SYSTEM_TYPE_ICON_MAP[system]
              const systemAlt = `${system} icon`
              const systemLabel = `${t(
                `anz.otp.stepOne.${system}`
              )}: ${contactValue}`

              return (
                <StyledTargetButton
                  disabled={!otpRequestEnabled}
                  key={system}
                  onClick={onTargetClick.bind(null, system)}
                >
                  <img src={systemIcon} alt={systemAlt} />
                  <Typography
                    $align="left"
                    $noMargin
                    $variant={'span'}
                    $wordBreak={'break-all'}
                  >
                    {systemLabel}
                  </Typography>
                  <StyledArrowIcon />
                </StyledTargetButton>
              )
            }
          )}
        </StyledTargetsWrapper>
      )}
      {!showError || otpRequestEnabled ? null : (
        <Typography $color="error" $align="center" $noMargin>
          {t('anz.otp.generationError')}
        </Typography>
      )}
      {!otpRequestEnabled && (
        <ResendCountdown
          expiryTimestamp={getOtpResendCountdown(
            otpResponse?.timeBeforeRecreate
          )}
          resendType="resend"
          onExpire={setOtpRequestEnabled.bind(null, true)}
        />
      )}
    </ModalStyle.StyledContent>
  )
}

export default StepOne
