import React from 'react'
import { useTimer } from 'react-timer-hook'
import { Typography } from '@components/Typography'
import { useTranslation } from 'react-i18next'

type ResendCountdownProps = {
  expiryTimestamp: Date
  resendType: 'resend' | 'recheck'
  onExpire: () => void
}

export const ResendCountdown = ({
  expiryTimestamp,
  onExpire,
  resendType,
}: ResendCountdownProps) => {
  const { t } = useTranslation()

  const { minutes, seconds, isRunning } = useTimer({
    expiryTimestamp,
    onExpire,
  })

  return !isRunning ? null : (
    <Typography $align="left">
      {t(`anz.otp.stepTwo.${resendType}Countdown`, { minutes, seconds })}
    </Typography>
  )
}
