import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const StyledButton = styled.button`
  background: none;
  border: 0;
  color: #326fa8;
  cursor: pointer;
  font: normal normal 200 16px / normal sofia-pro;
  margin: 0;
  padding: 0;
  text-align: initial;
  white-space: pre-wrap;
`

export const ModalEligibilityTriggerButton: React.FC<
  React.ComponentPropsWithoutRef<'button'>
> = (props) => {
  const { t } = useTranslation()

  return (
    <>
      <StyledButton type="button" {...props}>
        {t('eligibility_trigger_button')}
      </StyledButton>
    </>
  )
}
