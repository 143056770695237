import { UiSchema } from '@rjsf/core'
import { JSONSchema7 } from 'json-schema'
import { CountryType } from '@root/types/config'

export type jsonSchemaCustom = JSONSchema7 & {
  jsonSchemaVersion: string
  page: string
  types: {
    [key: string]: string
  }

  showGeneralReview?: boolean
  singlePropertiesToReview?: {
    [key: string]: 'icon' | 'text'
  }
}

export type uiSchemaCustom = UiSchema & {
  'ui:grid': Array<Array<string>>
}

export type formConstructor = {
  country: CountryType
  jsonSchema: Partial<jsonSchemaCustom>
  uiSchema: Partial<uiSchemaCustom>
}

export const RELATIVES_VALUES = Object.freeze([
  'sibling',
  'parent',
  'granparent',
  'aunt_uncle',
  'niece_nephew',
  'cousin',
] as const)

export type RelativesRecord = Record<typeof RELATIVES_VALUES[number], boolean>

export type HealthConditionEntry = {
  isSelf: boolean
  isRelatives: boolean
  relatives: RelativesRecord
}

export type HealthConditions = Record<string, HealthConditionEntry>

export const isHealthConditions = (value: any): value is HealthConditions => {
  return (
    typeof value === 'object' &&
    Object.values(value).every(
      (condition) =>
        condition &&
        typeof condition === 'object' &&
        'isSelf' in condition &&
        'isRelatives' in condition
    )
  )
}

export type ArrayEnumOption = {
  value: string
  label: string
}

export const isArrayEnumOptions = (value: any): value is ArrayEnumOption[] => {
  return (
    Array.isArray(value) &&
    value.every(
      (item) => typeof item === 'object' && 'value' in item && 'label' in item
    )
  )
}

export const BLANK_RELATIVES_RECORD: RelativesRecord = RELATIVES_VALUES.reduce(
  (accumulator, relative) => ({ ...accumulator, [relative]: false }),
  {} as RelativesRecord
)
