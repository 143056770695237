import { FieldProps } from '@rjsf/core'
import styled from 'styled-components'

import { Typography } from '@components/Typography'

const StyledContainer = styled.div`
  margin-bottom: 39px;
`

const TitleField = (props: React.PropsWithChildren<FieldProps>) => (
  <StyledContainer>
    <Typography $weight="normal" $align="left">
      {props.title}
    </Typography>
  </StyledContainer>
)

export default TitleField
