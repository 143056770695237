import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { WidgetProps } from '@rjsf/core'
import { useTranslation } from 'react-i18next'

import { InputGroup } from '@components/InputGroup'
import { Typography } from '@components/Typography'
import { HealthCondition } from '@root/anz/components/HealthCondition'
import {
  HealthConditions,
  isArrayEnumOptions,
  isHealthConditions,
  RELATIVES_VALUES,
  RelativesRecord,
} from '@root/anz/types/anzTypes'
import { RelativesModal } from '@root/anz/components/RelativesModal'

const StyledTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 675px) {
    width: calc((100% - 2rem) / 2);
  }
`

const StyledRow2 = styled.div`
  display: none;

  @media screen and (min-width: 675px) {
    display: flex;
    width: calc((100% - 2rem) / 2);
  }
`

const StyledCell = styled.div`
  display: inline-block;
  padding: 10px;
  text-align: left;
`

const StyledCell1 = styled(StyledCell)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`

const StyledCell2 = styled(StyledCell)`
  width: 4em;
  flex-shrink: 0;
`

const StyledColumnTitle = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 13px;
`

const RelativeWidget = ({
  disabled,
  label,
  options: { enumOptions: conditions = [] } = {},
  onChange,
  value: selectedValues,
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()

  const blankHealthConditions: HealthConditions = useMemo(
    () =>
      ((isArrayEnumOptions(conditions) && conditions) || []).reduce(
        (accumulator, { value }) => ({
          ...accumulator,
          [value]: {
            isSelf: false,
            isRelatives: false,
            relatives: RELATIVES_VALUES.reduce(
              (acc, relative) => ({ ...acc, [relative]: false }),
              {}
            ),
          },
        }),
        {}
      ),
    [conditions]
  )

  const [widgetHealthConditions, setWidgetHealthConditions] =
    useState<HealthConditions>({
      ...blankHealthConditions,
      ...JSON.parse(selectedValues || '{}'),
    })

  const [showModalForConditionName, setShowModalForConditionName] = useState<
    keyof HealthConditions | undefined
  >(undefined)

  useEffect(() => {
    if (isHealthConditions(widgetHealthConditions)) {
      onChange(JSON.stringify(widgetHealthConditions))
      return
    }
    console.warn(
      `Invalid value for RelativeWidget: ${JSON.stringify(
        widgetHealthConditions,
        null,
        2
      )}`
    )
  }, [onChange, widgetHealthConditions])

  const handleRelativesModalConfirm = (values: RelativesRecord) => {
    if (!showModalForConditionName) return
    setWidgetHealthConditions((currentConditions) => ({
      ...currentConditions,
      [showModalForConditionName]: {
        ...currentConditions[showModalForConditionName],
        relatives: values,
      },
    }))
    setShowModalForConditionName(undefined)
  }

  return !isArrayEnumOptions(conditions) ? (
    <pre style={{ color: 'orangered' }}>
      Error rendering RelativeWidget: enumOptions is not an array of objects
    </pre>
  ) : (
    <>
      <InputGroup>
        <InputGroup.Label>
          {`${label} `}
          <Typography
            $variant="span"
            $weight={200}
            style={{ border: '2x solid blue' }}
          >
            ({t('core.selectAllThatApply')})
          </Typography>
        </InputGroup.Label>

        <InputGroup.Inputs>
          <StyledTable>
            <StyledRow>
              <StyledCell1 />
              <StyledCell2>
                <StyledColumnTitle $noMargin $transform="uppercase">
                  {t('core.self')}
                </StyledColumnTitle>
              </StyledCell2>
              <StyledCell2>
                <StyledColumnTitle $noMargin $transform="uppercase">
                  {t('core.relatives')}
                </StyledColumnTitle>
              </StyledCell2>
            </StyledRow>

            <StyledRow2>
              <>
                <StyledCell1 />
                <StyledCell2>
                  <StyledColumnTitle $noMargin $transform="uppercase">
                    {conditions?.length && t('core.self')}
                  </StyledColumnTitle>
                </StyledCell2>
                <StyledCell2>
                  <StyledColumnTitle $noMargin $transform="uppercase">
                    {conditions?.length && t('core.relatives')}
                  </StyledColumnTitle>
                </StyledCell2>
              </>
            </StyledRow2>
            {conditions.map(({ value }, index) => (
              <HealthCondition
                key={`condition-${value}-${index}`}
                conditionName={value}
                disabled={disabled}
                value={widgetHealthConditions[value]}
                onChangeCallback={setWidgetHealthConditions}
                onModalTriggerClick={setShowModalForConditionName}
              />
            ))}
          </StyledTable>
        </InputGroup.Inputs>
      </InputGroup>
      {!showModalForConditionName ||
      !widgetHealthConditions[showModalForConditionName]?.relatives ? null : (
        <RelativesModal
          isOpen={Boolean(showModalForConditionName)}
          values={widgetHealthConditions[showModalForConditionName].relatives}
          onClose={setShowModalForConditionName}
          onConfirm={handleRelativesModalConfirm}
        />
      )}
    </>
  )
}

export default RelativeWidget
