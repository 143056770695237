/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Email type to send
 */
export enum EmailType {
  REMINDER = 'REMINDER',
  CONTINUE_LATER = 'CONTINUE_LATER',
}
