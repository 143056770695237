import React, { useContext, useEffect } from 'react'
import { WidgetProps } from '@rjsf/core'
import { InputGroup } from '@components/InputGroup'
import { Radio } from '@components/Radio'
import { handleOnChange } from '@components/RJSF/utils/handleOnChange'
import { isArrayEnumOptions } from '@root/anz/types/anzTypes'
import { FormDataContext } from '@root/anz/components/AnzForm/JSONSchemaForm'
import { isStringArray } from '@root/anz/components/AnzForm/RJSF.utils'
import styled from 'styled-components'

/* Yet another widget?!
 *  Oh, yeah ..
 *  We could have done a generic one for fields visible only when a certain condition is met ..
 *  but, guess what? We didn't, because .. ** snare drum roll ** .. we have NEITHER budget NOR time for that!
 *  Sounds quite familiar, doesn't it?
 *  */

const CONTACT_LENS_LABELS = [
  'Contact Lenses',
  'Lentes De Contacto',
  'Lentilles De Contact',
]

const isWearingContactLenses = (eyewear: string[]): boolean =>
  eyewear.some((item) => CONTACT_LENS_LABELS.includes(item))

const ContactLensInterestWidgetGroup = styled(InputGroup)`
  &[hidden] {
    display: none;
  }

  div:has(input[type='radio']) {
    margin: 0.5rem 0 0;
  }
`

export const ContactLensInterestWidget = ({
  disabled,
  label,
  id,
  onChange,
  options,
  rawErrors,
  required,
  value: fieldValue,
}: WidgetProps) => {
  const { eyewear } = useContext(FormDataContext)

  const enumOptions = isArrayEnumOptions(options.enumOptions)
    ? options.enumOptions.flatMap((option) =>
        Boolean(option.value) ? option : []
      )
    : []

  const selectedEyewear = isStringArray(eyewear) ? eyewear : []

  const hidden = isWearingContactLenses(selectedEyewear)

  useEffect(() => {
    hidden && onChange('')
  }, [hidden, onChange])

  return (
    <ContactLensInterestWidgetGroup hidden={hidden}>
      <InputGroup.Label required={required}>{label}</InputGroup.Label>

      <InputGroup.Inputs>
        {enumOptions.map(({ label, value }) => {
          return (
            <Radio
              checked={fieldValue === value}
              disabled={disabled}
              key={`CL-interest-widget-${label}`}
              label={label}
              name={id}
              value={value}
              onChange={handleOnChange(onChange)}
              required={false}
            />
          )
        })}
      </InputGroup.Inputs>
      <InputGroup.Errors
        error={!!rawErrors?.length}
        errorMessage={rawErrors?.join('\n')}
      />
    </ContactLensInterestWidgetGroup>
  )
}
