import React from 'react'
import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
import { onFormatPhoneNumber } from '@root/utils/formatPhoneNumber'

const PhoneNumberWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value.replace(/\D/g, '') || ''
    const formattedPhoneNumber = onFormatPhoneNumber(newPhoneNumber)
    onChange(formattedPhoneNumber)
  }

  return (
    <TextField
      name={id}
      value={value}
      label={label}
      onChange={handleOnChange}
      disabled={disabled}
      required={required}
      $fullWidth
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
    />
  )
}

export default PhoneNumberWidget
