import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { Modal, ModalProps } from '@components/Modal'
import { LegalDocument } from '../LegalDocument'
import { DocumentType } from '@api'
import { DocumentRecap } from '@root/api/models/DocumentRecap'

interface DocumentModalProps extends ModalProps {
  onRequestClose?: () => void
  docType: DocumentType
  isDocumentsSigned: boolean
  recapDocumentsSigned: DocumentRecap[]
}

const ModalStyles = createGlobalStyle`
    .intake__modal-content.intake__documentmodal {
        position: absolute;
        top: 0;
        left: unset;
        right: 0;
        height: 100vh;
        max-height: unset;
        max-width: unset;
        min-width: unset;
        min-height: unset;
        transform: unset;
        background-color: ${({ theme: { colors } }) =>
          colors.backgroundTertiary};
        overflow: hidden auto;
        border-radius: 0;
        outline: none;
        padding: 0;
        width: 100%;

        ${({ theme }) => theme.breakpoints.media.large} {
            width: 476px;
        }
    }

    #intake__modal-description {
        height: 100%;
        font: inherit;
        text-align: center;
    }
`

export const DocumentModal: React.FC<DocumentModalProps> = ({
  isDocumentsSigned,
  recapDocumentsSigned,
  docType,
  ...props
}) => (
  <>
    <ModalStyles />
    <Modal id="document-modal" className="intake__documentmodal" {...props}>
      <Modal.Content>
        <LegalDocument
          backAction={props.onRequestClose}
          docType={docType}
          isDocumentsSigned={isDocumentsSigned}
          recapDocumentsSigned={recapDocumentsSigned}
        />
      </Modal.Content>
    </Modal>
  </>
)
