import React, { useCallback, useMemo, useState } from 'react'
import { useAsync } from 'react-async'

import {
  Benefits,
  EligibilitySearch,
  Form,
  InsuranceService,
  PrimaryMember,
} from '@api'
import { useConfig } from '@root/Context'
import { useTabApiForm } from '@services/TabApiProvider/hooks/useTabApiForm'

import { Modal } from '@components/Modal'
import { ErrorModalContent } from '@components/ErrorModal'
import {
  ModalEligibilityLoading,
  ModalEligibilityNotFound,
  ModalEligibilityFound,
  ModalEligibilityTriggerButton,
} from '@components/ModalEligibility'

export interface ModalEligibilityProps {
  insuranceProvider?: string
  primaryMemberID?: string
  primaryMemberSSN?: string
  primaryHolderDOB?: string
  primaryHolderFirstName?: string
  primaryHolderLastName?: string
  primaryHolderSSN?: string
}

const neverEndingFormPromise = new Promise<Benefits>(() => {})

export const isValidEligibilitySearch = (
  RequestBody: any
): RequestBody is EligibilitySearch =>
  'dob' in RequestBody &&
  'firstName' in RequestBody &&
  'insuranceName' in RequestBody &&
  'lastName' in RequestBody &&
  'zipCode' in RequestBody

export const isValidPrimaryMember = (Member: any): Member is PrimaryMember =>
  Member.firstName && Member.lastName ? true : false

export const ModalEligibility: React.FC<ModalEligibilityProps> = ({
  insuranceProvider,
  primaryMemberID,
  primaryMemberSSN,
  primaryHolderDOB,
  primaryHolderFirstName,
  primaryHolderLastName,
  primaryHolderSSN,
}) => {
  const { brand, subscriptionKey: ocpApimSubscriptionKey, caller } = useConfig()

  const { data: formDataHook } = useTabApiForm()
  const formData = formDataHook as Form

  const primaryMember: PrimaryMember = useMemo(
    () => ({
      firstName: primaryHolderFirstName,
      lastName: primaryHolderLastName,
      dob: primaryHolderDOB,
      last4SSN: primaryHolderSSN,
    }),
    [
      primaryHolderFirstName,
      primaryHolderLastName,
      primaryHolderDOB,
      primaryHolderSSN,
    ]
  )

  const requestBody = useMemo(
    () => ({
      insuranceName: insuranceProvider,
      firstName: formData?.firstName,
      lastName: formData?.lastName,
      dob: formData?.dob,
      zipCode: formData?.zipCode,
      memberId: primaryMemberID,
      last4SSN: primaryMemberSSN,
      PrimaryMember: primaryMember,
    }),
    [
      insuranceProvider,
      formData,
      primaryMemberID,
      primaryMemberSSN,
      primaryMember,
    ]
  )

  const check = useCallback(
    () =>
      isValidEligibilitySearch(requestBody)
        ? InsuranceService.getInsuranceInfo({
            brand,
            caller,
            ocpApimSubscriptionKey,
            requestBody: requestBody,
          })
        : neverEndingFormPromise,
    [brand, caller, requestBody, ocpApimSubscriptionKey]
  )

  const [eligibilityModalIsOpen, setEligibilityIsOpen] = useState(false)

  const { data, error, isPending, run } = useAsync({
    deferFn: check,
  })

  async function openEligibilityModal() {
    setEligibilityIsOpen(true)
    run()
  }

  function closeEligibilityModal() {
    setEligibilityIsOpen(false)
  }

  return (
    <>
      <ModalEligibilityTriggerButton
        onClick={openEligibilityModal}
        className="intake__eligibility-toggle-button"
      />
      <Modal
        id="elegibility-modal"
        isOpen={eligibilityModalIsOpen}
        onRequestClose={closeEligibilityModal}
        showCloseButton
      >
        {isPending ? (
          <ModalEligibilityLoading className="intake__eligibility-loading" />
        ) : error ? (
          <ErrorModalContent
            onRequestClose={closeEligibilityModal}
            className="intake__eligibility-error"
          />
        ) : !data?.length ? (
          <ModalEligibilityNotFound
            onRetry={openEligibilityModal}
            className="intake__eligibility-not-found"
          />
        ) : (
          <ModalEligibilityFound
            benefits={data}
            className="intake__eligibility-found"
          />
        )}
      </Modal>
    </>
  )
}
