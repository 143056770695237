import React from 'react'
import { ErrorModal } from '../ErrorModal'

export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <ErrorModal isOpen onRequestClose={window.location.reload} />
    }

    return children
  }
}
