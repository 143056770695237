import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Button } from '@components/Button'
import { Layout } from '@components/Layout'
import { Loader } from '@components/Loader'
import { Typography } from '@components/Typography'
import { useConfig } from '../../Context'
import { useTabApiAppointment } from '@services/TabApiProvider/ProviderAppointment'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import usePageClassname from '@hooks/usePageClassname'
import { Modal } from '@components/Modal'
import { capitalize } from '@root/utils/stringUtils'
import useJwt from '@hooks/useJwt'

const StyledContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};

  padding: 61px;
`

const StyledCtaButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * {
    width: 50%;

    & + * {
      margin-top: 30px;
    }
  }

  ${({ theme }) => theme.breakpoints.media.large} {
    flex-direction: row;

    & > * {
      width: auto;

      & + * {
        margin-top: 0;
        margin-left: 30px;
      }
    }
  }
`

const CtaSection = () => {
  const { t } = useTranslation()
  const { thankYouPageCta } = useConfig()

  const onClick = (url: string) => () => window.open(url, '_blank')

  if (thankYouPageCta) {
    return (
      <section>
        <Typography $weight="bold">{t('core.additionalCta')}</Typography>
        <StyledCtaButtonWrapper>
          {thankYouPageCta.map(({ description, link, analyticsTag }, index) => (
            <Button
              key={`thankYouPageCta-link-${index}`}
              data-element-id={analyticsTag}
              onClick={onClick(link)}
            >
              {description}
            </Button>
          ))}
        </StyledCtaButtonWrapper>
      </section>
    )
  }

  return null
}

const StyledCreateAccountSection = styled.section`
  text-align: center;
  margin-bottom: 58px;
`

const CreateAccountSection = () => {
  const { t } = useTranslation()
  const { createAccountAction, brand } = useConfig()
  const { data, isLoading: isLoadingAppointment } = useTabApiAppointment()
  const isGuest = data?.patient.userType === 'ONLINE_GUEST'

  usePageClassname('confirm-page')

  if (isLoadingAppointment) return <Loader />

  if (isGuest && createAccountAction) {
    return (
      <StyledCreateAccountSection>
        <Typography $weight="bold">{t('confirmation.guestCta2')}</Typography>

        <Button
          $outlined
          onClick={createAccountAction}
          data-element-id="X_OEE_TYP_CreateAccount"
        >
          {t('core.createAccountNow')}
        </Button>

        <Typography $weight="bold">
          {t('confirmation.guestCta2End', {
            site: brand,
          })}
        </Typography>
      </StyledCreateAccountSection>
    )
  }

  return null
}

const Confirmation: React.FC = () => {
  const { t } = useTranslation()
  const { brandWebsite } = useConfig()
  const { data: formData, isLoading: isLoadingForm } = useTabApiForm()
  const data = flatFormObjectIfAnz(formData)
  const { removeJwt } = useJwt()

  const handleClick = () =>
    (window.location.href = brandWebsite || window.location.href)

  useEffect(() => {
    removeJwt()
  }, [removeJwt])

  if (isLoadingForm) return <Loader fullHeight />

  if (data && !isLoadingForm) {
    const { firstName } = data

    return (
      <Layout>
        <Modal
          isOpen
          showCloseButton
          closeButtonType="text"
          onRequestClose={handleClick}
        >
          <StyledContainer>
            <Typography $align="center" $weight={600} $variant="h1">
              {t('confirmation.title', { name: capitalize(firstName) })}
            </Typography>
            <Typography $align="center">
              {t('confirmation.descriptionAnz')}
            </Typography>

            <CtaSection />

            <CreateAccountSection />
          </StyledContainer>
        </Modal>
      </Layout>
    )
  }

  return null
}

export default Confirmation
