import { WidgetProps } from '@rjsf/core'

import { Checkbox } from '@components/Checkbox'

import { handleOnChangeCheckboxOrRadio } from '../utils/handleOnChange'

const CheckboxWidget = ({
  label,
  onChange,
  required,
  disabled,
  value,
  id,
  rawErrors,
}: React.PropsWithChildren<WidgetProps>) => {
  return (
    <Checkbox
      name={id}
      label={label}
      value={value}
      onChange={handleOnChangeCheckboxOrRadio(onChange)}
      disabled={disabled}
      required={required}
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
    />
  )
}

export default CheckboxWidget
