import React from 'react'
import clsx from 'clsx'

export interface OptionProps extends React.ComponentPropsWithRef<'option'> {}

export const Option: React.FC<OptionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <option className={clsx('intake__option', className)} {...props}>
      {children}
    </option>
  )
}
