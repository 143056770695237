import React, { useContext, useEffect, useState } from 'react'
import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
import { FormDataContext } from '@root/anz/components/AnzForm/JSONSchemaForm'
import { differenceInYears } from 'date-fns'

const parseAgeValue = (value: string) => {
  const now = new Date()
  const dob = new Date(value)
  const diff = differenceInYears(now, dob)

  return diff >= 0 ? diff.toString() : ''
}

const AgeWidget = ({
  label,
  id,
  onChange,
  rawErrors,
  required,
  schema: { maxLength, minLength },
}: WidgetProps) => {
  const formData = useContext(FormDataContext)
  const [intermediateValue, setIntermediateValue] = useState('')
  const currentValue = parseAgeValue((formData.dob as string) || '') || ''

  useEffect(() => {
    setIntermediateValue(currentValue)
  }, [currentValue])

  useEffect(() => {
    if (intermediateValue !== currentValue) {
      // workaround to prevent race condition when the RSJF onChange is invoked
      // before the form was actually updated as result of widgets onChange invocation
      if ('requestIdleCallback' in window) {
        // @ts-ignore
        window.requestIdleCallback(() => onChange(currentValue))
        return
      }
      setTimeout(() => onChange(currentValue), 0)
    }
  }, [onChange, currentValue, intermediateValue])

  return (
    <TextField
      $fullWidth
      disabled={true}
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
      label={label}
      maxLength={maxLength}
      minLength={minLength}
      name={id}
      required={required}
      value={currentValue}
    />
  )
}

export default AgeWidget
