import { useMemo } from 'react'
import { useAvailableDocuments } from '@services/tabApi'
import { DocumentType } from '../api/models/DocumentType'
import { DocumentVisibilities } from '../api/models/DocumentVisibilities'
import { DocumentVisibility } from '../api/models/DocumentVisibility'

export const useVisibleDocuments = (
  clinicId: string,
  clinicType: string,
  clinicName: string
): Record<DocumentType, DocumentVisibility[]> => {
  const { data } = useAvailableDocuments(clinicId, clinicType, clinicName)

  const emptyDocuments = {
    dif: [],
    pirf: [],
    privacy: [],
  }

  const convertArrayToObject = (array: DocumentVisibilities) => {
    return {
      dif: array.filter(
        (e) =>
          e.documentType === DocumentType.DIF ||
          e.documentType === DocumentType.DIF.toUpperCase()
      ),
      pirf: array.filter(
        (e) =>
          e.documentType === DocumentType.PIRF ||
          e.documentType === DocumentType.PIRF.toUpperCase()
      ),
      privacy: array.filter(
        (e) =>
          e.documentType === DocumentType.PRIVACY ||
          e.documentType === DocumentType.PRIVACY.toUpperCase()
      ),
    }
  }

  return useMemo(() => {
    if (data) {
      return convertArrayToObject(data)
    }
    return emptyDocuments
  }, [data])
}
