import { isJwtValid } from '@root/api/core/request'
import useJwt from './useJwt'

type IsAnzAuthenticated = () => {
  isAuthenticated: boolean
}

const useIsAnzAuthenticated: IsAnzAuthenticated = () => {
  const { jwt } = useJwt()

  const isAuthenticated = isJwtValid(jwt)

  return {
    isAuthenticated,
  }
}

export default useIsAnzAuthenticated
