import React from 'react'
import clsx from 'clsx'
import styled from 'styled-components'

export interface OptionProps extends React.ComponentPropsWithRef<'option'> {}

const StyledOption = styled.option`
  color: ${({ theme: { colors } }) => colors.onSurface};
`

export const Option: React.FC<OptionProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <StyledOption className={clsx('intake__option', className)} {...props}>
      {children}
    </StyledOption>
  )
}
