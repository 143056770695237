import React, { ChangeEvent, SetStateAction } from 'react'
import styled from 'styled-components'
import { Checkbox } from '@components/Checkbox'
import { Typography } from '@components/Typography'
import { useTranslation } from 'react-i18next'
import {
  BLANK_RELATIVES_RECORD,
  HealthConditionEntry,
  HealthConditions,
} from '@root/anz/types/anzTypes'

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 675px) {
    width: calc((100% - 2rem) / 2);
  }
`

const StyledCell = styled.div`
  display: inline-flex;
  padding: 10px;
  text-align: left;
  align-items: center;
`

const ConditionWrapper = styled(StyledCell)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`

const CheckboxWrapper = styled(StyledCell)`
  width: 4em;
  flex-shrink: 0;

  label {
    margin: 0;
  }
`

const StyledModalTrigger = styled(Typography)`
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.primary};
`

type AnzHealthConditionProps = {
  conditionName: string
  disabled: boolean
  value: HealthConditionEntry
  onChangeCallback: React.Dispatch<SetStateAction<HealthConditions>>
  onModalTriggerClick: React.Dispatch<SetStateAction<string | undefined>>
}

export const HealthCondition = ({
  conditionName,
  disabled,
  value: { isSelf, isRelatives, relatives },
  onChangeCallback,
  onModalTriggerClick,
}: AnzHealthConditionProps) => {
  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    onChangeCallback((currentConditions: HealthConditions) => ({
      ...currentConditions,
      [conditionName]: {
        ...currentConditions[conditionName],
        [name]: checked,
        relatives: BLANK_RELATIVES_RECORD,
      },
    }))
  }

  return (
    <StyledRow>
      <ConditionWrapper>
        <Wrapper>
          <Typography $noMargin $transform="uppercase" $align="left">
            {conditionName}
          </Typography>
          {isRelatives && (
            <>
              <StyledModalTrigger
                $noMargin
                $color="primary"
                $align="left"
                $weight="bold"
                role="button"
                tabIndex={0}
                onClick={onModalTriggerClick.bind(null, conditionName)}
              >
                {t('healthHistory.whichRelatives')}
              </StyledModalTrigger>

              <Typography $noMargin $align="left">
                {Object.entries(relatives || {})
                  .flatMap(([key, value]) =>
                    value ? t(`relatives.${key.toLowerCase()}`) : []
                  )
                  .join(', ')}
              </Typography>
            </>
          )}
        </Wrapper>
      </ConditionWrapper>

      <CheckboxWrapper>
        <Checkbox
          checked={isSelf}
          disabled={disabled}
          name="isSelf"
          onChange={handleChange}
        />
      </CheckboxWrapper>

      <CheckboxWrapper>
        <Checkbox
          checked={isRelatives}
          disabled={disabled}
          name="isRelatives"
          onChange={handleChange}
        />
      </CheckboxWrapper>
    </StyledRow>
  )
}
