/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Benefits } from '../models/Benefits'
import type { EligibilitySearch } from '../models/EligibilitySearch'
import { request as __request } from '../core/request'

export class InsuranceService {
  /**
   * Get insurance info of given appointment ID.
   * This method uses data provided in the form to retrieve insurance benefit of the customer.
   * @returns Benefits OK
   * @throws ApiError
   */
  public static async getInsuranceInfo({
    brand,
    caller,
    ocpApimSubscriptionKey,
    requestBody,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    requestBody?: EligibilitySearch
  }): Promise<Benefits> {
    const result = await __request({
      method: 'POST',
      path: `/${brand}/eligibility`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      body: requestBody,
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }
}
