import React from 'react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'

export interface IconProps extends React.ComponentPropsWithRef<'div'> {
  src: string
}

const StyledIcon = styled.div<IconProps>`
  ${({ src }) => css`
    width: 30px;
    height: 30px;
    margin-bottom: 6px;
    margin-right: 10px;
    background-image: url(${src});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 12px;
    @media (prefers-color-scheme: dark) {
      filter: invert(100%);
    }
  `}
`

export const Icon: React.FC<IconProps> = ({ className, ...props }) => {
  return <StyledIcon className={clsx('intake__icon', className)} {...props} />
}
