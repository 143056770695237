import { Helmet } from 'react-helmet'
import {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components'

export type StoreNameProps = {
  storeName: BrandName
}

export type BrandName = 'LC' | 'OPSM' | 'PV' | 'TO' | 'FE' | 'VT'

const size = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px',
} as const

const device = {
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  huge: `(min-width: ${size.huge})`,
} as const

const media = {
  small: `@media only screen and ${device.small}`,
  medium: `@media only screen and ${device.medium}`,
  large: `@media only screen and ${device.large}`,
  huge: `@media only screen and ${device.huge}`,
  tabletOnly: `@media only screen and ${device.small} and (max-width: ${size.large})`,
} as const

const THEME_COLORS = Object.freeze([
  'white',
  'black',
  'blue',
  'green',
  'greyLightest',
  'greyLight',
  'grey',
  'greyDarker',
  'greyDarkest',
  'disabled',
  'error',
  'offer',
  'primary',
  'backgroundPrimary',
  'backgroundOverlay',
  'backgroundSecondary',
  'backgroundTertiary',
  'text',
  'label',
  'info',
  'onPrimary',
  'onSurface',
  'modalItemBg',
  'progressBg',
] as const)

export type ThemeColor = typeof THEME_COLORS[number]

type Colors = Record<ThemeColor, string>

interface DifTheme {
  breakpoints: {
    size: Record<string, string>
    device: Record<string, string>
    media: Record<string, string>
  }
  colors: Colors
  fonts: {
    heading: string
    paragraph: string
  }
  heights: {
    footerHeight: string
    headerHeight: string
  }
}

declare module 'styled-components' {
  export interface DefaultTheme extends DifTheme {}
}

const generateColorRules = (): Colors => {
  return THEME_COLORS.reduce(
    (accumulator, color) => ({
      ...accumulator,
      [color]: `var(--color-${color}, crimson)`,
    }),
    {} as Colors
  )
}

export const getFontsBasedOnStore = (
  storeName: BrandName = 'LC'
): JSX.Element => {
  switch (storeName) {
    case 'LC':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'PV':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'TO':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'FE':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'VT':
      return (
        <Helmet>
          <link rel="stylesheet" href="https://use.typekit.net/tzs3jig.css" />
        </Helmet>
      )
    case 'OPSM':
      return (
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin=""
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet"
          />
        </Helmet>
      )
  }
}

export const stylesBasedOnThemes = ({
  storeName,
  styles,
}: {
  storeName: BrandName
  styles: Record<BrandName, FlattenInterpolation<ThemeProps<DefaultTheme>>>
}) => styles[storeName] || styles.LC

export const difTheme: DefaultTheme = {
  breakpoints: {
    size,
    device,
    media,
  },
  colors: generateColorRules(),
  fonts: {
    heading: 'normal normal 500 25px/normal sofia-pro',
    paragraph: 'normal normal 200 16px/normal sofia-pro',
  },
  heights: {
    headerHeight: '54px',
    footerHeight: '64px',
  },
}
