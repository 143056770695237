import { useEffect } from 'react'

const usePageClassname = (className: string) => {
  return useEffect(() => {
    document.body.classList.add(className)

    return () => {
      document.body.classList.remove(className)
    }
  }, [className])
}

export default usePageClassname
