import styled, { css } from 'styled-components'

import { Icon } from '@components/Icon'

import ArrowIcon from '../../../assets/icons/arrow.svg'
import { stylesBasedOnThemes, StoreNameProps } from '@root/theme'

const OPSMStyledWrapper = css<{ additionalTop?: number }>`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
  border: 1px solid ${({ theme }) => theme.colors.backgroundTertiary};
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.breakpoints.media.large} {
    position: sticky;
    top: ${(props) => (props.additionalTop ?? 0) + 84}px;
    width: 418px;
  }
`

const LCStyledWrapper = css`
  padding: 22px;
  background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
  border-radius: 10px;
  height: 100%;
  width: 100%;

  ${({ theme }) => theme.breakpoints.media.large} {
    position: sticky;
    top: 30px;
    width: 418px;
  }
`

export const StyledWrapper = styled.div<
  StoreNameProps & { additionalTop?: number }
>`
  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCStyledWrapper,
        PV: LCStyledWrapper,
        TO: LCStyledWrapper,
        FE: LCStyledWrapper,
        VT: LCStyledWrapper,
        OPSM: OPSMStyledWrapper,
      },
    })}
`

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;

  & p {
    margin: 0;
  }
`

export const StyledInfoWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.media.tabletOnly} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
      text-align: left;
    }
  }

  ${({ theme }) => theme.breakpoints.media.large} {
    & p {
      margin: 0;
      text-align: left;
    }
  }

  &&& {
    margin-bottom: 0px;
  }

  & p {
    &:first-of-type {
      font-weight: 200;
      max-width: 100px;
    }

    &:last-of-type {
      font-weight: bold;
    }
  }
`

export const StyledOpener = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: auto;
`

export const StyledArrowIcon = styled(Icon).attrs({
  src: ArrowIcon,
  className: 'intake__icon--arrow intake__appointmentrecap-icon',
})<{
  expanded: boolean
}>`
  transition: transform 0.25s ease-in-out;
  transform: ${({ expanded }) =>
    expanded ? 'rotate(0deg)' : 'rotate(180deg)'};
  margin: 0;
  padding: 0;
`

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    width: 100%;
    ${({ theme }) => css`
      ${theme.breakpoints.media.tabletOnly} {
        width: calc((100% - 2rem) / 2);
      }
    `}
  }
`

export const StyledColumnWrapper = styled.div`
  display: flex;
`

export const StyledFragment = styled.div``
