import { FieldProps } from '@rjsf/core'
import { InputGroup } from '@components/InputGroup'
import styled from 'styled-components'

const StyledDescription = styled(InputGroup.HelperText)`
  width: 70%;
  font-size: 14px;

  a {
    color: ${({ theme }) => theme.colors.onSurface};
  }
`

const DescriptionField = (props: React.PropsWithChildren<FieldProps>) => {
  if (!props.description) {
    return null
  }

  return (
    <StyledDescription
      dangerouslySetInnerHTML={{ __html: props.description }}
    />
  )
}

export default DescriptionField
