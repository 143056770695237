/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Document } from '../models/Document'
import type { DocumentVisibilities } from '../models/DocumentVisibilities'
import { request as __request } from '../core/request'
import { DocumentRecap } from '../models/DocumentRecap'

export class DocumentService {
  /**
   * Get a document to sign.
   * Get a document to sign for a Privacy or Health information, based on brand/store number.
   * @returns Document OK
   * @throws ApiError
   */
  public static async getDocuments({
    docType,
    language,
    formId,
    ocpApimSubscriptionKey,
    clinicId,
    clinicType,
    clinicName,
    brand,
    country,
    acceptedLanguage,
  }: {
    /** The document type (HEALTH or PRIVACY) **/
    docType: string
    /** The language code of requested document (ISO language code uppercase, ex. EN, ES...). **/
    language: string
    /** Form ID related to the document to retrieve. **/
    formId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Clinic name related to the document to retrieve.' **/
    clinicId: string
    /** Clinic type related to the document to retrieve.' **/
    clinicType: string
    /** Clinic name related to the document to retrieve.' **/
    clinicName: string
    /** Brand code of the store.' **/
    brand: string
    /** Country of the store.' **/
    country: string
    /** Language in use */
    acceptedLanguage?: `${string}-${string}`
  }): Promise<Document[]> {
    const result = await __request({
      method: 'GET',
      path: `/document/${docType}`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
        caller: 'DIF',
      },
      query: {
        language: language,
        formId: formId,
        clinicId: clinicId,
        clinicType: clinicType,
        clinicName: clinicName,
        brand: brand,
        country: country,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get documents type availables
   * Get a list of documents type available with visibilities for each clinic type
   * @returns DocumentVisibilities OK
   * @throws ApiError
   */
  public static async getDocumentVisibility({
    clinicId,
    clinicType,
    clinicName,
    brand,
    country,
    ocpApimSubscriptionKey,
    acceptedLanguage,
  }: {
    /** Clinic name related to the document to retrieve.' **/
    clinicId: string
    /** Clinic type related to the document to retrieve.' **/
    clinicType: string
    /** Clinic name related to the document to retrieve.' **/
    clinicName: string
    /** Brand code of the store.' **/
    brand: string
    /** Country of the store.' **/
    country: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
    /** Language in use */
    acceptedLanguage: `${string}-${string}`
  }): Promise<DocumentVisibilities> {
    const result = await __request({
      method: 'GET',
      path: `/document/visibility`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
        'Accept-Language': acceptedLanguage,
      },
      query: {
        clinicId: clinicId,
        clinicType: clinicType,
        clinicName: clinicName,
        brand: brand,
        country: country,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }

  /**
   * Get documents recap
   * Get a list of documents with signed status by the user
   * @returns DocumentRecap OK
   * @throws ApiError
   */
  public static async getDocumentRecap({
    formId,
    ocpApimSubscriptionKey,
  }: {
    /** Form ID related to the document to retrieve. **/
    formId: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<DocumentRecap[]> {
    const result = await __request({
      method: 'GET',
      path: `/document/documentRecap`,
      headers: {
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      query: {
        formId: formId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }
}
