import { createContext, FC, useContext } from 'react'

// #region ::: PARTIALS

// CONTEXT
export type ContextDynamicAnzFormType = {
  goToStep: (step: number) => void
}

export const DynamicAnzFormContext = createContext(
  {} as ContextDynamicAnzFormType
)

export const useDynamicAnzForm = () => {
  return useContext(DynamicAnzFormContext)
}

// #endregion

export const DynamicAnzFormProvider: FC<ContextDynamicAnzFormType> = ({
  children,
  ...contextData
}) => {
  return (
    <DynamicAnzFormContext.Provider value={contextData}>
      {children}
    </DynamicAnzFormContext.Provider>
  )
}
