import React from 'react'
import { Redirect } from 'react-router'

import OTPModal from '@components/OTPModal'
import { Layout } from '@components/Layout'
import { anzIntro } from '@root/utils/routes'
import useIsAnzAuthenticated from '@hooks/useIsAnzAuthenticated'

const OTPPage: React.FC = () => {
  const { isAuthenticated } = useIsAnzAuthenticated()

  if (isAuthenticated) {
    return <Redirect to={anzIntro()} />
  }

  return (
    <Layout>
      <OTPModal />
    </Layout>
  )
}

export default OTPPage
