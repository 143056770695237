import { useConfig } from '@root/Context'

const mapWebsiteStoreLink = {
  AU: 'https://www.opsm.com.au/find-store',
  NZ: 'https://www.opsm.co.nz/find-store',
  default: 'https://www.opsm.com.au/find-store',
}

const useStoreLink = () => {
  const { brandWebsite, country } = useConfig()

  return brandWebsite
    ? `${brandWebsite}find-store`
    : country === 'AU' || country === 'NZ'
    ? mapWebsiteStoreLink[country ?? 'AU']
    : mapWebsiteStoreLink.default
}

export default useStoreLink
