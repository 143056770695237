import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCommonConfigs } from '../../../hooks/useCommonConfigs'
import { twoColumnsStyle } from '../../../components/Layout/TwoColumns'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { ModalProps, Modal } from '../../../components/Modal'

type checkedRelativesType = {
  [key: string]: boolean
}
export interface AnzRelativesModalProps extends ModalProps {
  name: string
  baseName: string
  onConfirm: () => void
  onCancel: () => void
  relativesList?: string[]
  setCheckedValuesInWidget: (value: any) => void
  checkedValuesInModal: checkedRelativesType
}

const StyledModalContent = styled(Modal.Content)`
  text-align: left;

  ${twoColumnsStyle}
`

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AnzRelativesModal: React.FC<AnzRelativesModalProps> = ({
  name,
  baseName,
  onConfirm,
  onCancel,
  isOpen,
  style,
  relativesList,
  setCheckedValuesInWidget,
  checkedValuesInModal,
  ...props
}) => {
  const { t } = useTranslation()
  const [checkedRelatives, setCheckedRelatives] =
    useState<checkedRelativesType>(checkedValuesInModal || {})

  const commonConfigs = useCommonConfigs()

  const handleCheckUncheck = (relative: string) => {
    setCheckedRelatives((oldState) => {
      let newState = JSON.parse(JSON.stringify(oldState))
      oldState[`${relative}`]
        ? delete newState[`${relative}`]
        : (newState[`${relative}`] = true)
      return newState
    })
  }

  const handleConfirm = () => {
    setCheckedValuesInWidget((oldCheckedWidgetState: any) => {
      let newCheckedWidgetState = JSON.parse(
        JSON.stringify(oldCheckedWidgetState)
      )

      newCheckedWidgetState[`${name}`]
        ? (newCheckedWidgetState[`${name}`].relatives = checkedRelatives)
        : (newCheckedWidgetState[`${name}`] = { relatives: checkedRelatives })

      if (Object.keys(checkedRelatives).length === 0) {
        delete newCheckedWidgetState[`${name}`].relatives
      }

      return newCheckedWidgetState
    })
    onConfirm()
  }

  const handleCancel = () => {
    onCancel()
    setCheckedRelatives(checkedValuesInModal)
    setCheckedValuesInWidget((oldState: Object) => {
      let newCheckedWidgetState = JSON.parse(JSON.stringify(oldState || {}))
      if (Object.keys(checkedRelatives).length === 0) {
        delete newCheckedWidgetState[`${name}`].relatives
      }
      return newCheckedWidgetState
    })
  }

  if (commonConfigs) {
    return (
      <Modal
        {...props}
        id="relatives-modal"
        isOpen
        bodyOpenClassName="intake__modal-body-hidden"
        style={{
          overlay: { display: isOpen ? 'block' : 'none' },
          content: { display: isOpen ? 'block' : 'none' },
        }}
      >
        <StyledModalContent>
          {(relativesList || []).map((relative) => (
            <Checkbox
              name="Relatives"
              label={relative}
              key={relative}
              onClick={() => handleCheckUncheck(relative)}
              checked={
                checkedRelatives && checkedRelatives[`${relative}`]
                  ? true
                  : false
              }
            />
          ))}
        </StyledModalContent>

        <StyledModalActions>
          <Button $outlined onClick={handleCancel}>
            {t('core.close')}
          </Button>

          <Button onClick={handleConfirm}>{t('core.confirm')}</Button>
        </StyledModalActions>
      </Modal>
    )
  }

  return null
}
