import { createContext, FC, useCallback, useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { useToggle } from 'react-use'
import { Form, FormId, FormService } from '../../api'
import { ErrorModal } from '../../components/ErrorModal/ErrorModal'
import { useConfig } from '../../Context'

// #region ::: PARTIALS
const neverEndingFormPromise = new Promise<Form>(() => {})
// CONTEXT
export const TabApiFormContext = createContext({} as ContextFormValueType)
// TYPES
const useAsyncWithForm = () => useAsync<Form>({})
export type ContextFormValueType = ReturnType<typeof useAsyncWithForm>
// #endregion

export const ProviderForm: FC = ({ children }) => {
  const {
    brand,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
    appointmentId,
  } = useConfig()
  const [appointmentFormId, setAppointmentFormId] = useState<string>()

  const formId = appointmentFormId

  const getForm = useCallback(
    () =>
      formId
        ? FormService.getFormById({
            brand,
            id: formId,
            caller,
            ocpApimSubscriptionKey,
          })
        : neverEndingFormPromise,
    [brand, caller, formId, ocpApimSubscriptionKey]
  )

  const putForm = useCallback(
    ([requestBody, sendEmail = false]: [Form, boolean]) => {
      return formId
        ? FormService[
            sendEmail ? 'upsertFormByIdAndSendEmail' : 'upsertFormById'
          ]<Form>({
            brand,
            id: formId,
            caller,
            ocpApimSubscriptionKey,
            requestBody,
          })
        : neverEndingFormPromise
    },
    [brand, caller, formId, ocpApimSubscriptionKey]
  )

  const getFormIdFromAppointment = useCallback(
    () =>
      FormService.getFormByApptId({
        brand,
        appointmentId,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, appointmentId, ocpApimSubscriptionKey]
  )

  const TabApiAppointmentFormId = useAsync<FormId>({
    promiseFn: getFormIdFromAppointment,
    onResolve: ({ id }) => setAppointmentFormId(id),
  })

  const tabApiFormContextValue = useAsync<Form>({
    promiseFn: getForm,
    onResolve: ({ id }) => setAppointmentFormId(id),
    deferFn: putForm as (...args: any[]) => Promise<Form>,
  })

  const [isErrorModalVisible, toggleErrorModalVisibility] = useToggle(false)
  const error = TabApiAppointmentFormId.error

  useEffect(() => {
    if (error) {
      toggleErrorModalVisibility(true)
      console.error(error)
    } else {
      toggleErrorModalVisibility(false)
    }
  }, [error, toggleErrorModalVisibility])

  return (
    <TabApiFormContext.Provider value={tabApiFormContextValue}>
      {children}
      <ErrorModal
        isOpen={isErrorModalVisible}
        onRequestClose={toggleErrorModalVisibility}
      />
    </TabApiFormContext.Provider>
  )
}
