import { Typography } from '@components/Typography'
import { ObjectFieldTemplateProps } from '@rjsf/core'
import styled from 'styled-components'

const StyledContainer = styled.div``

const StyledFieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 26px;

  & > * {
    flex: 1 0 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;

    label {
      margin-right: 19px;
    }
  }
`

const CustomGridLayoutTemplate = (props: ObjectFieldTemplateProps) => {
  return (
    <StyledContainer id={props.idSchema.$id}>
      {props.uiSchema['ui:grid'] &&
        props.uiSchema['ui:grid'].map((row: any[]) => (
          <StyledFieldsWrapper>
            {row.map((fieldName) => {
              const field = props.properties.find(
                ({ name }) => name === fieldName
              )

              if (!field && fieldName === 'title') {
                return (
                  <Typography
                    data-element-id="form-title"
                    $align="left"
                    $variant="h1"
                  >
                    {props.title}
                  </Typography>
                )
              }

              if (!field && fieldName === 'description') {
                return (
                  <Typography
                    $align="left"
                    dangerouslySetInnerHTML={{ __html: props.description }}
                  />
                )
              }

              return field && field.content
            })}
          </StyledFieldsWrapper>
        ))}
    </StyledContainer>
  )
}

export default CustomGridLayoutTemplate
