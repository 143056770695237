import { InsuranceCardService } from '@root/api/services/InsuranceCardService'
export const downloadInsuranceCard = async (
  appointmentId: string,
  documentFileId: string,
  originalName: string,
  ocpApimSubscriptionKey: string
) => {
  try {
    const call = InsuranceCardService.getInsuranceCardBis({
      appointmentId,
      documentFileId,
      originalName,
      ocpApimSubscriptionKey,
    })
    const response = await call
    if (response) {
      const blob = await response.blob()
      const blobURL = URL.createObjectURL(blob)

      const downloadLink = document.body.appendChild(
        Object.assign(document.createElement('a'), {
          href: blobURL,
          download: originalName,
        })
      )

      downloadLink.click()
      downloadLink.remove()
      URL.revokeObjectURL(blobURL)
    }
  } catch (error) {
    console.error('Error downloading insurance card: ', error)
  }
}
