import { useConfig } from '@root/Context'
import { useEffect } from 'react'
import { useSessionJwt } from '@hooks/useSessionJwt'

const retrieveHrefWithoutJwt = () => {
  const urlObj = new URL(window.location.href)

  urlObj.searchParams.delete('jwt')

  return urlObj.href
}

export const useJwt = () => {
  const urlJwt = new URLSearchParams(window.location.search).get('jwt') || ''
  const { caller } = useConfig()

  const { sessionJwt, setSessionJwt } = useSessionJwt()

  if (Boolean(urlJwt) || Boolean(caller)) {
    setSessionJwt(urlJwt || caller)
  }

  const removeJwt = setSessionJwt.bind(null, '')

  useEffect(() => {
    urlJwt &&
      window.history.replaceState({}, document.title, retrieveHrefWithoutJwt())
  }, [urlJwt])

  return {
    jwt: sessionJwt,
    removeJwt,
  }
}

export default useJwt
