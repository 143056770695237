import { WidgetProps } from '@rjsf/core'
import { useTranslation } from 'react-i18next'

import { InputGroup } from '@components/InputGroup'
import { Typography } from '@components/Typography'
import { Checkbox, deselectValue, selectValue } from '@components/Checkbox'
import styled from 'styled-components'
import { useConfig } from '@root/Context'
import { isCaOrUs } from '@root/utils/utils'

const CheckboxWrapper = styled.div<{ twoCol: boolean }>`
  width: 42%;
`

function isNone(stringToCheck: string): boolean {
  return stringToCheck.toLowerCase() === 'none'
}

const CheckboxesWidget = ({
  label,
  onChange,
  options,
  required,
  disabled,
  value: selectedValues,
  rawErrors,
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()
  const { country } = useConfig()

  const enumOptions = options.enumOptions ? (options.enumOptions as any[]) : []

  const noneValue = enumOptions.find(({ value }) => isNone(value))?.value

  return (
    <InputGroup>
      <InputGroup.Label required={required}>
        {label + ' '}
        <Typography $variant="span" $weight={200}>
          ({t('core.selectAllThatApply')})
        </Typography>
      </InputGroup.Label>

      <InputGroup.Inputs /* $twoColumns ={_twoColumns} */>
        {enumOptions.map(({ value }, index) => {
          const isObject = typeof value === 'object'
          const parsedValue = isObject ? value.label : value

          const isChecked = !!(selectedValues as string[]).find(
            (v) => v === parsedValue
          )

          /**
           * When the checkbox is clicked, if it's already selected,
           * it deselect, else select.
           */
          const handleCheckboxesChange = (
            event: React.ChangeEvent<HTMLInputElement>
          ) => {
            // If you click on'NONE', deselect others and leave only 'NONE'
            if (value === noneValue && event.target.checked) {
              onChange([value])
              return
            }

            let nextValues = selectedValues
            const all = enumOptions.map(({ value }) =>
              isObject ? value.label : value
            )

            // If 'NONE' is selected and you click on another value, 'NONE' must be deselected
            if (selectedValues.includes(noneValue)) {
              nextValues = deselectValue(noneValue, nextValues)
            }

            if (event.target.checked) {
              nextValues = selectValue(parsedValue, nextValues, all)
            } else {
              nextValues = deselectValue(parsedValue, nextValues)
            }

            onChange(nextValues)
          }

          return (
            <CheckboxWrapper twoCol={isCaOrUs(country)}>
              <Checkbox
                disabled={disabled}
                key={`${parsedValue}-${index}`}
                checked={isChecked}
                name={parsedValue}
                value={parsedValue}
                label={parsedValue}
                tooltip={
                  parsedValue === 'Floaters'
                    ? t(`experiencingVision.floatersTooltip`, {
                        defaultValue: '',
                      })
                    : ''
                }
                onChange={handleCheckboxesChange}
              />
            </CheckboxWrapper>
          )
        })}

        <InputGroup.Errors
          error={!!rawErrors?.length}
          errorMessage={rawErrors?.join('\n')}
        />
      </InputGroup.Inputs>
    </InputGroup>
  )
}

export default CheckboxesWidget
