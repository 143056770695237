import React from 'react'
import { ProviderConfigs } from './ProviderConfigs'
import { ProviderAppointment } from './ProviderAppointment'
import { ProviderAnzForm } from './ProviderAnzForm'
import { ProviderDynamicForm } from './ProviderDynamicForm'

export const TabApiProvider: React.FC = ({ children }) => (
  <ProviderConfigs>
    <FormProviderComponent>{children}</FormProviderComponent>
  </ProviderConfigs>
)

const FormProviderComponent: React.FC = ({ children }) => {
  return (
    <ProviderAnzForm>
      <ProviderDynamicForm>
        <ProviderAppointment>{children}</ProviderAppointment>
      </ProviderDynamicForm>
    </ProviderAnzForm>
  )
}
