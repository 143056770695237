import { useCallback } from 'react'
import { Config } from '../api'
import { useTabApiConfig } from '../services/TabApiProvider/ProviderConfigs'

export const getInsuranceGroupFromConfig = (
  configs: Config,
  provider: string
) => {
  const providerGroups =
    configs.commonConfigs &&
    configs.commonConfigs.find((c) => c.propertyCode === 'providerGroup')
      ?.providerGroups
  const group = providerGroups?.find((p) => p.provider?.includes(provider))
  return group?.group
}

export const useInsuranceFieldVisible = () => {
  const { data: configs } = useTabApiConfig()

  const isFieldVisible = useCallback(
    (fieldName: string, provider: string | undefined) => {
      if (!configs || !provider) return false
      const group = getInsuranceGroupFromConfig(configs, provider)
      if (!group) return false
      if (!configs.insuranceConfigs) return false
      const fieldConfig = configs.insuranceConfigs.find(
        (i) => i.propertyCode === fieldName
      )
      return !!fieldConfig?.isVisibleOnInsuranceProvider?.includes(group)
    },
    [configs]
  )

  return isFieldVisible
}
