import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { WidgetProps } from '@rjsf/core'
import { useTranslation } from 'react-i18next'

import { InputGroup } from '@components/InputGroup'
import { Typography } from '@components/Typography'
import { AnzHealthCondition as HealthCondition } from '@root/anz/components/AnzHealthCondition'

import { JSONSchema7 } from 'json-schema'

const StyledTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
`

const StyledRow = styled.div`
  display: flex;
  width: 100%;

  @media screen and (min-width: 675px) {
    width: calc((100% - 2rem) / 2);
  }
`

const StyledRow2 = styled.div`
  display: none;

  @media screen and (min-width: 675px) {
    display: flex;
    width: calc((100% - 2rem) / 2);
  }
`

const StyledCell = styled.div`
  display: inline-block;
  padding: 10px;
  text-align: left;
`

const StyledCell1 = styled(StyledCell)`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
`

const StyledCell2 = styled(StyledCell)`
  width: 4em;
  flex-shrink: 0;
`

const StyledColumnTitle = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 13px;
`

type checkedValuesInWidgetType = {
  [key: string]: {
    self?: boolean
    relatives?: {
      [relative: string]: boolean
    }
  }
}
const SelfRelativesWidget = ({
  label,
  options,
  onChange,
  value: selectedValues,
  schema,
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()
  const [checkedValuesInWidget, setCheckedValuesInWidget] =
    useState<checkedValuesInWidgetType>({})

  const relativesList: string[] = schema.properties
    ? ((schema.properties.relatives as JSONSchema7).enum as string[]) || []
    : []

  useEffect(
    () => onChange(checkedValuesInWidget),
    [onChange, checkedValuesInWidget]
  )

  const healthCondition = useMemo(
    () =>
      (options.enumOptions as any[]).map(({ label, value }, index) => (
        <HealthCondition
          key={`condition-${value}-${index}`}
          index={index}
          name={label}
          setCheckedValuesInWidget={setCheckedValuesInWidget}
          checkedValuesInWidget={
            checkedValuesInWidget[`${label}`]
              ? checkedValuesInWidget[`${label}`].relatives
              : {}
          }
          enumOptions={options.enumOptions as any[]}
          selectedValues={selectedValues}
          relativesList={relativesList}
        />
      )),
    [checkedValuesInWidget]
  )
  return (
    <InputGroup>
      <InputGroup.Label>
        {`${label} `}
        <Typography $variant="span" $weight={200}>
          ({t('core.selectAllThatApply')})
        </Typography>
      </InputGroup.Label>

      <InputGroup.Inputs>
        <StyledTable>
          <StyledRow>
            <StyledCell1 />
            <StyledCell2>
              <StyledColumnTitle $noMargin $transform="uppercase">
                {t('core.self')}
              </StyledColumnTitle>
            </StyledCell2>
            <StyledCell2>
              <StyledColumnTitle $noMargin $transform="uppercase">
                {t('core.relatives')}
              </StyledColumnTitle>
            </StyledCell2>
          </StyledRow>

          <StyledRow2>
            <StyledCell1 />
            <StyledCell2>
              <StyledColumnTitle $noMargin $transform="uppercase">
                {t('core.self')}
              </StyledColumnTitle>
            </StyledCell2>
            <StyledCell2>
              <StyledColumnTitle $noMargin $transform="uppercase">
                {t('core.relatives')}
              </StyledColumnTitle>
            </StyledCell2>
          </StyledRow2>

          {healthCondition}
        </StyledTable>
      </InputGroup.Inputs>
    </InputGroup>
  )
}

export default SelfRelativesWidget
