import {
  createGlobalStyle,
  css,
  DefaultTheme,
  Interpolation,
  ThemeProps,
} from 'styled-components'
import { BrandName } from '@root/theme'

const globalStyles = css`
  @media (prefers-color-scheme: dark) {
    input,
    select {
      color-scheme: dark;
    }
  }

  #legal-sign-modal {
    background-color: ${({ theme: { colors } }) => colors.backgroundPrimary};

    .legal-sign-modal__document-wrapper {
      background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};

      .intake__icon-exclamation {
        filter: unset;
        width: 20px;
        height: 20px;
      }

      button {
        @media (prefers-color-scheme: dark) {
          filter: invert(1);
        }
      }
    }
  }

  [data-element-id='confirmation-layout'] {
    [class*='intake__icon'] {
      filter: unset;
    }
  }

  div#privacy-document {
    border-radius: unset;
  }

  .legal-sign-modal__document-wrapper {
    border-radius: unset;
  }
    
  :has(button[data-element-id*="X_OEE_Review_Continue-Later"]) div[role="main"] [data-element-id="appointment-recap-wrapper"] {
    inset-block-start: 8rem;
  }
`

const commonStyles = css`
  :root {
    --color-white: white;
    --color-black: black;
    --color-blue: #3d87cb;
    --color-green: #3f8350;
    --color-greyLightest: #f2f2f2;
    --color-greyLight: #e5e5e5;
    --color-grey: #cccccc;
    --color-greyDarker: #999999;
    --color-greyDarkest: #333333;

    --color-disabled: #666666;
    --color-error: #d0021b;
    --color-offer: #b63535;

    --color-backgroundPrimary: #f2f2f2;
    --color-backgroundSecondary: #ffffff;
    --color-backgroundTertiary: #ffffff;
    --color-backgroundOverlay: rgba(51, 51, 51, 0.7);
    --color-text: #333333;
    --color-label: transparent;
    --color-info: #80afdb;
    --color-onPrimary: #ffffff;
    --color-onSurface: #333333;

    --color-modalItemBg: #f1f1f1;

    --color-progressBg: #b58b34;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --color-white: white;
      --color-black: black;
      --color-blue: #3d87cb;
      --color-green: #3f8350;
      --color-greyLightest: #f2f2f2;
      --color-greyLight: #e5e5e5;
      --color-grey: #cccccc;
      --color-greyDarker: #999999;
      --color-greyDarkest: #333333;

      --color-disabled: #666666;
      --color-error: #e94156;
      --color-offer: #b63535;

      --color-backgroundPrimary: #1a1d1e;
      --color-backgroundSecondary: #ffffff;
      --color-backgroundTertiary: #2f3537;
      --color-text: #333333;
      --color-label: transparent;
      --color-info: #80afdb;
      --color-onPrimary: #ffffff;
      --color-onSurface: #f0f0f0;

      --color-modalItemBg: #f1f1f1;

      --color-progressBg: #666666;
    }
  }
`

const lcStyle = css`
  :root {
    --color-primary: #326fa8;
    @media (prefers-color-scheme: dark) {
      --color-primary: #589bd5;
    }
  }
`

const opsmStyle = css`
  :root {
    --color-primary: #2d2a26;
    @media (prefers-color-scheme: dark) {
      --color-primary: #589bd5;
    }
  }
`

const buttonStyle = css`
  .intake__button,
  .intake__button--outlined {
    text-transform: uppercase;
  }

  .intake__button--outlined {
    border-color: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.primary};
    background-color: ${({ theme: { colors } }) => colors.white};
    @media (prefers-color-scheme: dark) {
      background-color: transparent;
    }
  }
`

const checkboxStyle = css`
  .intake__checkbox-wrapper input[type='checkbox'] {
    &:checked {
      & + .intake__typography {
        &::before {
          background-color: ${({ theme: { colors } }) => colors.primary};
          box-shadow: inset 0 0 0 2px
            ${({ theme: { colors } }) => colors.backgroundTertiary};
          transition: background-color 0.25s linear;
        }

        &::after {
          display: none;
        }
      }
    }
  }
`

const pvStyle = css`
  :root {
    --color-primary: #5e9732;
    --color-info: #0066ff;
    --color-progressBg: #cedfc1;
    @media (prefers-color-scheme: dark) {
      --color-primary: #7aac48;
      --color-progressBg: #666666;
      --color-info: #5e8bff;
    }
  }

  body {
    ${buttonStyle};
    ${checkboxStyle};

    .intake__button,
    .intake__button--outlined {
      border-radius: unset;
    }

    [data-element-id='step-layout-title'],
    [data-element-id='intro-title'],
    [data-element-id='form-title'] {
      font-style: italic;
      color: ${({ theme: { colors } }) => colors.primary};
    }

    [data-element-id='step-layout-wrapper'] {
      border-radius: unset;

      section {
        border-radius: unset;
      }
    }

    [data-element-id='appointment-recap-wrapper'] {
      border-radius: unset;
    }
  }
`

const toStyle = css`
  :root {
    --color-primary: #cc0000;
    --color-progressBg: #efb2b2;
    --color-info: #80afdb;
    @media (prefers-color-scheme: dark) {
      --color-primary: #cf685f;
      --color-progressBg: #666666;
      --color-info: #80afdb;
    }
  }

  body {
    ${buttonStyle};
    ${checkboxStyle};
  }
`

const BRAND_NAME_STYLE_MAP: Record<
  BrandName,
  Interpolation<ThemeProps<DefaultTheme>>
> = {
  LC: lcStyle,
  PV: pvStyle,
  TO: toStyle,
  VT: toStyle,
  FE: lcStyle,
  OPSM: opsmStyle,
}

const getBrandStyle = (brand: BrandName) =>
  BRAND_NAME_STYLE_MAP[brand] || lcStyle

export const getGlobalStyles = (brand: BrandName, selector: string) => {
  return createGlobalStyle`
      ${globalStyles}
      ${commonStyles}
      ${getBrandStyle(brand)}
      ${selector} {
          height: 100%;
      }
  `
}
