import React from 'react'
import styled from 'styled-components'

export interface ContentProps extends React.ComponentPropsWithRef<'div'> {}

const StlyedMain = styled.div<ContentProps>`
  margin: auto;
  width: calc(100% - 30px);
  max-width: 1022px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${({
    theme: {
      heights: { footerHeight },
    },
  }) => `calc(62px + ${footerHeight})`};

  & > * {
    margin-top: 30px;
  }

  ${({ theme }) => theme.breakpoints.media.large} {
    flex-direction: row;
    padding-bottom: ${({
      theme: {
        heights: { footerHeight },
      },
    }) => `calc(62px + ${footerHeight})`};

    & > * + * {
      margin-left: 30px;
    }
  }
`

export const Content: React.FC<ContentProps> = ({ children, ...props }) => (
  <StlyedMain role="main" {...props}>
    {children}
  </StlyedMain>
)
