import React from 'react'
import styled, { css } from 'styled-components'
import { Tooltip } from '@components/Tooltip'
import { Typography } from '@components/Typography'

export type InsuranceTooltipHelperProps = {
  label: string
}

const InsuranceTooltip = styled(Tooltip)`
  display: inline-block;
  ${({ theme }) => css`
    ${theme.breakpoints.media.medium} {
      margin-right: auto;
      min-width: 280px;
    }
  `}
`

export const InsuranceTooltipHelper: React.FC<InsuranceTooltipHelperProps> = ({
  label,
  children,
}) => {
  return (
    <InsuranceTooltip
      position="top"
      label={
        <Typography
          $noMargin
          $align="initial"
          $color="primary"
          $variant={'span'}
        >
          {label}
        </Typography>
      }
    >
      {children}
    </InsuranceTooltip>
  )
}
