import { createGlobalStyle, css, DefaultTheme } from 'styled-components'
import { BrandName, StoreNameProps } from '@root/theme'

const OPSM = (theme: DefaultTheme) => css`
  .intake__modal-body-open {
    overflow: hidden;
  }

  .intake__modal-overlay {
    z-index: 2000;
  }

  .intake__modal {
    &-overlay {
      position: fixed;
      inset: 0;
      background-color: ${theme.colors.backgroundOverlay};
    }

    &-content {
      position: absolute;
      max-height: 92vh;
      max-width: 90vw;
      width: 605px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: ${({ theme: { colors } }) => colors.backgroundPrimary};
      overflow: auto;
      outline: none;
      border: 1px solid ${theme.colors.onSurface};

      padding: 19px 30px;
    }
  }
`

const LC = (theme: DefaultTheme) => css`
  position: relative;

  .intake__modal-body-open {
    overflow: hidden;
  }

  .intake__modal-overlay {
    z-index: 2000;
  }

  .intake__modal {
    &-overlay {
      position: fixed;
      inset: 0;
      background-color: ${theme.colors.backgroundOverlay};
    }

    &-content {
      position: absolute;
      max-height: 92vh;
      max-width: 90vw;
      width: 500px;
      inset-block: 50% auto;
      inset-inline: 50% auto;
      transform: translate(-50%, -50%);
      background-color: ${({ theme: { colors } }) => colors.backgroundTertiary};
      border-radius: 10px;
      padding: 10px;
      outline: none;

      ${theme.breakpoints.media.large} {
        width: fit-content;
        height: fit-content;
        padding: 40px;
      }
    }
  }
`

function modalStylesBasedOnThemes({
  storeName,
  theme,
}: {
  storeName: BrandName
  theme: DefaultTheme
}) {
  switch (storeName) {
    case 'LC':
      return LC(theme)
    case 'PV':
      return LC(theme)
    case 'TO':
      return LC(theme)
    case 'FE':
      return LC(theme)
    case 'VT':
      return LC(theme)
    case 'OPSM':
      return OPSM(theme)
  }
}

const ModalStyles = createGlobalStyle<StoreNameProps>`
${modalStylesBasedOnThemes}
`

export default ModalStyles
