import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react'
import { useAsync } from 'react-async'
import { useToggle } from 'react-use'
import {
  Form,
  FormService,
  Config as ConfigServiceType,
  ConfigService,
  Appointment,
  AppointmentService,
  FormId,
  Document,
  DocumentService,
  DocumentType,
  DocumentVisibilities,
} from '../api'
import { useConfig } from '../Context'
import { ErrorModal } from '../components/ErrorModal'
import { useTabApiAppointment as useTabApiAppointmentProvider } from '../services/TabApiProvider/ProviderAppointment'
import { InsuranceCardService } from '@root/api/services/InsuranceCardService'
import { InsuranceCard } from '@root/api/models/InsuranceCard'
import { DocumentRecap } from '@root/api/models/DocumentRecap'
import { useGetCurrentLanguage } from '@hooks/useLanguage'

const neverEndingFormPromise = new Promise<Form>(() => {})
const neverEndingDocumentPromise = new Promise<Document[]>(() => {})
const neverEndingDocumentsRecapPromise = new Promise<DocumentRecap[]>(() => {})
const neverEndingAppointmentPromise = new Promise<DocumentVisibilities>(
  () => {}
)
const neverEndingInsuranceCardPromise = new Promise<InsuranceCard[]>(() => {})

const useAsyncWithForm = () => useAsync<Form>({})
const useAsyncWithFormConfigs = () => useAsync<ConfigServiceType>({})
const useAsyncWithAppointment = () => useAsync<Appointment>({})

type ContextFormValueType = ReturnType<typeof useAsyncWithForm>
type ContextFormConfigsValueType = ReturnType<typeof useAsyncWithFormConfigs>
type ContextAppointmentValueType = ReturnType<typeof useAsyncWithAppointment>

export const TabApiFormContext = createContext({} as ContextFormValueType)

export const TabApiFormConfigsContext = createContext(
  {} as ContextFormConfigsValueType
)

export const TabApiAppointmentContext = createContext(
  {} as ContextAppointmentValueType
)

export const useTabApiForm = () => useContext(TabApiFormContext)

export const useTabApiConfig = () => useContext(TabApiFormConfigsContext)

export const useTabApiAppointment = () => useContext(TabApiAppointmentContext)

export const useLegalDocument = (
  docType: DocumentType,
  formId: string | undefined
) => {
  const {
    lang = 'en',
    subscriptionKey: ocpApimSubscriptionKey,
    country,
    brand,
  } = useConfig()
  const { data: appointmentData } = useTabApiAppointmentProvider()
  const clinicId =
    appointmentData && appointmentData.clinic ? appointmentData.clinic.id : ''
  const clinicType =
    appointmentData && appointmentData.clinic
      ? appointmentData.clinic.clinicType
      : ''
  const clinicName =
    appointmentData && appointmentData.clinic ? appointmentData.clinic.name : ''

  const language = lang.split('-')[0].toUpperCase()

  const getDocuments = useCallback(
    () =>
      formId
        ? DocumentService.getDocuments({
            docType: docType.toUpperCase(),
            language,
            ocpApimSubscriptionKey,
            formId,
            clinicId: clinicId || '',
            clinicType: clinicType || '',
            clinicName: clinicName || '',
            brand: brand,
            country: country || '',
            // @ts-ignore
            acceptedLanguage: country ? `en-${country}` : undefined,
          })
        : neverEndingDocumentPromise,
    [formId, docType, language, ocpApimSubscriptionKey, country]
  )

  return useAsync({
    promiseFn: getDocuments,
  })
}

export const useAvailableDocuments = (
  clinicId: string,
  clinicType: string,
  clinicName: string
) => {
  const {
    brand,
    country,
    subscriptionKey: ocpApimSubscriptionKey,
  } = useConfig()
  const currentLanguage = useGetCurrentLanguage()

  const getAvailableDocuments = useCallback(
    () =>
      clinicId
        ? DocumentService.getDocumentVisibility({
            clinicId: clinicId,
            clinicType: clinicType,
            clinicName: clinicName,
            brand: brand,
            country: country || '',
            ocpApimSubscriptionKey,
            acceptedLanguage: currentLanguage,
          })
        : neverEndingAppointmentPromise,
    [ocpApimSubscriptionKey, clinicId]
  )

  return useAsync({
    promiseFn: getAvailableDocuments,
  })
}

export const useDocumentRecap = (formId: string | undefined) => {
  const { subscriptionKey: ocpApimSubscriptionKey } = useConfig()

  const getAvailableDocuments = useCallback(
    () =>
      formId
        ? DocumentService.getDocumentRecap({
            formId: formId || '',
            ocpApimSubscriptionKey,
          })
        : neverEndingDocumentsRecapPromise,
    [formId, ocpApimSubscriptionKey]
  )

  return useAsync({
    promiseFn: getAvailableDocuments,
  })
}

export const TabApiProvider: React.FC = ({ children }) => {
  const {
    brand,
    appointmentId,
    subscriptionKey: ocpApimSubscriptionKey,
    caller,
  } = useConfig()
  const [appointmentFormId, setAppointmentFormId] = useState<string>()
  const formId = appointmentFormId

  const getFormIdFromAppointment = useCallback(
    () =>
      FormService.getFormByApptId({
        brand,
        appointmentId,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, appointmentId, ocpApimSubscriptionKey]
  )

  const getForm = useCallback(
    () =>
      formId
        ? FormService.getFormById({
            brand,
            id: formId,
            caller,
            ocpApimSubscriptionKey,
          })
        : neverEndingFormPromise,
    [brand, caller, formId, ocpApimSubscriptionKey]
  )

  const getAppointment = useCallback(
    () =>
      AppointmentService.getAppointment({
        appointmentId,
        brand,
        ocpApimSubscriptionKey,
      }),
    [appointmentId, brand, ocpApimSubscriptionKey]
  )

  const getFormConfigs = useCallback(
    () =>
      ConfigService.getConfigs({
        brand,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, ocpApimSubscriptionKey]
  )

  const putForm = useCallback(
    ([requestBody]: [Form]) => {
      return formId
        ? FormService.upsertFormById({
            brand,
            id: formId,
            caller,
            ocpApimSubscriptionKey,
            requestBody,
          })
        : neverEndingFormPromise
    },
    [brand, caller, formId, ocpApimSubscriptionKey]
  )

  useAsync<FormId>({
    promiseFn: getFormIdFromAppointment,
    onResolve: ({ id }) => setAppointmentFormId(id),
  })

  const tabApiFormContextValue = useAsync<Form>({
    promiseFn: getForm,
    deferFn: putForm as (...args: any[]) => Promise<Form>,
  })

  const tabApiFormConfigsContextValue = useAsync<ConfigServiceType>({
    promiseFn: getFormConfigs,
  })

  const originRun = tabApiFormConfigsContextValue.run
  tabApiFormConfigsContextValue.run = (...args) => {
    originRun.call(tabApiFormConfigsContextValue, args)
  }

  const tabApiAppointmentContextValue = useAsync<Appointment>({
    promiseFn: getAppointment,
  })

  const [isErrorModalVisible, toggleErrorModalVisibility] = useToggle(false)
  const error =
    tabApiFormContextValue.error ||
    tabApiFormConfigsContextValue.error ||
    tabApiAppointmentContextValue.error

  useEffect(() => {
    if (error) {
      toggleErrorModalVisibility(true)
      console.error(error)
    } else {
      toggleErrorModalVisibility(false)
    }
  }, [error, toggleErrorModalVisibility])

  return (
    <TabApiFormConfigsContext.Provider value={tabApiFormConfigsContextValue}>
      <TabApiFormContext.Provider value={tabApiFormContextValue}>
        <TabApiAppointmentContext.Provider
          value={tabApiAppointmentContextValue}
        >
          {children}
          <ErrorModal
            isOpen={isErrorModalVisible}
            onRequestClose={toggleErrorModalVisibility}
          />
        </TabApiAppointmentContext.Provider>
      </TabApiFormContext.Provider>
    </TabApiFormConfigsContext.Provider>
  )
}

export const useInsuranceCards = (formId: string | undefined) => {
  const {
    subscriptionKey: ocpApimSubscriptionKey,
    country,
    appointmentId,
  } = useConfig()

  const getInsuranceCardsList = useCallback(
    () =>
      formId
        ? InsuranceCardService.getInsuranceCardsList({
            ocpApimSubscriptionKey,
            formId,
            appointmentId,
            // @ts-ignore
            acceptedLanguage: country ? `en-${country}` : undefined,
          })
        : neverEndingInsuranceCardPromise,
    [formId, ocpApimSubscriptionKey, country]
  )

  return useAsync({
    promiseFn: getInsuranceCardsList,
  })
}
