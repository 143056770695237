import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useConfig } from '@root/Context'

import { Button } from '@components/Button'
import InsuranceCardIcon from '../../../assets/icons/insurance-card.svg'
import CrossCircleIcon from '../../../assets/icons/cross-circle.svg'
import { useInsuranceCards } from '@root/services/tabApi'
import { InsuranceCardService } from '@root/api/services/InsuranceCardService'
import { useAsync } from 'react-async'
import { downloadInsuranceCard } from '@root/utils/insuranceCards'
import { Icon } from '@components/Icon'
import { Typography } from '@components/Typography'
import { FormIdContext } from '@root/anz/components/AnzForm'
import { InsuranceCard } from '@root/api/models/InsuranceCard'
import { WidgetProps } from '@rjsf/core'

export const InsuranceDataContext = createContext({
  insuranceCards: [] as InsuranceCard[],
  setInsuranceCards: () => {},
})

export const BoxWrapper = styled.div`
  width: 100%;
  max-width: 100%;

  ${({ theme }) => theme.breakpoints.media.large} {
    width: 65%;
    max-width: 65%;
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.75rem;
`

export const CardRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
`

export const TypographyWrapper = styled.div<{ isPointer?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => (!!props.isPointer ? 'cursor: pointer;' : '')}

  & p {
    text-align: left;
    overflow: hidden;
    margin-left: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.media.large} {
    & p {
      margin-left: 20px;
    }
  }
`

export const TypographyCardStatus = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.6;
  padding-right: 1rem;
`

export const StyledIcon = styled(Icon).attrs({
  src: InsuranceCardIcon,
  className: 'intake__icon-check',
})`
  width: 25px;
  height: 28px;
  background-size: auto;
  margin: 5px 0px 0px 5px;
`

export const StyledIconDelete = styled(Icon).attrs({
  src: CrossCircleIcon,
  className: 'intake__icon-check',
})`
  width: 20px;
  height: 20px;
  background-size: auto;
  margin: 5px 0px 0px 5px;
`

export interface UploadButtonOnChangeProps {
  fileName: string
  fileContent: string
  fileSize: number
}

interface Props {
  onChange: (props: UploadButtonOnChangeProps) => void
  maxSize?: number
  disabled?: boolean
  setMaxSizeError: (value: boolean) => void
  setGenericError: (value: boolean) => void
  appointmentId: string
  formId: string
  ocpApimSubscriptionKey: string
  insuranceCards: InsuranceCard[]
  setInsuranceCards: (value: InsuranceCard[]) => void
}

const InputContainer = styled.div`
  outline: none;
  position: relative;
  & > input {
    display: none;
    cursor: pointer;
  }

  .Mui-disabled svg {
    stroke: rgba(0, 0, 0, 0.26);
  }
`

const UploadButton: React.FC<Props> = ({
  children,
  onChange,
  maxSize = 10485760,
  disabled,
  setMaxSizeError,
  setGenericError,
  appointmentId,
  formId,
  ocpApimSubscriptionKey,
  insuranceCards,
  setInsuranceCards,
}) => {
  const [changed, setChanged] = useState(false)
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = (event: any) => {
    hiddenFileInput.current && hiddenFileInput.current.click()
  }

  useEffect(() => {
    setMaxSizeError(false)
    setGenericError(false)
  }, [changed])

  return (
    <InputContainer>
      <input
        ref={hiddenFileInput}
        accept="image/*"
        type="file"
        id={'image-upload'}
        disabled={disabled}
        onClick={(event: any) => {
          event.target.value = null
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const file = e.target && e.target.files && e.target.files[0]

          if (!file) {
            return
          }

          setChanged(!changed)

          if (maxSize && file.size > maxSize) {
            setMaxSizeError(true)
            return
          }

          const reader = new FileReader()
          reader.onloadend = ({ target }) => {
            if (target && target.result) {
              onChange({
                fileName: file.name,
                fileContent: target.result.toString(),
                fileSize: file.size,
              })
              setMaxSizeError(false)

              InsuranceCardService.createInsuranceCards({
                appointmentId,
                formId,
                requestBody: {
                  filename: file.name,
                  file: target.result.toString(),
                },
                ocpApimSubscriptionKey,
              })
                .then((res) => {
                  setInsuranceCards(
                    insuranceCards.concat([
                      {
                        documentId: res.documentId,
                        originalName: res.originalName,
                        insuranceLastModifiedDate:
                          res.insuranceLastModifiedDate,
                      },
                    ])
                  )
                })
                .catch((error: any) => {
                  setGenericError(true)
                })
            }
          }
          reader.readAsDataURL(file)
        }}
      />
      <label htmlFor={'image-upload'}>
        <Button disabled={disabled} $compact onClick={handleClick}>
          {children}
        </Button>
      </label>
    </InputContainer>
  )
}

export const InsuranceUploaderWidget = ({
  onChange,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()
  const { subscriptionKey: ocpApimSubscriptionKey, appointmentId } = useConfig()
  const formId = useContext(FormIdContext)

  const { data: insuranceCardsData } = useInsuranceCards(formId)
  const [maxSizeError, setMaxSizeError] = useState<boolean>(false)
  const [genericError, setGenericError] = useState<boolean>(false)

  const [insuranceCards, setInsuranceCards] = useState<InsuranceCard[]>(
    insuranceCardsData || []
  )

  const insuranceCardsContextValue = useMemo(
    () => ({ insuranceCards, setInsuranceCards }),
    [insuranceCards]
  )

  useEffect(() => {
    insuranceCardsData && setInsuranceCards(insuranceCardsData)
  }, [setInsuranceCards, insuranceCardsData])

  const deleteInsuranceCardCall = useCallback(
    (args: string[]) => {
      const docIdToDelete = args && args.length ? args[0] : ''
      return InsuranceCardService.deleteInsuranceCards({
        appointmentId,
        documentFileId: docIdToDelete,
        ocpApimSubscriptionKey,
      })
    },
    [appointmentId, ocpApimSubscriptionKey]
  )

  const { run: deleteInsuranceCard } = useAsync({
    deferFn: deleteInsuranceCardCall as (...args: any[]) => Promise<string>,
  })

  const handleUpload = () => {}

  const handleDownloadInsuranceCard = (docId: string, docName: string) => {
    downloadInsuranceCard(appointmentId, docId, docName, ocpApimSubscriptionKey)
  }

  const disableUploadButton = insuranceCards && insuranceCards.length >= 2

  useEffect(() => {
    !!insuranceCards && onChange(JSON.stringify(insuranceCards))
  }, [onChange, insuranceCards])

  return (
    <InsuranceDataContext.Provider value={insuranceCardsContextValue as any}>
      <BoxWrapper>
        <StyledWrapper>
          <Typography $variant="span" $weight="bold">
            {t('insuranceCardUploader.title')}
          </Typography>
          <UploadButton
            disabled={disableUploadButton}
            onChange={handleUpload}
            setMaxSizeError={setMaxSizeError}
            setGenericError={setGenericError}
            appointmentId={appointmentId}
            formId={formId}
            ocpApimSubscriptionKey={ocpApimSubscriptionKey}
            insuranceCards={insuranceCards}
            setInsuranceCards={setInsuranceCards}
          >
            <b>{t('insuranceCardUploader.uploadButton')}</b>
          </UploadButton>
        </StyledWrapper>
        <StyledWrapper>
          <Typography $variant="span">
            {t('insuranceCardUploader.subtitle')}
          </Typography>
        </StyledWrapper>

        {maxSizeError && (
          <StyledWrapper>
            <Typography $color="error" $variant="span" $weight="bold">
              {t('insuranceCardUploader.sizeError')}
            </Typography>
          </StyledWrapper>
        )}

        {genericError && (
          <StyledWrapper>
            <Typography $color="error" $variant="span" $weight="bold">
              {t('insuranceCardUploader.genericError')}
            </Typography>
          </StyledWrapper>
        )}

        {insuranceCards && insuranceCards.length
          ? insuranceCards.map((insuranceCard, index) => {
              return (
                <CardRowWrapper
                  key={`insurance-card-${insuranceCard.documentId}-${index}`}
                >
                  <TypographyWrapper
                    isPointer={true}
                    onClick={() =>
                      handleDownloadInsuranceCard(
                        insuranceCard.documentId,
                        insuranceCard.originalName
                      )
                    }
                  >
                    <StyledIcon src={InsuranceCardIcon} />
                    <Typography $weight="normal">
                      {insuranceCard.originalName}
                    </Typography>
                  </TypographyWrapper>
                  <TypographyWrapper>
                    <TypographyCardStatus>
                      <Typography $variant="span">
                        {t('insuranceCardUploader.uploaded')}
                      </Typography>
                    </TypographyCardStatus>
                    <StyledIconDelete
                      src={CrossCircleIcon}
                      onClick={() => {
                        deleteInsuranceCard(insuranceCard.documentId)
                        const newInsuranceCards = insuranceCards
                          ? insuranceCards.filter(
                              (c) => c.documentId !== insuranceCard.documentId
                            )
                          : []
                        setInsuranceCards(newInsuranceCards)
                      }}
                    />
                  </TypographyWrapper>
                </CardRowWrapper>
              )
            })
          : null}
      </BoxWrapper>
    </InsuranceDataContext.Provider>
  )
}
