import React from 'react'
import styled from 'styled-components'
import { Typography } from '../../Typography'

export interface HelperTextProps extends React.ComponentPropsWithRef<'p'> {}

const StyledParagraph = styled(Typography)`
  line-height: 1.44;
  letter-spacing: 0.5px;

  &::first-letter {
    text-transform: capitalize;
  }
`

export const HelperText: React.FC<HelperTextProps> = ({
  children,
  ...props
}) => {
  return (
    <StyledParagraph $align="left" {...props}>
      {children}
    </StyledParagraph>
  )
}
