/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * list of clinic type
 */
export enum ClinicType {
  CA = 'CA',
  CAN = 'CAN',
  EMP = 'EMP',
  FRAN = 'FRAN',
  FRANEMP = 'FRANEMP',
  FRANSUB = 'FRANSUB',
  MOD = 'MOD',
  SUB = 'SUB',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}
