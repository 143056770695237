import styled, { css } from 'styled-components'

import { Typography } from '@components/Typography'
import { StoreNameProps, stylesBasedOnThemes } from '@root/theme'
import { TextAreaProps } from './TextArea'

const LCSpan = css`
  &::after {
    color: ${({ theme: { colors } }) => colors.info};
  }
`

const OPSMSpan = css`
  &::after {
    color: ${({ theme: { colors } }) => colors.offer};
  }
`

type StyledSpanProps = Pick<TextAreaProps, 'required'> & StoreNameProps
const StyledSpan = styled(Typography)<StyledSpanProps>`
  font-weight: bold;
  line-height: 1.44;
  letter-spacing: 0.5px;

  &::first-letter {
    text-transform: capitalize;
  }

  ${({ storeName }) =>
    stylesBasedOnThemes({
      storeName,
      styles: {
        LC: LCSpan,
        PV: LCSpan,
        TO: LCSpan,
        FE: LCSpan,
        VT: LCSpan,
        OPSM: OPSMSpan,
      },
    })};

  &::after {
    content: ${({ required }) => (required ? "' *'" : '')};
  }
`

export { StyledSpan }
