import { DatePicker } from '@components/DatePicker'
import { TwoColumns } from '@components/Layout/TwoColumns'
import { InsuranceFormData } from '@components/RJSF/widgets/InsuranceWidget'
import { Select } from '@components/Select'
import { TextField } from '@components/TextField'
import { useCommonConfigs } from '@hooks/useCommonConfigs'
import { useConfig } from '@root/Context'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { genderOptions, relationshipOptions } from './constants'

type InsuranceDataProps = {
  namePrefix: string
  currentFormValues: InsuranceFormData
  setCurrentFormValues: (value: InsuranceFormData) => void
  setHasAdditionalErrors: any
  isOnSubmit: any
}

const InsuranceData = ({
  namePrefix,
  currentFormValues,
  setCurrentFormValues,
  setHasAdditionalErrors,
  isOnSubmit,
}: InsuranceDataProps) => {
  const { t } = useTranslation()
  const { country } = useConfig()

  const formCountry = currentFormValues.primaryHolderCountry

  const commonConfigs = useCommonConfigs()

  const onChange = (
    fieldName: keyof InsuranceFormData,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.target?.value
    const stateObject =
      fieldName === 'primaryHolderCountry' ? { primaryHolderState: '' } : {}
    setCurrentFormValues({
      ...currentFormValues,
      [fieldName]: value,
      ...stateObject,
    })
    setHasAdditionalErrors(!value?.length)
  }

  return (
    <>
      <TwoColumns data-id={'insurance-data-container'}>
        <Select
          label={t('insurance.relationship')}
          value={currentFormValues.relationshipWithHolder}
          onChange={onChange.bind(null, 'relationshipWithHolder')}
          error={isOnSubmit && !currentFormValues.relationshipWithHolder}
          errorMessage={t('core.requiredSelection')}
          required
        >
          <Select.Option value="" disabled>
            --
          </Select.Option>
          {relationshipOptions.map(({ label, value }) => (
            <Select.Option
              value={value}
              key={`${'relationshipWithHolder'}-${label}`}
            >
              {t(label)}
            </Select.Option>
          ))}
        </Select>
        <TextField
          name={namePrefix + 'PrimaryHolderFirstName'}
          label={t('insurance.primaryHolderFirstName')}
          value={currentFormValues.primaryHolderFirstName}
          onChange={onChange.bind(null, 'primaryHolderFirstName')}
          error={isOnSubmit && !currentFormValues.primaryHolderFirstName}
          errorMessage={t('core.requiredMessage')}
          $fullWidth
          required
        />
        <TextField
          name={namePrefix + 'PrimaryHolderLastName'}
          label={t('insurance.primaryHolderLastName')}
          value={currentFormValues.primaryHolderLastName}
          onChange={onChange.bind(null, 'primaryHolderLastName')}
          error={isOnSubmit && !currentFormValues.primaryHolderLastName}
          errorMessage={t('core.requiredMessage')}
          $fullWidth
          required
        />
        <Select
          label={t('insurance.primaryHolderSex')}
          name={namePrefix + 'PrimaryHolderGender'}
          value={currentFormValues.primaryHolderGender}
          onChange={onChange.bind(null, 'primaryHolderGender')}
          error={isOnSubmit && !currentFormValues.primaryHolderGender}
          errorMessage={t('core.requiredSelection')}
          required
        >
          <Select.Option value="" disabled>
            --
          </Select.Option>
          {genderOptions.map(({ label, value }) => (
            <Select.Option
              value={value}
              key={`${'primaryHolderGender'}-${label}`}
            >
              {t(label)}
            </Select.Option>
          ))}
        </Select>
        <DatePicker
          name={namePrefix + 'PrimaryHolderDOB'}
          label={t('insurance.primaryHolderDOB')}
          value={currentFormValues.primaryHolderDOB}
          onChange={onChange.bind(null, 'primaryHolderDOB')}
          error={isOnSubmit && !currentFormValues.primaryHolderDOB}
          errorMessage={t('core.requiredMessage')}
          required
        />
        <TextField
          name={namePrefix + 'PrimaryHolderAddress'}
          label={t('insurance.primaryHolderAddress')}
          value={currentFormValues.primaryHolderAddress}
          onChange={onChange.bind(null, 'primaryHolderAddress')}
          error={isOnSubmit && !currentFormValues.primaryHolderAddress}
          errorMessage={t('core.requiredMessage')}
          $fullWidth
          required
        />
        <TextField
          name={namePrefix + 'PrimaryHolderCity'}
          label={t('insurance.primaryHolderCity')}
          value={currentFormValues.primaryHolderCity}
          onChange={onChange.bind(null, 'primaryHolderCity')}
          error={isOnSubmit && !currentFormValues.primaryHolderCity}
          errorMessage={t('core.requiredMessage')}
          $fullWidth
          required
        />
        <Select
          label={t('core.country')}
          name={namePrefix + 'PrimaryHolderCountry'}
          value={currentFormValues.primaryHolderCountry}
          onChange={onChange.bind(null, 'primaryHolderCountry')}
          error={isOnSubmit && !currentFormValues.primaryHolderCountry}
          errorMessage={t('core.requiredSelection')}
          required
        >
          <Select.Option value="" disabled>
            --
          </Select.Option>
          {commonConfigs.country?.map(({ label, value }, index) => (
            <Select.Option value={value} key={`country-${label}-${index}`}>
              {t(`country.${label}`)}
            </Select.Option>
          ))}
        </Select>
        <TextField
          name={namePrefix + 'PrimaryHolderZipCode'}
          label={t('insurance.primaryHolderZip')}
          value={currentFormValues.primaryHolderZipCode}
          onChange={onChange.bind(null, 'primaryHolderZipCode')}
          error={isOnSubmit && !currentFormValues.primaryHolderZipCode}
          errorMessage={t('core.requiredMessage')}
          $fullWidth
          required
        />
        <Select
          label={t('core.state')}
          name={namePrefix + 'PrimaryHolderState'}
          value={currentFormValues.primaryHolderState}
          onChange={onChange.bind(null, 'primaryHolderState')}
          error={isOnSubmit && !currentFormValues.primaryHolderState}
          errorMessage={t('core.requiredSelection')}
          required
        >
          <Select.Option value="" disabled>
            --
          </Select.Option>
          {commonConfigs[formCountry ? `state${formCountry}` : 'state']?.map(
            ({ label, value }, index) => (
              <Select.Option
                value={value}
                key={`${'primaryHolderState'}-${label}-${index}`}
              >
                {value}
              </Select.Option>
            )
          )}
        </Select>

        {country !== 'CAN' && (
          <TextField
            name={namePrefix + 'PrimaryHolderSSN'}
            label={t('insurance.primaryHolderSSN')}
            value={currentFormValues.primaryHolderSSN}
            onChange={onChange.bind(null, 'primaryHolderSSN')}
            error={isOnSubmit && !currentFormValues.primaryHolderSSN}
            errorMessage={t('core.requiredMessage')}
            required
            styleLabel={{ whiteSpace: 'nowrap' }}
          />
        )}
      </TwoColumns>
    </>
  )
}

export default InsuranceData
