export const relationshipOptions = [
  { value: 'SELF', label: 'core.self' },
  { value: 'SPOUSE', label: 'core.spouse' },
  { value: 'CHILD', label: 'core.child' },
  { value: 'OTHER', label: 'core.other' },
]

export const genderOptions = [
  { value: 'Male', label: 'gender.male' },
  { value: 'Female', label: 'gender.female' },
  { value: 'Other', label: 'gender.other' },
  { value: 'Unknown', label: 'gender.unknown' },
]
