import React, { useContext, useEffect, useState } from 'react'
import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
import { useTranslation } from 'react-i18next'
import { FormDataContext } from '@root/anz/components/AnzForm/JSONSchemaForm'
import { handleOnChange } from '../utils/handleOnChange'
import { validateFormat } from '../customFormats'
import { useConfig } from '@root/Context'

const ZipCodeWidget = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()
  const { country } = useConfig()
  const [currentErrors, setCurrentErrors] = useState<string[]>()
  const formData = useContext(FormDataContext)
  const currentFormCountry: string = formData.country as string

  useEffect(() => {
    if (
      value &&
      (currentFormCountry === 'CAN'
        ? !validateFormat(country || 'US').zipCodeCAN.test(value)
        : !validateFormat(country || 'US').zipCode.test(value))
    ) {
      setCurrentErrors([t('anz.formErrorLabels.format')])
    } else {
      setCurrentErrors([])
    }
  }, [value, currentFormCountry, setCurrentErrors])

  return currentFormCountry ? (
    <TextField
      name={id}
      value={value}
      label={
        currentFormCountry === 'US' ? t('core.zipCode') : t('core.postCode')
      }
      onChange={handleOnChange(onChange)}
      disabled={disabled}
      required={required}
      $fullWidth
      error={!!currentErrors?.length}
      errorMessage={currentErrors?.join('\n')}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
    />
  ) : null
}

export default ZipCodeWidget
