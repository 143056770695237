import {
  BaseInput,
  CheckboxesWidget,
  DateWidget,
  DigitsWidget,
  RadioWidget,
  SelectWidget,
  MultiSelectWidget,
  CheckboxWidget,
  TextareaWidget,
  SelfRelativesWidget,
  YearsWidget,
} from './widgets'

import { DescriptionField, TitleField } from './fields'
import InsuranceWidget from './widgets/InsuranceWidget'
import RelativeWidget from './widgets/RelativeWidget'
import FloaterWidget from './widgets/FloaterWidget'
import DateValidationWidget from './widgets/DateValidationWidget'
import AgeWidget from './widgets/AgeWidget'
import { InsuranceUploaderWidget } from './widgets/InsuranceUploaderWidget'
import TextBoldWidget from './widgets/TextBoldWidget'
import FemaleRadioWidget from './widgets/FemaleRadioWidget'
import EyeglassPrescriptionWidget from './widgets/EyeglassPrescriptionWidget'
import ContactLensTypeWidget from './widgets/ContactLensTypeWidget'
import ZipCodeWidget from './widgets/ZipCodeWidget'
import MedicalInsuranceWidget from './widgets/MedicalInsuranceWidget'
import PhoneNumberWidget from './widgets/PhoneNumberWidget'
import { ContactLensInterestWidget } from './widgets/ContactLensInterestWidget'

export const widgets = {
  BaseInput,
  TextBoldWidget,
  AgeWidget,
  CheckboxesWidget,
  SelectWidget,
  MultiSelectWidget,
  DateWidget,
  DateValidationWidget,
  DigitsWidget,
  RadioWidget,
  FemaleRadioWidget,
  CheckboxWidget,
  TextareaWidget,
  SelfRelativesWidget,
  YearsWidget,
  InsuranceWidget,
  MedicalInsuranceWidget,
  RelativeWidget,
  FloaterWidget,
  InsuranceUploaderWidget,
  EyeglassPrescriptionWidget,
  ContactLensTypeWidget,
  ZipCodeWidget,
  PhoneNumberWidget,
  ContactLensInterestWidget,
}

export const fields = {
  DescriptionField,
  TitleField,
}
