/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The brand which require the resource.
 */
export enum Brand {
  LC = 'LC',
  TO = 'TO',
  PV = 'PV',
}
