import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { Typography } from '../Typography'
import { Tooltip } from '../Tooltip'
import { StyledSpan } from './Checkbox.styling'
import { useConfig } from '@root/Context'

export interface CheckboxProps extends React.ComponentPropsWithRef<'input'> {
  label?: React.ReactNode
  error?: boolean
  errorMessage?: string
  tooltip?: string
}

const StyledInput = styled.input<Omit<CheckboxProps, 'label'>>(
  ({ error, theme: { colors } }) => `
  display: none;

  &:disabled + span::before {
    color: ${colors.onSurface};
    cursor: not-allowed;
  }

  & + span::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 18px;
    height: 18px;
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    border-color: ${error ? colors.error : colors.onSurface};
    margin-right: 13px;
  }

  &:checked + span::after {
    content: '✓';
    color: ${colors.primary};
    font-weight: bolder;
    text-align: center;
    position: absolute;
    left: 2px;
    top: -2px;
    font-size: larger;
  }
`
)

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledLabel = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
`

const StyledError = styled(Typography)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`

/**
 * Function taken by default CheckboxesWidget:
 * https://github.com/rjsf-team/react-jsonschema-form/blob/8f9318ff8cc878275719e910e9c99be6af08d90f/packages/core/src/components/widgets/CheckboxesWidget.js#L4
 */
export function selectValue(value: string, selected: string[], all: string[]) {
  const at = all.indexOf(value)
  const updated =
    selected?.slice(0, at)?.concat(value, selected?.slice(at)) || []

  return updated.sort((a, b) => Number(all.indexOf(a) > all.indexOf(b)))
}

/**
 * Function taken by default CheckboxesWidget:
 * https://github.com/rjsf-team/react-jsonschema-form/blob/8f9318ff8cc878275719e910e9c99be6af08d90f/packages/core/src/components/widgets/CheckboxesWidget.js#L12
 */
export function deselectValue(value: string, selected: string[]) {
  const arrayWithoutValue = selected.filter((v) => v !== value)

  return arrayWithoutValue
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { className, error, errorMessage, label, style, tooltip = '', ...props },
    ref
  ) => {
    const { brand } = useConfig()

    return (
      <StyledWrapper className="intake__checkbox-wrapper">
        <StyledLabel
          className={clsx(
            'intake__checkbox',
            {
              'intake__checkbox--error': error,
            },
            className
          )}
          style={style}
        >
          <StyledInput
            type="checkbox"
            error={error}
            {...props}
            ref={ref}
            aria-invalid={error ? 'true' : 'false'}
          />
          <StyledSpan
            storeName={brand}
            $variant="span"
            required={props.required}
          >
            {label}
            {tooltip?.length !== 0 && <Tooltip>{tooltip}</Tooltip>}
          </StyledSpan>
          {error && (
            <StyledError $variant="span" $color="error" role="alert">
              {errorMessage}
            </StyledError>
          )}
        </StyledLabel>
      </StyledWrapper>
    )
  }
)
