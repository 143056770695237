import React from 'react'
import clsx from 'clsx'
import styled, { css } from 'styled-components'
import { Button, LinkButton, ButtonProps } from '@components/Button'
import { Modal, ModalProps } from '@components/Modal'
import { Typography } from '@components/Typography'
import ArrowIcon from '../../assets/icons/arrow.svg'
import CheckIcon from '../../assets/icons/check.svg'
import ExclamationIcon from '../../assets/icons/exclamation.svg'
import { useTranslation } from 'react-i18next'
import { anzForm } from '@utils/routes'
import { DocumentType } from '@api'
import { Icon } from '@components/Icon'

interface LegalSignModalProps extends ModalProps {
  documentPageHandler: (docType: DocumentType) => void
  availableDocuments: Record<DocumentType, boolean>
}

const StyledDocumentsSection = styled.section`
  display: grid;
  gap: 20px;
`

const DocumentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.modalItemBg};
  padding: 8px 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledIcon = styled(Icon)`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
`

const StyledArrowButton = styled(Button).attrs({
  src: ArrowIcon,
})<
  ButtonProps & {
    src: string
  }
>`
  width: 25px;
  height: 25px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(90deg);
  padding: 5px;
  transition: transform 0.25s ease-in-out;
  margin-inline-start: auto;

  &&&:not(disabled):hover,
  &&&:not(disabled):focus {
    box-shadow: unset;
    transform: rotate(90deg) translateY(-5px);
  }
`

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;

  & * {
    width: 60%;
  }

  & > * + * {
    margin-bottom: 15px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.media.large} {
      flex-direction: row;

      & * {
        width: auto;
      }

      & > * + * {
        margin-bottom: 0px;
        margin-left: 30px;
      }
    }
  `}
`

export const LegalSignModal: React.FC<LegalSignModalProps> = ({
  documentPageHandler,
  availableDocuments,
  ...props
}) => {
  const { t } = useTranslation()

  const isPrivacyPracticeSigned = false
  const isHealthInformationSigned = false

  return (
    <Modal
      {...props}
      id="legal-sign-modal"
      data-element-id="X_OEE_Intro_Legal-Overlay"
    >
      <Modal.Content>
        <Typography>{t('legalSignModal.description')}</Typography>

        <StyledDocumentsSection>
          {availableDocuments.dif && !isPrivacyPracticeSigned && (
            <DocumentWrapper className={'legal-sign-modal__document-wrapper'}>
              <StyledIcon
                className={clsx({
                  'intake__icon-check': isPrivacyPracticeSigned,
                  'intake__icon-exclamation': !isPrivacyPracticeSigned,
                })}
                src={isPrivacyPracticeSigned ? CheckIcon : ExclamationIcon}
              />
              <Typography $color={'onSurface'} $weight="normal">
                {t('intro.privacyDescription')}
              </Typography>
              <StyledArrowButton
                onClick={() => documentPageHandler(DocumentType.DIF)}
              />
            </DocumentWrapper>
          )}
        </StyledDocumentsSection>
      </Modal.Content>

      <StyledModalActions>
        <Button $outlined onClick={props.onRequestClose}>
          {t('core.goBack')}
        </Button>
        <LinkButton to={anzForm()}>{t('core.understand')}</LinkButton>
      </StyledModalActions>
    </Modal>
  )
}
