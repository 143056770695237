import { WidgetProps } from '@rjsf/core'
import Select, { ActionMeta, MultiValue } from 'react-select'
import styled from 'styled-components'

import { InputGroup } from '@components/InputGroup'
import { isArray } from 'lodash'

export interface Option {
  value: any
  label: string
  key?: string
  disabled?: boolean
}

export interface MultiSelectProps
  extends React.ComponentPropsWithRef<typeof Select> {
  label: string
  error?: boolean
  errorMessage?: string
  $fullWidth?: boolean
}

const handleOnChange =
  (onChange: (value: any) => void) =>
  (newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    return onChange(newValue.map((x) => x.value))
  }

const StyledLabel = styled(InputGroup.Label)`
  margin-bottom: 17.25px;
`

export const transformValueInArray = (value: string[] | string | undefined) => {
  if (!value) {
    return []
  }

  if (isArray(value)) {
    return value
  }

  return value.split(',').map((x) => x.trim())
}

const MultiSelectWidget = ({
  id,
  label,
  onChange,
  options,
  rawErrors,
  required,
  value,
  disabled,
}: React.PropsWithChildren<WidgetProps>) => {
  const hasError = !!rawErrors?.length
  const errorMessage = rawErrors?.join('\n')
  const defaultValue = transformValueInArray(value).map((x: string) => ({
    value: x,
    label: x,
  }))

  return (
    <InputGroup>
      <StyledLabel required={required}>{label.trim()}</StyledLabel>

      <Select
        isDisabled={disabled}
        options={options.enumOptions as unknown as Option[]}
        isMulti
        onChange={handleOnChange(onChange)}
        defaultValue={defaultValue}
        name={id}
        styles={{
          control: (defaultStyles) => ({
            ...defaultStyles,
            borderRadius: '8px',
            padding: '8px 6px',
          }),
        }}
      />

      <InputGroup.Errors error={hasError} errorMessage={errorMessage} />
    </InputGroup>
  )
}

export default MultiSelectWidget
