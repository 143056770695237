/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Config } from '../models/Config'
import { request as __request } from '../core/request'

export class ConfigService {
  /**
   * Get all fields, visibilities and mandatory rules of base form using country and brand
   * @returns Config OK
   * @throws ApiError
   */
  public static async getConfigs({
    brand,
    caller,
    ocpApimSubscriptionKey,
  }: {
    /** Brand which requests the resources. **/
    brand: string
    /** The component/system/user that requires the resource. **/
    caller: string
    /** Subscription API Key **/
    ocpApimSubscriptionKey: string
  }): Promise<Config> {
    const result = await __request({
      method: 'GET',
      path: `/${brand}/configs`,
      headers: {
        caller: caller,
        'Ocp-Apim-Subscription-Key': ocpApimSubscriptionKey,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Error received from the backend`,
      },
    })
    return result.body
  }
}
