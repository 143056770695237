import React, { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Modal, ModalProps } from '@components/Modal'
import {
  BLANK_RELATIVES_RECORD,
  RelativesRecord,
} from '@root/anz/types/anzTypes'

const StyledModalContent = styled(Modal.Content)`
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 0.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.media.small} {
      grid-template-columns: repeat(2, 1fr);
    }
  `}
`

const StyledModalActions = styled(Modal.Actions)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export type AnzRelativesModalProps = ModalProps & {
  values: RelativesRecord
  onConfirm: (values: RelativesRecord) => void
  onClose: React.Dispatch<SetStateAction<string | undefined>>
}

export const RelativesModal = ({
  isOpen,
  values,
  onConfirm,
  onClose,
  style,
  ...props
}: AnzRelativesModalProps) => {
  const { t } = useTranslation()

  const [selectedRelativesMap, setSelectedRelativesMap] =
    useState<RelativesRecord>({ ...BLANK_RELATIVES_RECORD, ...values })

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    setSelectedRelativesMap((currentMap) => ({
      ...currentMap,
      [name]: checked,
    }))
  }

  return (
    <Modal
      {...props}
      id="relatives-modal"
      isOpen
      bodyOpenClassName="intake__modal-body-hidden"
      style={{
        overlay: { display: isOpen ? 'block' : 'none' },
        content: { display: isOpen ? 'block' : 'none' },
      }}
    >
      <StyledModalContent>
        {Object.entries(selectedRelativesMap).map(([key, value], index) => (
          <Checkbox
            key={`${key}-${index}`}
            checked={value}
            label={t(`relatives.${key}`)}
            name={key}
            onChange={handleCheckboxChange}
          />
        ))}
      </StyledModalContent>

      <StyledModalActions>
        <Button $outlined onClick={onClose.bind(null, undefined)}>
          {t('core.close')}
        </Button>

        <Button onClick={onConfirm.bind(null, selectedRelativesMap)}>
          {t('core.confirm')}
        </Button>
      </StyledModalActions>
    </Modal>
  )
}
