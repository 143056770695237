import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Typography } from '../Typography'

import { Loader } from '@components/Loader'
import { Modal } from '@components/Modal'

const Divider = styled.div`
  width: 90%;
  border: solid 0.6px #bdbdbd;
  margin: auto;
  margin-top: 73px;
`

const StyledLoader = styled(Loader)`
  margin-bottom: 30px;
  margin-top: 60px;
`

const StyledSecurityInfo = styled(Typography)`
  font-size: 12px;
  font-weight: 200;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #777777;
`

const StyledModalTitle = styled(Modal.Title)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ModalEligibilityLoading: React.FC<
  React.ComponentPropsWithoutRef<'div'>
> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <div className={className}>
      <StyledModalTitle>
        <StyledLoader height={50} />
        {t('eligibility_loading_title')}
      </StyledModalTitle>

      <Modal.Content>
        <Typography>{t('elibibility_loading_text')}</Typography>

        <Divider />

        <StyledSecurityInfo>{t('errorModal.securityInfo')}</StyledSecurityInfo>
      </Modal.Content>
    </div>
  )
}
