import clsx from 'clsx'
import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { Typography } from '../Typography'

import { useConfig } from '@root/Context'
import { StyledInput, StyledSpan, StyledWrapper } from './TextField.styling'
import useIsFieldReadonly from '@hooks/useIsFieldReadonly'

export interface TextFieldProps
  extends Omit<React.ComponentPropsWithRef<'input'>, 'type'> {
  label?: string
  error?: boolean
  errorMessage?: string
  helperText?: React.ReactNode
  $fullWidth?: boolean
  styleLabel?: CSSProperties
  isLabelBold?: boolean
}

const StyledLabel = styled.label``

const StyledError = styled(Typography)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      children,
      className,
      label,
      error,
      errorMessage,
      helperText,
      name,
      $fullWidth = false,
      style,
      styleLabel,
      required = false,
      readOnly,
      isLabelBold,
      ...props
    },
    ref
  ) => {
    const { brand } = useConfig()
    const isConfigReadonly = useIsFieldReadonly(name)

    const isReadonly = !!readOnly || isConfigReadonly

    return (
      <StyledWrapper
        storeName={brand}
        className={clsx(
          'intake__textfield',
          {
            'intake__textfield--error': error,
          },
          className
        )}
        style={style}
      >
        {children}
        {label && (
          <StyledLabel htmlFor={name}>
            <StyledSpan
              storeName={brand}
              $variant="span"
              required={required}
              style={styleLabel}
              isBold={!!isLabelBold}
              $color={'text'}
            >
              {label}
            </StyledSpan>
          </StyledLabel>
        )}
        <StyledInput
          storeName={brand}
          type="text"
          name={name}
          error={error}
          readOnly={isReadonly}
          {...props}
          $fullWidth={$fullWidth}
          ref={ref}
          aria-invalid={error ? 'true' : 'false'}
        />
        {helperText}
        {error && errorMessage && (
          <StyledError $variant="span" $color="error" role="alert">
            {errorMessage}
          </StyledError>
        )}
      </StyledWrapper>
    )
  }
)
