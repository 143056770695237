import React, { FC, createContext, useCallback, useContext } from 'react'

import { ConfigService } from '../../api'
import { Config as ConfigServiceType } from '../../api'
import { useAsync } from 'react-async'
import { useConfig } from '../../Context'

// #region ::: PARTIALS
// HOOK
export const useTabApiConfig = () => useContext(TabApiFormConfigsContext)
// CONTEXT
export const TabApiFormConfigsContext = createContext(
  {} as ContextFormConfigsValueType
)
// TYPES
const useAsyncWithFormConfigs = () => useAsync<ConfigServiceType>({})
export type ContextFormConfigsValueType = ReturnType<
  typeof useAsyncWithFormConfigs
>
// #endregion

export const ProviderConfigs: FC = ({ children }) => {
  const { brand, subscriptionKey: ocpApimSubscriptionKey, caller } = useConfig()

  const getFormConfigs = useCallback(
    () =>
      ConfigService.getConfigs({
        brand,
        caller,
        ocpApimSubscriptionKey,
      }),
    [brand, caller, ocpApimSubscriptionKey]
  )

  const tabApiFormConfigsContextValue = useAsync<ConfigServiceType>({
    promiseFn: getFormConfigs,
  })

  return (
    <TabApiFormConfigsContext.Provider value={tabApiFormConfigsContextValue}>
      {children}
    </TabApiFormConfigsContext.Provider>
  )
}
