import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import ConfirmationIcon from '../assets/icons/confirmation.svg'
import { AppointmentRecap } from '@components/AppointmentRecap'
import { Icon } from '@components/Icon'
import { Button } from '@components/Button'
import { Layout } from '@components/Layout'
import { Loader } from '@components/Loader'
import { StepLayout } from '@components/StepLayout'
import { Typography } from '@components/Typography'
import { useConfig } from '../Context'
import { useTabApiAppointment } from '@services/TabApiProvider/ProviderAppointment'
import {
  flatFormObjectIfAnz,
  useTabApiForm,
} from '@services/TabApiProvider/hooks/useTabApiForm'
import usePageClassname from '../hooks/usePageClassname'
import { getHideLogo } from '@root/utils/logo'

const StyledCtaButtonWrapper = styled.div`
  display: grid;
  justify-content: center;
  gap: 24px;

  ${({ theme }) => theme.breakpoints.media.large} {
    grid-template-columns: repeat(2, 1fr);
    gap: 56px;
  }
`

const StyledConfirmationIcon = styled(Icon).attrs({
  src: ConfirmationIcon,
  className: 'intake__icon--confirmation',
})`
  margin: auto;
  width: 1em;
  height: 1em;
`

const CtaSection = () => {
  const { t } = useTranslation()
  const { thankYouPageCta } = useConfig()

  const onClick = (url: string) => window.open(url, '_blank')

  if (thankYouPageCta) {
    return (
      <section>
        <Typography $weight="bold">{t('core.additionalCta')}</Typography>
        <StyledCtaButtonWrapper>
          {thankYouPageCta.map(({ description, link, analyticsTag }, index) => (
            <Button
              $outlined
              key={`thankYouPageCta-link-${index}`}
              data-element-id={analyticsTag}
              onClick={() => onClick(link)}
            >
              {description}
            </Button>
          ))}
        </StyledCtaButtonWrapper>
      </section>
    )
  }

  return null
}

const StyledCreateAccountSection = styled.section`
  text-align: center;
  margin-bottom: 58px;
`

const CreateAccountSection = () => {
  const { t } = useTranslation()
  const { createAccountAction, brandWebsite } = useConfig()
  const { data, isLoading: isLoadingAppointment } = useTabApiAppointment()
  const isGuest = data?.patient.userType === 'ONLINE_GUEST'

  usePageClassname('confirm-page')

  if (isLoadingAppointment) return <Loader />

  if (isGuest && createAccountAction) {
    return (
      <StyledCreateAccountSection>
        <Typography $weight="bold">
          {t('confirmation.guestCta', {
            site: brandWebsite || 'LensCrafters.com',
          })}
        </Typography>

        <Button
          onClick={createAccountAction}
          data-element-id="X_OEE_TYP_CreateAccount"
        >
          {t('core.createAccount')}
        </Button>
      </StyledCreateAccountSection>
    )
  }

  return null
}

export const Confirmation = () => {
  const { t } = useTranslation()
  const { data: formData, isLoading: isLoadingForm } = useTabApiForm()
  const data = flatFormObjectIfAnz(formData)
  const { country } = useConfig()

  if (isLoadingForm) return <Loader fullHeight />

  if (data && !isLoadingForm) {
    const { firstName } = data

    return (
      <Layout>
        <Layout.Header hideLogo={getHideLogo(country)} />

        <Layout.Content>
          <StepLayout data-element-id={'confirmation-layout'} hideRequiredHint>
            <StepLayout.Title $align="center">
              <StyledConfirmationIcon />
              {t('confirmation.title', { name: firstName })}
            </StepLayout.Title>
            <StepLayout.Description $align="center">
              {t('confirmation.description')}
            </StepLayout.Description>

            <CreateAccountSection />

            <CtaSection />
          </StepLayout>

          <AppointmentRecap expanded />
        </Layout.Content>
      </Layout>
    )
  }

  return null
}
