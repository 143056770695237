import React, { useEffect, useState } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Actions } from './Actions'
import CloseIcon from '../../assets/icons/close.svg'
import { Content } from './Content'
import { Title } from './Title'
import clsx from 'clsx'
import { useConfig } from '../../Context'
import ModalStyles from './themes/ModalStyles'
import { Typography } from '@components/Typography'

export interface ModalProps extends ReactModalProps {
  showCloseButton?: boolean
  closeButtonType?: 'icon' | 'text'
  ['data-element-id']?: string
}

const StyledClose = css`
  cursor: pointer;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
`

const StyledCloseText = styled(Typography)`
  ${StyledClose};
  text-decoration: underline;
`

const StyledCloseButton = styled.button`
  background-image: url('${CloseIcon}');
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: none;
  background-color: transparent;
  ${StyledClose};
`

export const Modal: React.FC<ModalProps> & {
  Title: typeof Title
  Content: typeof Content
  Actions: typeof Actions
} = ({
  children,
  showCloseButton = false,
  closeButtonType = 'icon',
  className,
  bodyOpenClassName = 'intake__modal-body-open',
  ...props
}) => {
  const { t } = useTranslation()
  const [overlayRef, setOverlayRef] = useState<HTMLDivElement>()
  const dataElementId = props['data-element-id']
  const { selector, brand } = useConfig()
  const container = document.querySelector(
    selector ? selector : '#root'
  ) as HTMLElement
  useEffect(() => {
    if (dataElementId && overlayRef) {
      overlayRef.setAttribute('data-element-id', dataElementId)
    }
  }, [overlayRef, dataElementId])

  return (
    <>
      <ModalStyles storeName={brand} />
      <ReactModal
        appElement={container}
        ariaHideApp={process.env.NODE_ENV !== 'test'}
        {...props}
        bodyOpenClassName={bodyOpenClassName}
        className={{
          base: clsx('intake__modal-content', className),
          afterOpen: 'intake__modal-content--after-open',
          beforeClose: 'intake__modal-content--before-close',
        }}
        overlayClassName={{
          base: 'intake__modal-overlay',
          afterOpen: 'intake__modal-overlay--after-open',
          beforeClose: 'intake__modal-overlay--before-close',
        }}
        portalClassName="intake__modal"
        aria={{
          labelledby: 'intake__modal-heading',
          describedby: 'intake__modal-description',
        }}
        overlayRef={setOverlayRef}
        parentSelector={() => container || document.body}
      >
        {showCloseButton && closeButtonType === 'icon' ? (
          <StyledCloseButton onClick={props.onRequestClose} />
        ) : null}
        {showCloseButton && closeButtonType === 'text' ? (
          <StyledCloseText onClick={props.onRequestClose} $noMargin>
            {t('core.close')}
          </StyledCloseText>
        ) : null}
        {children}
      </ReactModal>
    </>
  )
}

Modal.Title = Title
Modal.Content = Content
Modal.Actions = Actions
