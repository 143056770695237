import { useConfig } from '@root/Context'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

const useIsFieldReadonly = (fieldName: string | undefined) => {
  const { readonlyFields } = useConfig()
  const [isFieldValid, setIsFieldValid] = useState(true)
  const formContext = useFormContext()

  const isFieldInReadonlyConfig =
    !!fieldName && readonlyFields?.includes(fieldName)

  const isReadonly = isFieldInReadonlyConfig && isFieldValid

  useEffect(() => {
    if (!formContext || !fieldName || !isFieldInReadonlyConfig) {
      return
    }

    ;(async () => {
      let isValid = await formContext.trigger(fieldName)

      if (!isValid) {
        // For some reasons date fields are always invalid in the first validation, so we retry
        isValid = await formContext.trigger(fieldName)
      }
      if (!isValid) {
        formContext.clearErrors(fieldName)
      }

      setIsFieldValid(isValid)
    })()
  }, [fieldName])

  return isReadonly
}

export default useIsFieldReadonly
