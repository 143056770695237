import React from 'react'
import clsx from 'clsx'
import styled from 'styled-components'
import { OptGroup } from './OptGroup'
import { Option } from './Option'
import { Typography } from '../Typography'
import { useConfig } from '@root/Context'

import { StyledWrapper, StyledSelect, StyledSpan } from './Select.style'
import useIsFieldReadonly from '@hooks/useIsFieldReadonly'

export interface SelectProps extends React.ComponentPropsWithRef<'select'> {
  label: string
  error?: boolean
  errorMessage?: string
  $fullWidth?: boolean
}

// ? Contrary to SFC/FC interface, it seems one can't define static property so easily when using React.forwardRef
// ? https://github.com/DefinitelyTyped/DefinitelyTyped/issues/34757#issuecomment-488848720
interface CompoundedComponent
  extends React.ForwardRefExoticComponent<
    SelectProps & React.RefAttributes<HTMLSelectElement>
  > {
  Option: typeof Option
  OptGroup: typeof OptGroup
}

export const StyledLabel = styled.label`
  position: relative;
`

const StyledError = styled(Typography)`
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
`

export const Select = React.forwardRef<
  HTMLSelectElement,
  SelectProps & {
    Option: typeof Option
    OptGroup: typeof OptGroup
  }
>(
  (
    {
      children,
      className,
      label,
      error,
      errorMessage,
      name,
      style,
      $fullWidth = false,
      required,
      disabled,
      ...props
    },
    ref
  ) => {
    const { brand } = useConfig()

    const isConfigReadonly = useIsFieldReadonly(name)
    const isReadonly = !!disabled || isConfigReadonly

    return (
      <StyledWrapper
        storeName={brand}
        className={clsx(
          'intake__select',
          {
            'intake__select--error': error,
          },
          className
        )}
        style={style}
      >
        {label && (
          <StyledLabel htmlFor={name}>
            <StyledSpan storeName={brand} $variant="span" required={required}>
              {label.trim()}
            </StyledSpan>
          </StyledLabel>
        )}
        <StyledSelect
          storeName={brand}
          error={error}
          name={name}
          disabled={isReadonly}
          {...props}
          ref={ref}
          aria-invalid={error}
          $fullWidth={$fullWidth}
        >
          {children}
        </StyledSelect>
        {error && (
          <StyledError $variant="span" $color="error" role="alert">
            {errorMessage}
          </StyledError>
        )}
      </StyledWrapper>
    )
  }
) as CompoundedComponent

Select.OptGroup = OptGroup
Select.Option = Option
