import { AnzForm, Form } from '@api'

const COMPLETED_STATUS = 'COMPLETED' as const
const CANCELLED_STATUS = 'CANCELLED' as const

type IsFormCompleted = (formData: Form | AnzForm | undefined) => {
  isFormCompleted: boolean
}

const useIsFormCompleted: IsFormCompleted = (
  formData: Form | AnzForm | undefined
) => {

  const { statusForm, step } = formData || {}

  return {
    isFormCompleted: (statusForm === COMPLETED_STATUS || step === COMPLETED_STATUS) || statusForm === CANCELLED_STATUS,
  }
}

export default useIsFormCompleted
