import { parse, parseISO } from 'date-fns'
import { format as tzformat } from 'date-fns-tz'

import { BrandName } from './../theme'

export const localeToDateMask = (locale: string) => {
  const m = 1
  const d = 28
  const y = 2012
  const date = new Date(y, m - 1, d, 0, 0)
  try {
    const dtf = date.toLocaleDateString(locale)
    const sepArray = /[^\d]/.exec(dtf)
    if (!sepArray) return 'MM/dd/yyyy'

    const sep = sepArray[0]
    return dtf
      .split(sep)
      .map((p) => {
        const mask =
          +p === m
            ? 'MM'
            : +p === d || +p === d - 1 || +p === d + 1 // XXX for timezone issue it could be the previous or the next day...
            ? 'dd'
            : +p === y
            ? 'yyyy'
            : 'dd'
        return mask
      })
      .join(sep)
  } catch (e) {
    return 'MM/dd/yyyy'
  }
}

export const localeToDatePickerMask = (locale: string) => {
  return localeToDateMask(locale).replace(/[Mdy]/g, '9')
}

export const localeDateStringToDate = (
  dateString: string,
  locale: string
): number | Date => {
  const mask = localeToDateMask(locale)
  const date = parse(dateString, mask, new Date())
  return date
}

export const localeDateStringToIsoString = (
  dateString: string | undefined,
  locale: string
): string | undefined => {
  if (!dateString) return
  const date = localeDateStringToDate(dateString, locale)
  return tzformat(date, 'yyyy-MM-dd')
}

export const isoStringToLocaleDateString = (options: {
  isoString?: string
  locale: string
  includeHours?: boolean
  customMask?: string
}): string | undefined => {
  const { isoString, includeHours, locale, customMask } = options

  if (!isoString) return

  const dateStr = includeHours
    ? isoString.replace(/Z/, '')
    : isoString.substring(0, 10).replace(/-g/, '/').replace(/T.+/, '')
  const mask = customMask || localeToDateMask(locale)

  const parsedTime = parseISO(dateStr)
  return tzformat(parsedTime, `${mask}`, {
    timeZone: 'UTC',
  })
}

export const isoStringToDateString = (options: {
  isoString?: string
  brand?: BrandName
}): string | undefined => {
  const { isoString, brand } = options

  if (!isoString) return

  // XXX: it's just formatting the string in US format.
  // there are issue by using diferent timezones set by browser plugin
  // see: https://abstractsrl.atlassian.net/browse/IF-328
  const dateParts = isoString.split('-')

  if (brand === 'OPSM') {
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`
  }

  return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`
}

const DEFAULT_RESEND_COUNTDOWN = 60

export const getOtpResendCountdown = (
  timeBeforeRecreate = DEFAULT_RESEND_COUNTDOWN
): Date => {
  return new Date(Date.now() + timeBeforeRecreate * 1000)
}
