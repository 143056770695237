import React from 'react'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import { Tooltip } from '../components/Tooltip'

const htmlToReactParser = new HtmlToReactParser()

const isValidNode = () => true

const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions()

const processingInstructions = [
  {
    shouldProcessNode: (node: any) => {
      return node?.attribs?.['data-tooltip'] != null
    },
    processNode: function (node: any, children: any) {
      const position = node?.attribs?.['data-tooltip'] || 'top'
      return <Tooltip position={position}>{children}</Tooltip>
    },
  },
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

export const htmlToReactComponent = (htmlInput: string) =>
  htmlToReactParser.parseWithInstructions(
    htmlInput,
    isValidNode,
    processingInstructions
  )
