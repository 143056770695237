import { useEffect, useState } from 'react'
import { WidgetProps } from '@rjsf/core'
import { format, isMatch, parseISO } from 'date-fns'

import { DatePicker } from '@components/DatePicker'
import { handleOnChange } from '../utils/handleOnChange'
import { useTranslation } from 'react-i18next'

const isIsoDate = (date: string): boolean => {
  const isoDate = parseISO(date)
  return isoDate.toString() !== 'Invalid Date'
}

const formatDate = (date: Date, formatType: string): string | undefined => {
  try {
    const formattedDate = format(date, formatType)
    return formattedDate
  } catch (error) {
    return undefined
  }
}

const isValidDate = (value: string) => {
  const isValidMatch = isMatch(value, 'MM/dd/yyyy')
  return isValidMatch
}

const DateWidget = ({
  label,
  required,
  value,
  onChange,
  disabled,
  id,
  rawErrors,
  schema,
}: React.PropsWithChildren<WidgetProps>) => {
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState<boolean>(true)
  const dateFormat = schema.format || ''
  const builtMask = dateFormat.replace(/[Mdy]/g, '9')

  useEffect(() => {
    if (isIsoDate(value)) {
      const dateObject = new Date(value)
      const formattedDate = formatDate(dateObject, dateFormat)

      onChange(formattedDate)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const isValidValue = isValidDate(value)
    setIsValid(isValidValue)
  }, [value])

  return (
    <DatePicker
      name={id}
      label={label}
      value={value}
      disabled={disabled}
      required={required}
      onChange={handleOnChange(onChange)}
      error={!!rawErrors?.length || !isValid}
      errorMessage={
        !!rawErrors?.length
          ? rawErrors?.join('\n')
          : t('anz.formErrorLabels.format')
      }
      customMask={builtMask}
    />
  )
}

export default DateWidget
