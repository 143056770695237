import React from 'react'
import { WidgetProps } from '@rjsf/core'

import { TextField } from '@components/TextField'
import { handleOnChange } from '../utils/handleOnChange'

const BaseInput = ({
  disabled,
  label,
  required,
  value,
  onChange,
  id,
  rawErrors,
  ...props
}: React.PropsWithChildren<WidgetProps>) => {
  return (
    <TextField
      name={id}
      value={value}
      label={label}
      onChange={handleOnChange(onChange)}
      disabled={disabled}
      required={required}
      $fullWidth
      error={!!rawErrors?.length}
      errorMessage={rawErrors?.join('\n')}
      minLength={props.schema.minLength}
      maxLength={props.schema.maxLength}
    />
  )
}

export default BaseInput
