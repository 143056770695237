import React from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import { Typography } from '../Typography'

export interface ToggleProps extends React.ComponentPropsWithRef<'input'> {
  onLabel?: string
  offLabel?: string
}

const StyledInput = styled.input<Omit<ToggleProps, 'label'>>`
  display: none;

  &:checked + div::after {
    transform: translateX(125%);
  }
`

const StyledLabel = styled.label`
  cursor: pointer;
`

const StyledLever = styled.div`
  position: relative;
  margin: -5px 12.5px;
  display: inline-block;
  &::before,
  &::after {
    content: '';
  }

  &::before {
    display: block;
    width: 40px;
    height: 20px;
    background-color: ${({ theme: { colors } }) => colors.primary};
    border-radius: 20px;
  }

  &::after {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${({ theme: { colors } }) => colors.white};
    top: 3px;
    left: 5px;
    transition: all 0.3s;
  }
`

const StyledSpan = styled(Typography)<{
  highlight: boolean
}>`
  color: ${({ highlight, theme: { colors } }) =>
    highlight ? colors.primary : colors.onSurface};
  user-select: none;

  &::first-letter {
    text-transform: capitalize;
  }
`

export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  ({ className, onLabel, offLabel, ...props }, ref) => {
    return (
      <div
        className={clsx(
          'intake__toggle',
          {
            'intake__toggle--checked': props.checked,
          },
          className
        )}
      >
        <StyledLabel>
          {offLabel && (
            <StyledSpan $variant="span" highlight={!props.checked}>
              {offLabel}
            </StyledSpan>
          )}
          <StyledInput type="checkbox" {...props} ref={ref} />
          <StyledLever />
          {onLabel && (
            <StyledSpan $variant="span" highlight={!!props.checked}>
              {onLabel}
            </StyledSpan>
          )}
        </StyledLabel>
      </div>
    )
  }
)
