/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

type Resolver<T> = () => Promise<T>
type Headers = Record<string, string>

type Config = {
  BASE: string
  BASE_ANZ: string
  VERSION: string
  WITH_CREDENTIALS: boolean
  TOKEN?: string | Resolver<string>
  USERNAME?: string | Resolver<string>
  PASSWORD?: string | Resolver<string>
  HEADERS?: Headers | Resolver<Headers>
}

export const OpenAPI: Config = {
  BASE: 'https://uat-apigw-connect-anz.essilorluxottica.com/dif',
  BASE_ANZ: 'https://lrapdifwaq01.luxottica.com/',
  VERSION: '1',
  WITH_CREDENTIALS: false,
  USERNAME: undefined,
  PASSWORD: undefined,
  HEADERS: undefined,
}
