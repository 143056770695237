import { useConfig } from '@root/Context'

export const useGetCurrentLanguage = () => {
  const { lang, country } = useConfig()

  const currentLanguage: `${string}-${string}` = `${lang || 'en'}-${
    country || 'US'
  }` as `${string}-${string}`

  return currentLanguage
}
